import React from "react";
import PropTypes from "prop-types";

import { documentTypeList } from "../commons/Constants";
import Select from "../atoms/Select";

export default function InputDocumentType(props) {
  return (
    <>
      <Select
        id="plan_num"
        onChange={props.onChange}
        options={documentTypeList}
        value={props.value}
        isBlank={true}
        blankLabel=""
        // error={props.error.plan_num}
      />
    </>
  );
}

InputDocumentType.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
InputDocumentType.defaultProps = {
  onChange: null,
  value: "1",
};
