//import React from "react";
import format from "date-fns/format";
import {
  ROLE_ADMIN,
  ROLE_DESIGNER_ADMIN,
  ROLE_DESIGNER_EXTERNAL,
  ROLE_DESIGNER_MANAGER,
  ROLE_DESIGNER_USER,
  ROLE_SALES_ADMIN,
  ROLE_SALES_MANAGER,
  ROLE_SALES_USER,
  rowsPerPage,
} from "./Constants";
// import axios from "axios";
import { scroller } from "react-scroll";

const objectToMap = (object) => new Map(Object.entries(object));

var arrayToObject = function (arr) {
  const obj = {};
  arr.forEach((data) => {
    obj[data.id] = data;
  });
  return obj;
};

// YYYY-MM-DD hh:mm:ss 形式で返す
var dateFormat = function (dbDateAt, isTime = false) {
  if (dbDateAt) {
    let convertedAt = dbDateAt.split(/[T\s.]/);

    return (
      convertedAt[0].replace(/-/g, "/") +
      getWeek(convertedAt[0]) +
      " " +
      (isTime && isset(convertedAt[1]) ? convertedAt[1].substring(0, 5) : "")
    );
  }
  return "";
};

// YYYY-MM-DD hh:mm 形式で返す
var dateToString = function (dateAt, isTime = false) {
  if (isDate(dateAt, false)) {
    if (isTime) {
      return format(dateAt, "yyyy-MM-dd hh:mm");
    }

    return format(dateAt, "yyyy-MM-dd");
  }
  return null;
};

const stringToDate = (str) => {
  return str ? new Date(Date.parse(str)) : null;
};

var isDate = function (date, nullok = true) {
  if (nullok && date == null) {
    return true;
  }

  return date != null && date.toString() !== "Invalid Date";
};
// 曜日を返す
var getWeek = function (date_str) {
  const date = new Date(date_str);
  const dayOfWeek = date.getDay(); // 曜日(数値)
  const weeks = ["日", "月", "火", "水", "木", "金", "土"];

  return "(" + weeks[dayOfWeek] + ")";
};

var inputCheckbaxValue = function (array) {
  if (!isset(array)) {
    return [];
  }
  return array.split(",");
};

// setされているか
var isset = function (data, is_trim = false) {
  if (is_trim === true && typeof data === "string") {
    data = data.trim();
  }
  if (data === "" || data === null || data === undefined) {
    return false;
  } else {
    return true;
  }
};

var numberToString = function (data) {
  return isset(data) ? String(data) : null;
};

// ページパラメータ付与
var addPageParams = function (params, page, is_sort_order = true) {
  let new_params = {
    ...params,
    page_offset: isset(page) && page.offset ? page.offset : 0,
    page_limit: isset(page) && page.limit ? page.limit : rowsPerPage,
  };

  if (is_sort_order) {
    new_params = {
      ...new_params,
      page_sort: page.sort,
      page_order: page.order,
    };
  }
  return new_params;
};

// バリデーション
var validateString = function (name, val, length, require = false) {
  if (require) {
    if (!isset(val, true)) {
      const error = name + "は必須入力項目です。";
      return error;
    }
  }

  if (isset(val) && val.length > length) {
    return name + "は、" + length + "文字以内で入力してください。";
  }

  return null;
};

var validateNumber = function (name, val, require = false) {
  if (require) {
    if (!isset(val, true)) {
      const error = name + "は必須入力項目です。";
      return error;
    }
  }
  if (isset(val)) {
    if (!isNaN(parseFloat(val)) && isFinite(val)) {
      return null;
    }
    return name + "は、" + "数字で入力してください。";
  }

  return null;
};
// バリデーションキー存在
var validateExistKey = function (name, val, list, require = true) {
  if (!require && !val) {
    return null;
  }
  if (!list.find((v) => v.value == val)) {
    const error = name + "を選択してください。";
    return error;
  }

  return null;
};

// バリデーション(カタカナ)
var validateKana = function (name, val, length, require = false) {
  if (require) {
    if (!isset(val, true)) {
      const error = name + "は必須入力項目です。";
      return error;
    }
  }

  if (isset(val) && val.length > length) {
    return name + "は、" + length + "文字以内で入力してください。";
  }

  let pattern = /^[ァ-ヶー\u3000]+$/;
  if (isset(val) && !pattern.test(val)) {
    return name + "はカタカナで入力してください。";
  }

  return null;
};

// バリデーション(郵便番号)
var validatePostalCode = function (name, val, require = false) {
  if (require) {
    if (!isset(val)) {
      const error = name + "は必須入力項目です。";
      return error;
    }
  }

  let pattern = /^[0-9]{3}-[0-9]{4}$/;
  if (isset(val) && !pattern.test(val)) {
    return name + "の形式に誤りがあります。(000-0000)";
  }

  return null;
};

// バリデーション(電話番号)
var validateTel = function (name, val, require = false) {
  if (require) {
    if (!isset(val)) {
      const error = name + "は必須入力項目です。";
      return error;
    }
  }

  let pattern = /^0\d{1,4}-\d{1,4}-\d{3,5}$/;
  if (isset(val) && !pattern.test(val)) {
    return name + "の形式に誤りがあります。(000-0000-0000)";
  }

  return null;
};

// バリデーション(日付)
var validateDate = function (name, val, require = false) {
  if (require) {
    if (!isset(val)) {
      const error = name + "は必須入力項目です。";
      return error;
    }
  }
  if (!isDate(val)) {
    return name + "の形式に誤りがあります。(00/00)";
  }

  return null;
};

// バリデーション(時間)
var validateTime = function (name, val, require = false) {
  if (require) {
    if (!isset(val)) {
      const error = name + "は必須入力項目です。";
      return error;
    }
  }

  let pattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
  if (isset(val) && !pattern.test(val)) {
    return name + "の形式に誤りがあります。(00:00)";
  }

  return null;
};

// バリデーション(メールアドレス)
var validateEmail = function (name, val, require = false) {
  let error = null;
  const pattern =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;

  if (require) {
    if (!isset(val)) {
      error = name + "は必須入力項目です。";
    }
  }

  if (error == null && isset(val) && !pattern.test(val)) {
    if (
      ![
        "((#営業担当メールアドレス#))",
        "((#設計担当メールアドレス#))",
      ].includes(val)
    ) {
      error = name + "の形式に誤りがあります。";
    }
  }
  return error;
};

var validateFileLength = function (
  requestFiles,
  ini_length = 0,
  max_length = 10,
  require = true
) {
  if (require && requestFiles.length + ini_length == 0) {
    return "添付ファイルは必須入力です。";
  }
  if (requestFiles.length + ini_length > max_length) {
    return "添付ファイルは" + max_length + "件までです。";
  }
  return null;
};

var validateFileSize = function (requestFiles, ini_size = 0, max_size_mb = 8) {
  let size = 0;
  const max_size = max_size_mb * 1024 * 1024;
  for (let data of requestFiles) {
    size += data.file.size;
  }
  if (size + ini_size > max_size) {
    // 8M = 1024*1024*8
    return "添付ファイルの合計サイズは" + max_size_mb + "MBまでです。";
  }
  return null;
};

// 空
// function foo(err) {
//   console.log(err);
//   return err;
// }

// エラーが有るか
var isValid = function (errors) {
  if (!isset(errors)) {
    return true;
  }

  const keys = Object.keys(errors);

  const result = keys.filter((key) => errors[key] !== null);

  return result.length == 0;
};

// 管理者
var isAdmin = function (authInfo) {
  if (!isset(authInfo) || !isset(authInfo.user) || !isset(authInfo.user.role)) {
    return false;
  }
  return authInfo.user.role.code === ROLE_ADMIN;
};

// 営業
var isSales = function (authInfo) {
  if (!isset(authInfo) || !isset(authInfo.user) || !isset(authInfo.user.role)) {
    return false;
  }
  return (
    authInfo.user.role.code === ROLE_ADMIN ||
    authInfo.user.role.code === ROLE_SALES_ADMIN ||
    authInfo.user.role.code === ROLE_SALES_MANAGER ||
    authInfo.user.role.code === ROLE_SALES_USER
  );
};

var isSalesAdmin = function (authInfo) {
  if (!isset(authInfo) || !isset(authInfo.user) || !isset(authInfo.user.role)) {
    return false;
  }
  return (
    authInfo.user.role.code === ROLE_ADMIN ||
    authInfo.user.role.code === ROLE_SALES_ADMIN ||
    authInfo.user.role.code === ROLE_SALES_MANAGER
  );
};

// 設計者
var isDesigner = function (authInfo) {
  if (!isset(authInfo) || !isset(authInfo.user) || !isset(authInfo.user.role)) {
    return false;
  }
  return (
    authInfo.user.role.code === ROLE_ADMIN ||
    authInfo.user.role.code === ROLE_DESIGNER_ADMIN ||
    authInfo.user.role.code === ROLE_DESIGNER_MANAGER ||
    authInfo.user.role.code === ROLE_DESIGNER_USER ||
    authInfo.user.role.code === ROLE_DESIGNER_EXTERNAL
  );
};

var isDesignerAdmin = function (authInfo) {
  if (!isset(authInfo) || !isset(authInfo.user) || !isset(authInfo.user.role)) {
    return false;
  }
  return (
    authInfo.user.role.code === ROLE_ADMIN ||
    authInfo.user.role.code === ROLE_DESIGNER_ADMIN ||
    authInfo.user.role.code === ROLE_DESIGNER_MANAGER
  );
};

var isDesignerUser = function (authInfo) {
  if (!isset(authInfo) || !isset(authInfo.user) || !isset(authInfo.user.role)) {
    return false;
  }
  return authInfo.user.role.code === ROLE_DESIGNER_USER;
};

var isDesignerExternal = function (authInfo) {
  if (!isset(authInfo) || !isset(authInfo.user) || !isset(authInfo.user.role)) {
    return false;
  }
  return authInfo.user.role.code === ROLE_DESIGNER_EXTERNAL;
};

var getDesignerType = function (authInfo) {
  let type = "external";
  if (isDesignerUser(authInfo)) {
    type = "user";
  } else if (isDesignerAdmin(authInfo)) {
    type = "admin";
  }
  return type;
};
// // 責任者
// var isBoss = function (roles) {
//   if (!isset(roles)) {
//     return false;
//   }
//   const result = roles.find((role) => role.role.id == 2 || role.role.id == 1);
//   return result != undefined;
// };

// // 一般ユーザー
// var isUser = function (roles) {
//   if (!isset(roles)) {
//     return false;
//   }
//   const result = roles.find(
//     (role) => role.role.id == 3 || role.role.id == 2 || role.role.id == 1
//   );
//   return result != undefined;
// };

// // 八王子
// var isHachiouji = function (roles) {
//   if (!isset(roles)) {
//     return false;
//   }
//   const result = roles.find((role) => role.role.id == 4 || role.role.id == 1);
//   return result != undefined;
// };

// // 八王子だけ
// var isOnlyHachiouji = function (roles) {
//   if (!isset(roles)) {
//     return false;
//   }

//   const result = roles.find(
//     (role) => role.role.id == 3 || role.role.id == 2 || role.role.id == 1
//   );
//   if (result != undefined) {
//     return false;
//   }
//   const result2 = roles.find((role) => role.role.id == 4);
//   if (result2 == undefined) {
//     return false;
//   }
//   return true;
// };

// // 責任者、一般だけ
// var isOnlyBossUser = function (roles) {
//   if (!isset(roles)) {
//     return false;
//   }

//   const result = roles.find((role) => role.role.id == 4 || role.role.id == 1);
//   if (result != undefined) {
//     return false;
//   }
//   const result2 = roles.find((role) => role.role.id == 3 || role.role.id == 2);
//   if (result2 == undefined) {
//     return false;
//   }
//   return true;
// };

// 数字チェック
const isNumber = function (value) {
  return typeof value === "number" && isFinite(value);
};

// CSVダウンロード
var csvDownload = function (data, filename) {
  // utf8
  const bom = "\uFEFF";
  const blob = new Blob([bom, data], { type: "text/csv" });

  const jsonURL = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  document.body.appendChild(link);
  link.href = jsonURL;
  link.setAttribute("download", filename);
  link.click();
  document.body.removeChild(link);
};

// ファイルダウンロード
var fileDownload = function (data, content_type, filename) {
  const contentType = content_type ?? "application/octet-stream";
  // console.log(headers);
  // console.log(typeof data);

  const blob = new Blob([data], { type: contentType });

  const jsonURL = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  document.body.appendChild(link);
  link.href = jsonURL;
  link.setAttribute("download", basename(filename));
  link.click();
  document.body.removeChild(link);
};

// スリープ
var sleep = function (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// 単位変換
var getFileSize = function (size) {
  const { target, unit } = getSizeUnit(size);
  const d = Math.pow(10, 1);

  const newSize = target !== null ? Math.floor((size / target) * d) / d : size;

  return newSize + unit;
};

var getSizeUnit = function (size) {
  if (!isset(size)) {
    return "";
  }
  const kb = 1024;
  const mb = Math.pow(kb, 2);
  const gb = Math.pow(kb, 3);
  const tb = Math.pow(kb, 4);

  if (size >= tb) return { target: tb, unit: "TB" };
  if (size >= gb) return { target: gb, unit: "GB" };
  if (size >= mb) return { target: mb, unit: "MB" };
  if (size >= kb) return { target: kb, unit: "KB" };

  return { target: null, unit: "byte" };
};

// /**
//  * HTMLタグ除去
//  */
// var removeHtml = function (html) {
//   if (!isset(html)) {
//     return html;
//   }
//   return html.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "");
// };

var basename = function (path) {
  if (!isset(path)) {
    return path;
  }
  // const result = path.replace(/^\d+_\d+_/, "");
  const result = path.split("/").pop();

  return result;
};

var jsonParse = function (json, default_value = {}) {
  try {
    if (!isset(json)) {
      return default_value;
    }
    return JSON.parse(json);
  } catch (e) {
    // console.log("error json parce", e);
    return default_value;
  }
};

var joinName = function (last_name, first_name) {
  const name = [last_name, first_name];

  return name.join(" ");
};
// // 名前重複削除
// var distinctTakkenUser = function (contact_takken_users) {
//   let takken_users = [];
//   let takken_users_name = [];

//   contact_takken_users.forEach((contact_takken_user) => {
//     if (
//       contact_takken_user.takken_user != null &&
//       takken_users_name.indexOf(contact_takken_user.takken_user.full_name) ===
//         -1
//     ) {
//       takken_users.push(contact_takken_user.takken_user);
//       takken_users_name.push(contact_takken_user.takken_user.full_name);
//     }
//   });

//   return takken_users;
// };
var findDesigner = function (designers, designer_id, is_branch_name = true) {
  let designer = null;
  for (let b of designers) {
    for (let d of b.options) {
      if (d.value == designer_id) {
        designer = is_branch_name ? b.name + " " : "";
        designer += d.name;
        break;
      }
    }
    if (designer) {
      break;
    }
  }
  return designer;
};

var getDesignerId = function (plan_request, initial = false) {
  if (!plan_request) {
    return;
  }

  if (initial) {
    return plan_request && plan_request.initial_design_office_id
      ? "trader_id:" + plan_request.initial_design_office_id
      : plan_request.initial_designer;
  }

  const designer_id =
    plan_request && plan_request.design_office_id
      ? "trader_id:" + plan_request.design_office_id
      : plan_request.designer;

  return designer_id;
};

var scrollTo = function (id) {
  const to = id ?? "top";
  scroller.scrollTo(to, {
    duration: 500,
    // delay: 100,
    smooth: true,
    containerId: "content",
    offset: -50,
  });
};

var gotoTopUrl = function (authInfo) {
  if (isSales(authInfo)) {
    window.open("/project/", "_self");
    return;
  }
  if (isDesigner(authInfo)) {
    window.open("/plan/", "_self");
    return;
  }
};

var getFixNoteLabel = function (plan) {
  if (plan.request_type == 1) {
    return "依頼内容";
  }
  return "修正/変更 依頼内容";
};

function base64Encode(...parts) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const offset = reader.result.indexOf(",") + 1;
      resolve(reader.result.slice(offset));
    };
    reader.readAsDataURL(new Blob(parts));
  });
}

// export
export {
  dateFormat,
  dateToString,
  stringToDate,
  isDate,
  inputCheckbaxValue,
  objectToMap,
  arrayToObject,
  isset,
  numberToString,
  addPageParams,
  isAdmin,
  isSales,
  isSalesAdmin,
  isDesigner,
  isDesignerAdmin,
  isDesignerUser,
  isDesignerExternal,
  getDesignerType,
  //   isUser,
  //   isHachiouji,
  //   isOnlyHachiouji,
  //   isOnlyBossUser,
  isValid,
  validateString,
  validateNumber,
  validateExistKey,
  validateKana,
  validatePostalCode,
  validateTel,
  validateDate,
  validateTime,
  validateEmail,
  validateFileLength,
  validateFileSize,
  csvDownload,
  fileDownload,
  isNumber,
  sleep,
  getFileSize,
  //   removeHtml,
  basename,
  jsonParse,
  joinName,
  //   distinctTakkenUser,
  findDesigner,
  getDesignerId,
  scrollTo,
  gotoTopUrl,
  getFixNoteLabel,
  base64Encode,
};
