import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// import Button from "../../atoms/Button";
import Modal from "../templates/Modal";
import OkCancel from "../molecules/OkCancel";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import Table from "../atoms/Table";
import { roleList, rowsPerPage } from "../commons/Constants";
import LabelValue from "../templates/LabelValue";
import CallAPI from "./CallAPI";
import { addPageParams } from "../commons/Functions";
import Name from "./Name";
import User from "../commons/User";
import Select from "../atoms/Select";

export default function SearchUser(props) {
  useEffect(() => {
    const branches = User.getSalesBranches();
    setBranches(branches);

    loadData(page);
  }, []);

  const [branches, setBranches] = useState([]);
  // console.log(branches);

  // const [kizunaBranches, setKizunaBranches] = useState([]);
  const [apiParams, setApiParams] = useState({});
  const [keyword, setKeywoed] = useState("");
  const [roleCode, setRoleCode] = useState();

  const [users, setUsers] = useState({ list: [], count: 0 });
  // ページ管理
  const [page, setPage] = useState({
    offset: 0,
    limit: rowsPerPage,
  });

  const handleKeyword = (e) => {
    // props.onClose();
    setKeywoed(e.target.value);
  };

  const handleRole = (e) => {
    setRoleCode(e.target.value);
  };

  const handleSearch = () => {
    const params = {
      ...page,
      offset: 0,
    };
    setPage(params);
    loadData(params);
  };

  const loadData = (page) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/user/search_user/`,
      method: "GET",
      params: addPageParams(
        { keyword: keyword, role_code: roleCode },
        page,
        false
      ),
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = (data) => {
    // console.log(data);
    setUsers(data);
  };

  // 改ページ
  const handleChangePageNo = (e, offset) => {
    const params = {
      ...page,
      offset: offset,
    };
    setPage(params);
    loadData(params);
  };

  const handleClickRow = (id) => {
    // console.log(id);
    const user = users.list.find((v) => v.id == id);
    const kizuna_branch = users
      ? branches.find((v) => v.id == user.branch_id)
      : null;

    props.onChange(user, kizuna_branch ? kizuna_branch.name : "");
    props.onClose();
  };

  const handleCancel = () => {
    props.onChange({});
    props.onClose();
  };

  const getRoleName = (value) => {
    const role = roleList.find((v) => v.value == value);
    return <>{role ? role.name : ""}</>;
  };
  const getUserName = (value, id, row) => {
    return <Name user={row} />;
  };

  const columns = [
    {
      id: "user_name",
      label: "担当者名",
      minWidth: 150,
      format: (value, id, row) => {
        return getUserName(value, id, row);
      },
    },
    {
      id: "role_code",
      label: "権限",
      minWidth: 150,
      format: (value, id, row) => {
        return getRoleName(value, id, row);
      },
    },
  ];

  return (
    <Modal
      title="担当者選択"
      open={props.open}
      onClose={props.onClose}
      style="normal"
    >
      <LabelValue label="営業担当名" underline={false}>
        <Input
          id="keyword"
          value={keyword}
          onChange={handleKeyword}
          onKeyEnter={handleSearch}
        />
      </LabelValue>
      <LabelValue label="権限" underline={false}>
        <Select
          id="role"
          onChange={handleRole}
          options={roleList}
          value={roleCode}
          isBlank={true}
        />
      </LabelValue>

      <LabelValue label="" underline={false}>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          検索
        </Button>
      </LabelValue>

      <Table
        columns={columns}
        data={users}
        page={page}
        onChangePageNo={handleChangePageNo}
        onClickRow={handleClickRow}
        buttonLabel={<>選択</>}
      />

      <OkCancel ok="クリア" onOk={handleCancel} onCancel={props.onClose} />
      <CallAPI params={apiParams} />
    </Modal>
  );
}

SearchUser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};
SearchUser.defaultProps = {
  open: false,
};
