import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Element } from "react-scroll";
import ReactToPrint from "react-to-print";

import Layout from "../../templates/Layout";
import LocationInfo from "../../organisms/LocationInfo";
import RequestInfo from "../../organisms/RequestInfo";
import PlanTitle from "../../organisms/PlanTitle";
import PostPlanInfo from "../../organisms/PostPlanInfo";
import InputPostFile from "../../organisms/InputPostFile";
import CallAPI from "../../molecules/CallAPI";
import DesiredBuildingInfo from "../../organisms/DesiredBuildingInfo";
import SiteInfo from "../../organisms/SiteInfo";
import {
  dateToString,
  findDesigner,
  getDesignerId,
  inputCheckbaxValue,
  isAdmin,
  isDesigner,
  isDesignerAdmin,
  isSales,
  isValid,
  // joinName,
  numberToString,
  scrollTo,
  stringToDate,
  validateDate,
  validateString,
} from "../../commons/Functions";
import OkCancel from "../../molecules/OkCancel";
import InputRequestInfo from "../../organisms/InputRequestInfo";
import {
  validateDesiredBuildingInfo,
  validateRequestFile,
  validateRequestInfo,
  validateReviewFile,
  validateSiteInfo,
} from "./Validate";
import InputRequestFile from "../../organisms/InputRequestFile";
import InputSiteInfo from "../../organisms/InputSiteInfo";
import InputDesiredBuildingInfo from "../../organisms/InputDesiredBuildingInfo";
import RequestFileList from "../../molecules/RequestFileList";
import Modal from "../../templates/Modal";
import LabelValue from "../../templates/LabelValue";
import DatePicker from "../../atoms/DatePicker";
import Textarea from "../../atoms/Textarea";
import RequestFiles from "../../molecules/RequestFiles";
import User from "../../commons/User";
import DesiredDeliveryDate from "../../molecules/DesiredDeliveryDate";
import RequestInfoHead from "../../organisms/RequestInfoHead";
import History from "../../commons/History";
import Button from "../../atoms/Button";
import DetailPrint from "./DetailPrint";
import { makeStyles } from "@material-ui/core/styles";
import Select from "../../atoms/Select";
import { planNumList, PLAN_STATUS } from "../../commons/Constants";
import InputReviewFile from "../../organisms/InputReviewFile";
import { Box, FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(1),
  },
  print_button: {
    marginTop: theme.spacing(5),
  },
  label: {
    fontSize: "20px",
    marginTop: theme.spacing(0),
  },
}));

export default function Detail(props) {
  const classes = useStyles();

  const project_id = props.match.params.project_id;
  const plan_id = props.match.params.plan_id;
  // console.log("project_id", project_id, "plan_id", plan_id);

  const componentRef = useRef(null);

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    setAuthInfo(authInfo);
    loadData(plan_id);
  }, [project_id, plan_id]);

  const [authInfo, setAuthInfo] = useState({});
  const [mode, setMode] = useState("detail");
  const [titleMessage, setTitleMessage] = useState(null);

  const [apiParams, setApiParams] = useState({});
  const [project, setProject] = useState({});
  const [sites, setSites] = useState({
    list: [],
    count: 0,
  });
  const [dbPlanRequest, setDbPlanRequest] = useState();
  const [users, setUsers] = useState([]);
  const [designers, setDesigners] = useState([]);
  const [dbRequestFiles, setDbRequestFiles] = useState([]);
  const [dbPostFiles, setDbPostFiles] = useState([]);
  const [dbReviewFiles, setDbReviewFiles] = useState([]);
  const [editRequestFiles, setEditRequestFiles] = useState([]);
  // edit
  const [requestInfo, setRequestInfo] = useState({});
  const [requestInfoError, setRequestInfoError] = useState({});
  const [requestFiles, setRequestFiles] = useState([]);
  const [requestFilesError, setRequestFilesError] = useState({});
  const [siteInfo, setSiteInfo] = useState({});
  const [siteInfoError, setSiteInfoError] = useState({});
  const [desiredBuildingInfo, setDesiredBuildingInfo] = useState({});
  const [desiredBuildingInfoError, setDesiredBuildingInfoError] = useState({});

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [littleUpdate, setLittleUpdate] = useState({});
  const [littleUpdateError, setLittleUpdateError] = useState({});
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [openStartModal, setOpenStartModal] = useState(false);
  const [openRemandModal, setOpenRemandModal] = useState(false);
  const [openSwitchShowRemandModal, setOpenSwitchShowRemandModal] =
    useState(false);
  const [remand, setRemand] = useState({ note: null, files: [] });
  const [remandError, setRemandError] = useState({});
  const [reviewFilesError, setReviewFilesError] = useState({});
  const [openPullBackModal, setOpenPullBackModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openReviewDoneModal, setOpenReviewDoneModal] = useState(false);
  const [openReviewRemandModal, setOpenReviewRemandModal] = useState(false);

  // console.log(requestInfoError);

  const loadData = (plan_id) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/${plan_id}/`,
      method: "GET",
      params: {},
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = (data) => {
    // console.log(data);

    setProject(data.project);
    setSites({
      list: data.sites,
      count: data.sites.length,
    });

    const plan_request = { ...data.plan_request };

    plan_request.request_plan = inputCheckbaxValue(plan_request.request_plan);
    plan_request.desired_delivery_date = stringToDate(
      plan_request.desired_delivery_date
    );
    plan_request.posted_at = stringToDate(plan_request.posted_at);
    // plan_request.is_address =
    //   isset(plan_request.prefecture) || isset(plan_request.city) ? 0 : 1;

    plan_request.prefecture =
      plan_request.is_address == 0 ? plan_request.prefecture : "東京都";

    // plan_request.is_site_area = isset(plan_request.site_area) ? 0 : 1;
    plan_request.site_area = numberToString(plan_request.site_area);

    // plan_request.is_use_district = isset(plan_request.use_district) ? 0 : 1;

    // plan_request.is_height_district = isset(plan_request.height_district)
    // ? 0
    // : 1;

    // plan_request.is_fire_protection = isset(plan_request.fire_protection)
    //   ? 0
    //   : 1;

    // plan_request.is_construction_volume_rate =
    //   isset(plan_request.construction_rate) || isset(plan_request.volume_rate)
    //     ? 0
    //     : 1;
    plan_request.construction_rate = numberToString(
      plan_request.construction_rate
    );
    plan_request.volume_rate = numberToString(plan_request.volume_rate);

    // plan_request.is_road_width =
    //   isset(plan_request.road_width_north) ||
    //   isset(plan_request.road_width_south) ||
    //   isset(plan_request.road_width_east) ||
    //   isset(plan_request.road_width_west)
    //     ? 0
    //     : 1;

    plan_request.road_width_north = numberToString(
      plan_request.road_width_north
    );
    plan_request.road_width_south = numberToString(
      plan_request.road_width_south
    );
    plan_request.road_width_east = numberToString(plan_request.road_width_east);
    plan_request.road_width_west = numberToString(plan_request.road_width_west);

    plan_request.road_obstacle = inputCheckbaxValue(plan_request.road_obstacle);
    // plan_request.is_road_obstacle = isset(plan_request.road_obstacle) ? 0 : 1;

    plan_request.designer_message = data.designer_message;

    plan_request.designer = getDesignerId(plan_request);

    setDbPlanRequest(plan_request);

    setUsers(data.users);
    setDesigners(data.designers);

    setDbRequestFiles(data.request_files);
    setDbPostFiles(data.post_files);
    setDbReviewFiles(data.review_files);

    setLittleUpdate({ ...littleUpdate, plan_num: plan_request.plan_num });

    const url = new URL(location);
    const mode = url.searchParams.get("mode");
    if (mode && mode == "confirm") {
      window.history.replaceState(
        "",
        "",
        "/plan/" + encodeURI(project_id) + "/" + encodeURI(plan_id)
      );

      setMode("edit");

      setRequestInfo(plan_request);
      setSiteInfo(plan_request);
      setDesiredBuildingInfo(plan_request);
      setEditRequestFiles(data.request_files);
      setRequestFiles([]);

      confirm(plan_request, plan_request, plan_request, data.request_files);
    }
    if (mode && mode == "saved") {
      window.history.replaceState(
        "",
        "",
        "/plan/" + encodeURI(project_id) + "/" + encodeURI(plan_id)
      );
      setTitleMessage("依頼が完了しました");
    }
  };

  const handleEdit = (status) => {
    const request = { ...dbPlanRequest };
    if (status == PLAN_STATUS.COMPLETE) {
      // 再依頼
      request.request_type = 2;
      request.standard_delivery_date_flag = 1;
      request.desired_delivery_date = new Date();
    }

    setRequestInfo(request);
    setSiteInfo(request);
    setDesiredBuildingInfo(request);
    setEditRequestFiles([...dbRequestFiles]);
    setRequestFiles([]);
    setMode("edit");
    setTitleMessage(null);

    scrollTo("top");
  };

  const handleCancel = () => {
    setMode("detail");
  };

  const handleRequestInfo = (e) => {
    // console.log(e);
    setRequestInfo({ ...requestInfo, ...e });
  };

  const handleRequestFiles = (e, db) => {
    // console.log(db);
    setRequestFiles(e);
    setEditRequestFiles(db);
  };

  const handleSiteInfo = (e) => {
    // console.log("handleSiteInfo", e);
    setSiteInfo({ ...siteInfo, ...e });
  };

  const handleDesiredBuildingInfo = (e) => {
    // console.log(e);
    setDesiredBuildingInfo(e);
  };

  const confirm = (
    requestInfo,
    siteInfo,
    desiredBuildingInfo,
    editRequestFiles,
    requestFiles = []
  ) => {
    // 確認
    const requestInfoError = validateRequestInfo(requestInfo);
    setRequestInfoError(requestInfoError);

    const siteInfoError = validateSiteInfo(siteInfo);
    setSiteInfoError(siteInfoError);

    const desiredBuildingInfoError =
      validateDesiredBuildingInfo(desiredBuildingInfo);
    setDesiredBuildingInfoError(desiredBuildingInfoError);

    // 登録済みの修正
    let length = 0;
    let size = 0;
    for (let data of editRequestFiles) {
      length++;
      size += data.size;
    }

    const requestFilesError = validateRequestFile(requestFiles, length, size);
    setRequestFilesError(requestFilesError);

    if (!isValid(requestInfoError)) {
      scrollTo("InputRequestInfo");
      return false;
    }
    if (!isValid(requestFilesError)) {
      scrollTo("InputRequestFile");
      return false;
    }
    if (!isValid(siteInfoError)) {
      scrollTo("InputSiteInfo");
      return false;
    }
    if (!isValid(desiredBuildingInfoError)) {
      scrollTo("InputDesiredBuildingInfo");
      return false;
    }

    setMode("confirm");

    scrollTo("top");
  };

  const handleConfirm = () => {
    updatePlan("confirm");
  };

  const handleTemporarySave = () => {
    updatePlan("temp");
  };
  const handleCancelConfirm = () => {
    setMode("edit");
    setTitleMessage(null);
  };
  const handleUpdate = () => {
    updatePlan("save");
  };

  const updatePlan = (type) => {
    // console.log("editRequestFiles", editRequestFiles);

    let status = type == "save" ? PLAN_STATUS.REQUESTED : PLAN_STATUS.TEMP;
    if (requestInfo.status == PLAN_STATUS.REQUESTED && type == "confirm") {
      status = PLAN_STATUS.REQUESTED;
    }
    if (requestInfo.status == PLAN_STATUS.REMAND && type == "confirm") {
      status = PLAN_STATUS.REMAND;
    }
    if (requestInfo.status == PLAN_STATUS.REMAND && type == "temp") {
      status = PLAN_STATUS.REMAND;
    }

    const requestInfoError = validateRequestInfo(requestInfo, true);
    setRequestInfoError(requestInfoError);

    const siteInfoError = validateSiteInfo(siteInfo, true);
    setSiteInfoError(siteInfoError);

    const desiredBuildingInfoError = validateDesiredBuildingInfo(
      desiredBuildingInfo,
      true
    );
    setDesiredBuildingInfoError(desiredBuildingInfoError);

    if (!isValid(requestInfoError)) {
      scrollTo("InputRequestInfo");
      return false;
    }
    if (!isValid(siteInfoError)) {
      scrollTo("InputSiteInfo");
      return false;
    }
    if (!isValid(desiredBuildingInfoError)) {
      scrollTo("InputDesiredBuildingInfo");
      return false;
    }

    const update_params = {
      project_id: project_id,
      plan_id: plan_id,
      request_info: JSON.stringify({
        ...requestInfo,
        status: status,
        request_plan: requestInfo.request_plan,
        desired_delivery_date: dateToString(requestInfo.desired_delivery_date),
      }),
      site_info: JSON.stringify({
        ...siteInfo,
        road_obstacle: siteInfo.road_obstacle,
      }),
      desired_building_info: JSON.stringify({ ...desiredBuildingInfo }),
      request_files: requestFiles,
      edit_request_files: JSON.stringify(editRequestFiles),
      timestamp: new Date(),
    };
    // console.log(update_params.request_info.request_plan);

    let url = `${process.env.REACT_APP_API_ENDPOINT}/plan/update/`;
    if (dbPlanRequest.status == PLAN_STATUS.COMPLETE) {
      // 再依頼
      url = `${process.env.REACT_APP_API_ENDPOINT}/plan/re_request/`;
    }

    const params = {
      // url: `${process.env.REACT_APP_API_ENDPOINT}/plan/${plan_id}/`,
      url: url,
      method: "FILE",
      params: update_params,
      setData: setPlanData,
      retValue: type,
      isSubmit: true,
    };
    // console.log(params);
    setApiParams(params);
  };

  // 取得データセット
  const setPlanData = (data, type) => {
    if (type == "save") {
      window.open(
        "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id) + "/?mode=saved",
        "_self"
      );
      // History.push(
      //   "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id)
      // );
      return;
    }
    if (type == "temp") {
      window.open(
        "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id),
        "_self"
      );
      return;
    }
    if (type == "confirm") {
      window.open(
        "/plan/" +
        encodeURI(project_id) +
        "/" +
        encodeURI(data.plan_id) +
        "/?mode=confirm",
        "_self"
      );
      return;
    }

    // window.open("/project/", "_self");
    History.push("/project/");
  };

  // 軽微変更
  const handleLittleUpdateModal = () => {
    setOpenUpdateModal(true);
    setRequestFiles([]);
  };

  const handleLittleUpdate = () => {
    const littleUpdateError = {
      desired_delivery_date: validateDate(
        "納品希望日",
        littleUpdate.desired_delivery_date,
        true
      ),
      fix_note: validateString(
        "修正依頼内容",
        littleUpdate.fix_note,
        1000,
        true
      ),
    };

    setLittleUpdateError(littleUpdateError);
    const requestFilesError =
      requestFiles.length != 0 ? validateRequestFile(requestFiles) : null;
    setRequestFilesError(requestFilesError);

    if (!isValid(littleUpdateError)) {
      return;
    }
    if (!isValid(requestFilesError)) {
      return;
    }

    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/little_request/`,
      method: "FILE",
      params: {
        project_id: project_id,
        plan_id: plan_id,
        desired_delivery_date: dateToString(littleUpdate.desired_delivery_date),
        fix_note: littleUpdate.fix_note,
        plan_num: littleUpdate.plan_num,
        request_files: requestFiles,
      },
      setData: setDataLittleUpdate,
      isSubmit: true,
    };
    setApiParams(params);
  };
  const setDataLittleUpdate = (data) => {
    // setIsEdit(false);
    window.open(
      "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id),
      "_self"
    );

    return;
  };

  const handleDesiredDeliveryDate = (e) => {
    setLittleUpdate({ ...littleUpdate, desired_delivery_date: e });
  };
  const handlePlanNum = (e) => {
    setLittleUpdate({ ...littleUpdate, plan_num: e.target.value });
  };
  const handleFixNote = (e) => {
    setLittleUpdate({ ...littleUpdate, fix_note: e.target.value });
  };

  // 取下げ
  const handleWithdraw = () => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/withdraw/`,
      method: "PUT",
      params: {
        project_id: project_id,
        plan_id: plan_id,
      },
      setData: reloadPlan,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 受付
  const handleStart = () => {
    const req_param = {
      plan_id: plan_id,
      project_id: project_id,
    };
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/start/`,
      method: "PUT",
      params: req_param,
      setData: reloadPlan,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 差戻し
  const handleRemandNote = (e) => {
    setRemand({ ...remand, note: e.target.value });
  };

  const handleRemand = () => {
    const remandError = {
      note: validateString("伝達事項", remand.note, 1000, true),
    };

    setRemandError(remandError);
    if (!isValid(remandError)) {
      return;
    }

    const req_param = {
      plan_id: plan_id,
      project_id: project_id,
      post_note: remand.note,
    };
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/remand/`,
      method: "PUT",
      params: req_param,
      setData: reloadPlanTop,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const handleChangeRequestType = (e) => {
    dbPlanRequest, setDbPlanRequest({ ...dbPlanRequest, request_type: e });
  };

  // 取下げ
  const handlePullBack = () => {
    const req_param = {
      plan_id: plan_id,
      project_id: project_id,
    };
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/pullback/`,
      method: "PUT",
      params: req_param,
      setData: reloadPlan,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 削除
  const handleDelete = () => {
    const req_param = {
      plan_id: plan_id,
      project_id: project_id,
    };
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/delete/`,
      method: "PUT",
      params: req_param,
      setData: () => reloadProject(true),
      isSubmit: true,
    };
    setApiParams(params);
  };

  // レビュー完了
  const handleReviewDone = () => {
    const remandError = {
      note: validateString("完了コメント", remand.note, 1000, false),
    };
    setRemandError(remandError);
    if (!isValid(remandError)) {
      return;
    }

    const req_param = {
      plan_id: plan_id,
      project_id: project_id,
      review_complete_note: remand.note,
    };
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/review_done/`,
      method: "PUT",
      params: req_param,
      setData: reloadPlan,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // レビュー差戻し
  const handleRemandFiles = (e) => {
    // console.log(e);
    setRemand({ ...remand, files: e });
  };

  const handleReviewRemand = () => {
    const remandError = {
      note: validateString("差戻し理由", remand.note, 1000, true),
    };

    const reviewFilesError = validateReviewFile(remand.files);
    setReviewFilesError(reviewFilesError);

    setRemandError(remandError);
    if (!isValid(remandError)) {
      return;
    }

    const req_param = {
      plan_id: plan_id,
      project_id: project_id,
      review_note: remand.note,
      review_files: remand.files,
    };
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/review_remand/`,
      method: "FILE",
      params: req_param,
      setData: reloadPlan,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const handleSwitchShowRemand = () => {
    const req_param = {
      plan_id: plan_id,
      project_id: project_id,
      is_show_remand: dbPlanRequest.is_show_remand == 1 ? 0 : 1,
    };

    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/is_show_remand/`,
      method: "PUT",
      params: req_param,
      setData: reloadPlan,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const reloadPlanTop = () => {
    History.push("/plan/");
  };

  const reloadPlan = () => {
    window.open(
      "/plan/" + encodeURI(project_id) + "/" + encodeURI(plan_id),
      "_self"
    );
  };

  const reloadProject = (is_plan = false) => {
    const tab = is_plan ? "?tab=plan" : "";
    window.open("/project/" + encodeURI(project_id) + tab, "_self");
  };

  const user = users.find((u) => u.id == dbPlanRequest.requested_by);

  let designer_id = getDesignerId(dbPlanRequest);
  designer_id = mode === "confirm" ? requestInfo.designer : designer_id;
  const seach_designers = mode === "detail" ? designers : User.getDesigners();
  const designer = findDesigner(seach_designers, designer_id);

  const initial_designer_id = getDesignerId(dbPlanRequest, true);
  const initial_designer =
    designer_id != initial_designer_id
      ? findDesigner(designers, initial_designer_id)
      : null;

  let breadcrumb = [
    { url: "/plan/", text: "プラン依頼一覧" },
    { url: "self", text: "プラン詳細" },
  ];
  if (isSales(authInfo)) {
    breadcrumb = [
      { url: "/project/", text: "案件一覧" },
      { url: "/project/" + project_id, text: "案件詳細" },
      { url: "self", text: "プラン詳細" },
    ];
  }

  let is_postal = false;
  if (
    dbPlanRequest &&
    (isAdmin(authInfo) ||
      dbPlanRequest.designer == authInfo.user.id ||
      (dbPlanRequest.design_office_id != null &&
        dbPlanRequest.design_office_id == authInfo.user.kizuna_trader_id))
  ) {
    is_postal = true;
  }

  return (
    <Layout title="プラン詳細" breadcrumb={breadcrumb}>
      {dbPlanRequest && (
        <>
          <PlanTitle
            plan={dbPlanRequest}
            onChangeRequestType={handleChangeRequestType}
            message={titleMessage}
          />

          <div className={classes.page}>
            {mode == "detail" && (
              <>
                {(dbPlanRequest.status == PLAN_STATUS.REQUESTED ||
                  dbPlanRequest.status == PLAN_STATUS.MAKING ||
                  dbPlanRequest.status == PLAN_STATUS.COMPLETE ||
                  dbPlanRequest.status == PLAN_STATUS.REMAND) && (
                    <RequestInfoHead
                      plan={dbPlanRequest}
                      designer={designer}
                    //   designer
                    //     ? joinName(designer.last_name, designer.first_name)
                    //     : null
                    // }
                    />
                  )}

                {dbPlanRequest.status == PLAN_STATUS.MAKING &&
                  isDesigner(authInfo) && (
                    // 依頼受付中
                    <InputPostFile
                      plan={dbPlanRequest}
                      dbFiles={dbPostFiles}
                      reviewFiles={dbReviewFiles}
                      users={users}
                      isPostal={is_postal}
                    />
                  )}
                {(dbPlanRequest.status == PLAN_STATUS.COMPLETE ||
                  dbPlanRequest.status == PLAN_STATUS.REVIEW) && (
                    // 完了
                    <PostPlanInfo
                      plan={dbPlanRequest}
                      designer={designer}
                      postFiles={dbPostFiles}
                      users={users}
                    />
                  )}
                {dbPlanRequest.status == PLAN_STATUS.REVIEW &&
                  dbPlanRequest.is_latest == 1 &&
                  isDesignerAdmin(authInfo) && (
                    // 完了
                    <OkCancel
                      ok="レビュー完了"
                      onOk={() => setOpenReviewDoneModal(true)}
                      cancel="差戻し"
                      onCancel={() => setOpenReviewRemandModal(true)}
                    />
                  )}

                <RequestInfo
                  value={dbPlanRequest}
                  designer={designer}
                  designerMessage={dbPlanRequest.designer_message}
                  initial_designer={initial_designer}
                  user={user}
                />
                <LocationInfo showType={2} project={project} sites={sites} />
                <RequestFileList
                  project_id={project_id}
                  plan_id={plan_id}
                  data={dbRequestFiles}
                />
                <SiteInfo value={dbPlanRequest} />
                <DesiredBuildingInfo value={dbPlanRequest} />

                <div style={{ display: "none" }}>
                  <DetailPrint
                    ref={componentRef}
                    project_id={project_id}
                    plan_id={plan_id}
                    dbPlanRequest={dbPlanRequest}
                    users={users}
                    user={user}
                    designer={designer}
                    initial_designer={initial_designer}
                    project={project}
                    sites={sites}
                    dbPostFiles={dbPostFiles}
                    dbRequestFiles={dbRequestFiles}
                  />
                </div>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="text"
                      color="primary"
                      className={classes.print_button}
                    >
                      印刷
                    </Button>
                  )}
                  content={() => componentRef.current}
                  documentTitle={project.project_title + "_" + plan_id + ".pdf"}
                />

                {isSales(authInfo) && (
                  <>
                    {dbPlanRequest.status == PLAN_STATUS.TEMP && (
                      // 一時保存
                      <OkCancel
                        ok="内容を編集する"
                        onOk={() => handleEdit(dbPlanRequest.status)}
                        ok2="削除する"
                        onOk2={() => setOpenDeleteModal(true)}
                      />
                    )}
                    {dbPlanRequest.status == PLAN_STATUS.REQUESTED && (
                      // 依頼中
                      <OkCancel
                        ok="依頼内容変更"
                        onOk={() => handleEdit(dbPlanRequest.status)}
                        cancel="取下げ"
                        onCancel={() => setOpenWithdrawModal(true)}
                      />
                    )}
                    {dbPlanRequest.status == PLAN_STATUS.COMPLETE &&
                      dbPlanRequest.is_latest == 1 && (
                        // 完了
                        <OkCancel
                          ok="修正依頼（軽微な修正）"
                          onOk={handleLittleUpdateModal}
                          ok2="変更依頼（再作成）"
                          onOk2={() => handleEdit(dbPlanRequest.status)}
                        />
                      )}
                    {dbPlanRequest.status == PLAN_STATUS.REMAND &&
                      dbPlanRequest.is_latest == 1 && (
                        // 差戻し

                        <OkCancel
                          ok="内容を編集する"
                          onOk={() => handleEdit(dbPlanRequest.status)}
                          ok2="削除する"
                          onOk2={() => setOpenDeleteModal(true)}
                        />
                      )}
                    {dbPlanRequest.status == PLAN_STATUS.WITHDRAW &&
                      dbPlanRequest.is_latest == 1 && (
                        // 取下げ
                        <OkCancel
                          ok="再依頼"
                          onOk={() => handleEdit(dbPlanRequest.status)}
                          ok2="削除する"
                          onOk2={() => setOpenDeleteModal(true)}
                        />
                      )}
                  </>
                )}

                {isDesigner(authInfo) && (
                  <>
                    {dbPlanRequest.status == PLAN_STATUS.REQUESTED && (
                      <OkCancel
                        ok="受付ける"
                        onOk={() => setOpenStartModal(true)}
                        cancel="差戻し"
                        onCancel={() => setOpenRemandModal(true)}
                      />
                    )}
                    {dbPlanRequest.status == PLAN_STATUS.COMPLETE &&
                      dbPlanRequest.is_latest == 1 &&
                      is_postal && (
                        // 完了
                        <OkCancel
                          ok="引き戻し"
                          onOk={() => setOpenPullBackModal(true)}
                        />
                      )}
                    {dbPlanRequest.status == PLAN_STATUS.REVIEW &&
                      dbPlanRequest.is_latest == 1 &&
                      is_postal && (
                        // レビュー
                        <OkCancel
                          ok="引き戻し"
                          onOk={() => setOpenPullBackModal(true)}
                        />
                      )}
                    {dbPlanRequest.status == PLAN_STATUS.REMAND &&
                      dbPlanRequest.is_latest == 1 &&
                      dbPlanRequest.designer == authInfo.user.id && (
                        /* || isDesignerAdmin(authInfo) )*/
                        // 差戻し
                        <OkCancel
                          ok={
                            dbPlanRequest.is_show_remand == 1
                              ? "この差戻し依頼を一覧に表示しない"
                              : "この差戻し依頼を一覧に表示する"
                          }
                          onOk={() => setOpenSwitchShowRemandModal(true)}
                        />
                      )}
                  </>
                )}

                <Modal
                  title="プランの修正を依頼します"
                  open={openUpdateModal}
                  onClose={() => setOpenUpdateModal(false)}
                  style="large"
                >
                  <LabelValue label="現場名">
                    {project.project_title}
                  </LabelValue>
                  <LabelValue label="顧客名">
                    {project.customer_name}
                  </LabelValue>

                  <LabelValue label="納品希望日" required>
                    <DatePicker
                      id="desired_delivery_date"
                      value={littleUpdate.desired_delivery_date}
                      onChange={handleDesiredDeliveryDate}
                      error={littleUpdateError.desired_delivery_date}
                    />
                  </LabelValue>
                  <LabelValue label="プラン作成件数">
                    <Select
                      id="plan_num"
                      onChange={handlePlanNum}
                      options={planNumList}
                      value={String(littleUpdate.plan_num)}
                      error={littleUpdateError.plan_num}
                      endText="件"
                    />
                  </LabelValue>

                  <InputRequestFile
                    // dbFiles={editRequestFiles}
                    inputFiles={requestFiles}
                    onChange={handleRequestFiles}
                    error={requestFilesError}
                    isModal={true}
                  />

                  <LabelValue label="修正依頼内容" required>
                    <Textarea
                      id="fix_note"
                      value={littleUpdate.fix_note}
                      onChange={handleFixNote}
                      error={littleUpdateError.fix_note}
                    // label="最大100文字"
                    />
                  </LabelValue>
                  <OkCancel
                    onCancel={() => setOpenUpdateModal(false)}
                    onOk={handleLittleUpdate}
                    ok="修正を依頼"
                  />
                </Modal>

                <Modal
                  title="プランを取り消します"
                  open={openWithdrawModal}
                  onClose={() => setOpenWithdrawModal(false)}
                  style="small"
                >
                  よろしいですか？
                  <OkCancel
                    onCancel={() => setOpenWithdrawModal(false)}
                    onOk={handleWithdraw}
                    ok="取り下げる"
                  />
                </Modal>

                <Modal
                  title="この依頼を受付けます。"
                  open={openStartModal}
                  onClose={() => setOpenStartModal(false)}
                  style="normal"
                >
                  納品希望日を確認して、受付を確定してください。
                  <br />
                  <LabelValue label="現場名" grid={3}>
                    {project.project_title}
                  </LabelValue>
                  <LabelValue label="顧客名" grid={3}>
                    {project.customer_name}
                  </LabelValue>
                  <LabelValue label="納品希望日" grid={3}>
                    <DesiredDeliveryDate
                      standardDeliveryDateFlag={
                        dbPlanRequest.standard_delivery_date_flag
                      }
                      date={dbPlanRequest.desired_delivery_date}
                      status={dbPlanRequest.status}
                    />
                  </LabelValue>
                  <br />
                  <OkCancel
                    onCancel={() => setOpenStartModal(false)}
                    onOk={handleStart}
                    ok="受付ける"
                  />
                </Modal>

                <Modal
                  title="この依頼を差戻します。"
                  open={openRemandModal}
                  onClose={() => setOpenRemandModal(false)}
                  style="normal"
                >
                  よろしいですか？
                  <LabelValue
                    label="伝達事項"
                    required
                    grid={3}
                    underline={false}
                  >
                    <Textarea
                      id="post_note"
                      value={remand.note}
                      onChange={handleRemandNote}
                      error={remandError.note}
                    // label="最大100文字"
                    />
                  </LabelValue>
                  <OkCancel
                    onCancel={() => setOpenRemandModal(false)}
                    onOk={handleRemand}
                    ok="差戻す"
                  />
                </Modal>

                <Modal
                  title="この依頼を引き戻します。"
                  open={openPullBackModal}
                  onClose={() => setOpenPullBackModal(false)}
                  style="small"
                >
                  よろしいですか？
                  <OkCancel
                    onCancel={() => setOpenPullBackModal(false)}
                    onOk={handlePullBack}
                    ok="引き戻す"
                  />
                </Modal>

                <Modal
                  title="この依頼を削除します。"
                  open={openDeleteModal}
                  onClose={() => setOpenDeleteModal(false)}
                  style="small"
                >
                  よろしいですか？
                  <OkCancel
                    onCancel={() => setOpenDeleteModal(false)}
                    onOk={handleDelete}
                    ok="削除する"
                  />
                </Modal>

                <Modal
                  title="この投稿のレビューを完了します。"
                  open={openReviewDoneModal}
                  onClose={() => setOpenReviewDoneModal(false)}
                  style="normal"
                >
                  よろしいですか？
                  <LabelValue label="完了コメント" grid={2} underline={false}>
                    <Textarea
                      id="review_complete_note"
                      value={remand.note}
                      onChange={handleRemandNote}
                      error={remandError.note}
                    // label="最大100文字"
                    />
                  </LabelValue>
                  <OkCancel
                    onCancel={() => setOpenReviewDoneModal(false)}
                    onOk={handleReviewDone}
                    ok="完了する"
                  />
                </Modal>

                <Modal
                  title="この投稿を差戻します。"
                  open={openReviewRemandModal}
                  onClose={() => setOpenReviewRemandModal(false)}
                  style="large"
                >
                  よろしいですか？
                  <LabelValue
                    label="差戻し理由"
                    required
                    grid={2}
                    underline={false}
                  >
                    <Textarea
                      id="review_note"
                      value={remand.note}
                      onChange={handleRemandNote}
                      error={remandError.note}
                    // label="最大100文字"
                    />
                  </LabelValue>
                  <InputReviewFile
                    inputFiles={remand.files}
                    error={reviewFilesError}
                    isModal={true}
                    onChange={handleRemandFiles}
                  />
                  <OkCancel
                    onCancel={() => setOpenReviewRemandModal(false)}
                    onOk={handleReviewRemand}
                    ok="差戻す"
                  />
                </Modal>
                <Modal
                  title={
                    dbPlanRequest.is_show_remand == 1
                      ? "この依頼を非表示にします。"
                      : "この依頼を表示にします。"
                  }
                  open={openSwitchShowRemandModal}
                  onClose={() => setOpenSwitchShowRemandModal(false)}
                  style="small"
                >
                  {dbPlanRequest.is_show_remand == 1
                    ? "この依頼を一覧から削除します。"
                    : "この依頼を一覧に表示します。"}
                  <br />
                  よろしいですか？
                  <OkCancel
                    onCancel={() => setOpenSwitchShowRemandModal(false)}
                    onOk={handleSwitchShowRemand}
                    ok="OK"
                  />
                </Modal>
              </>
            )}

            {mode == "edit" && (
              // 編集
              <>
                <Element id="InputRequestInfo" />
                <InputRequestInfo
                  value={requestInfo}
                  onChange={handleRequestInfo}
                  error={requestInfoError}
                  user={user}
                />

                <LocationInfo showType={2} project={project} sites={sites} />

                <Element id="InputRequestFile" />
                <InputRequestFile
                  dbFiles={editRequestFiles}
                  inputFiles={requestFiles}
                  onChange={handleRequestFiles}
                  error={requestFilesError}
                />

                <Element id="InputSiteInfo" />
                <InputSiteInfo
                  value={siteInfo}
                  onChange={handleSiteInfo}
                  error={siteInfoError}
                />

                <Element id="InputDesiredBuildingInfo" />
                <InputDesiredBuildingInfo
                  value={desiredBuildingInfo}
                  onChange={handleDesiredBuildingInfo}
                  error={desiredBuildingInfoError}
                />

                {dbPlanRequest.status == PLAN_STATUS.REQUESTED ? (
                  // 依頼中
                  <OkCancel
                    ok="内容を確認する"
                    onOk={handleConfirm}
                    cancel="キャンセル"
                    onCancel={handleCancel}
                  />
                ) : (
                  <OkCancel
                    ok="内容を確認する"
                    onOk={handleConfirm}
                    ok2="一時保存する"
                    onOk2={handleTemporarySave}
                    cancel="キャンセル"
                    onCancel={handleCancel}
                  />
                )}
              </>
            )}
            {mode === "confirm" && (
              <>
                <Box display="flex" alignItems="center">
                  <Box fontSize={20} mr={5}>
                    依頼内容をご確認ください
                  </Box>
                  <FormHelperText error>
                    <Box fontSize={20}>
                      【注意】依頼は完了しておりません
                    </Box>
                  </FormHelperText>
                </Box>
                <RequestInfo
                  value={requestInfo}
                  user={user}
                  designer={designer}
                  designerMessage={requestInfo.designer_message}
                />
                <LocationInfo showType={2} project={project} sites={sites} />
                <RequestFiles
                  dbFiles={editRequestFiles}
                  requestFiles={requestFiles}
                />
                <SiteInfo value={siteInfo} />
                <DesiredBuildingInfo value={desiredBuildingInfo} />
                <OkCancel
                  ok="この内容で依頼する"
                  onOk={handleUpdate}
                  cancel="キャンセル"
                  onCancel={handleCancelConfirm}
                />
              </>
            )}
          </div>
        </>
      )
      }
      <CallAPI params={apiParams} />
    </Layout >
  );
}

Detail.propTypes = {
  match: PropTypes.object.isRequired,
};
