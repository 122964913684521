import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Input from "../atoms/Input";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    alignItems: "flex-end",
  },
  inputField: {
    width: "200px",
  },
  right: {
    marginLeft: "auto",
    textAlign: "right",
  },
}));

export default function InputRoadWidth(props) {
  const classes = useStyles();

  const handleAttachedFile = (e, value) => {
    if (value === null) {
      return;
    }
    props.onChange({
      is_attached_file: value,
      value: value == 1 ? {} : 1,
    });
  };

  const handleNorth = (e) => {
    props.onChange({
      is_attached_file: 0,
      value: {
        north: e.target.value,
        south: props.value.south,
        east: props.value.east,
        west: props.value.west,
      },
    });
  };
  const handleSouth = (e) => {
    props.onChange({
      is_attached_file: 0,
      value: {
        north: props.value.north,
        south: e.target.value,
        east: props.value.east,
        west: props.value.west,
      },
    });
  };

  const handleEast = (e) => {
    props.onChange({
      is_attached_file: 0,
      value: {
        north: props.value.north,
        south: props.value.south,
        east: e.target.value,
        west: props.value.west,
      },
    });
  };

  const handleWest = (e) => {
    props.onChange({
      is_attached_file: 0,
      value: {
        north: props.value.south,
        south: props.value.south,
        east: props.value.east,
        west: e.target.value,
      },
    });
  };
  const handleOnClick = () => {
    props.onChange({
      is_attached_file: 0,
      value: props.value,
    });
  };

  const selected = props.isAttachedFile;

  return (
    <>
      <div className={classes.right}>
        <ToggleButtonGroup
          value={props.isAttachedFile}
          exclusive
          onChange={handleAttachedFile}
          aria-label="text alignment"
        >
          <ToggleButton value={0} aria-label="left aligned">
            直接入力
          </ToggleButton>
          <ToggleButton value={1} aria-label="centered">
            入力省略(添付参照)
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div className={classes.flex}>
        <div className={classes.inputField}>
          <Input
            id="north"
            type="number"
            inputProps={{ step: "1" }}
            value={props.value.north}
            onChange={handleNorth}
            onClick={handleOnClick}
            disabled={selected === 1}
            placeholder="北側"
            endText="ｍ"
            error={props.error && props.error.north}
          />
        </div>
        <div>&nbsp;</div>
        <div className={classes.inputField}>
          <Input
            id="south"
            type="number"
            inputProps={{ step: "1" }}
            value={props.value.south}
            onChange={handleSouth}
            onClick={handleOnClick}
            disabled={selected === 1}
            placeholder="南側"
            endText="ｍ"
            error={props.error && props.error.south}
          />
        </div>
        <div>&nbsp;</div>
        <div className={classes.inputField}>
          <Input
            id="east"
            type="number"
            inputProps={{ step: "1" }}
            value={props.value.east}
            onChange={handleEast}
            onClick={handleOnClick}
            disabled={selected === 1}
            placeholder="東側"
            endText="ｍ"
            error={props.error && props.error.east}
          />
        </div>
        <div>&nbsp;</div>
        <div className={classes.inputField}>
          <Input
            id="west"
            type="number"
            inputProps={{ step: "1" }}
            value={props.value.west}
            onChange={handleWest}
            onClick={handleOnClick}
            disabled={selected === 1}
            placeholder="西側"
            endText="ｍ"
            error={props.error && props.error.west}
          />
        </div>
      </div>
    </>
  );
}

InputRoadWidth.propTypes = {
  onChange: PropTypes.func,
  isAttachedFile: PropTypes.number,
  value: PropTypes.object,
  error: PropTypes.object,
};
InputRoadWidth.defaultProps = {
  onChange: null,
  isAttachedFile: 1,
  value: {},
  error: {},
};
