import React, { useEffect, useState } from "react";

import User from "../../commons/User";
import Layout from "../../templates/Layout";
import Name from "../../molecules/Name";
import LabelValue from "../../templates/LabelValue";
import Branch from "../../molecules/Branch";
import CallAPI from "../../molecules/CallAPI";
import Index from "../../molecules/Index";
import { roleList } from "../../commons/Constants";
import ListValue from "../../molecules/ListValue";
import Button from "../../atoms/Button";
import SearchUser from "../../molecules/SearchUser";
import { gotoTopUrl, isAdmin } from "../../commons/Functions";

export default function SwitchAccount() {
  useEffect(() => {
    const authInfo = User.getAuthInfo();
    if (!isAdmin(authInfo)) {
      gotoTopUrl(authInfo);
      return;
    }

    setAuthInfo(authInfo);
  }, []);

  const [authInfo, setAuthInfo] = useState();
  const [apiParams, setApiParams] = useState({});
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  const handleChange = (data) => {
    console.log(data);
    setUser(data);
  };

  const handleSwitchAccount = () => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/user/switch_user/`,
      method: "PUT",
      params: { user_id: user.id },
      setData: reload,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const reload = (data) => {
    console.log("reloaded", data);
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/auth/is_login/`,
      method: "GET",
      setData: reloadData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const reloadData = (data) => {
    console.log("reloaded", data);
    const authInfo = { ...data };
    setAuthInfo(authInfo);
    User.resetAuthInfo(authInfo);
    if (!isAdmin(authInfo)) {
      window.open("/profile/", "_self");
    }
  };

  const role = user ? roleList.find((v) => v.value == user.role_code) : null;

  return (
    <Layout
      title="アカウント切り替え"
      breadcrumb={[
        { url: "/setting/", text: "システム設定" },
        { url: "self", text: "アカウント切り替え" },
      ]}
    >
      {authInfo && (
        <>
          <Index value="ユーザー情報" />

          <LabelValue label="ID">{authInfo.user.id}</LabelValue>
          <LabelValue label="名前">
            <Name user={authInfo.user} isHonorific={true} />
          </LabelValue>
          <LabelValue label="メールアドレス">{authInfo.user.email}</LabelValue>
          {authInfo.branch && (
            <LabelValue label="所属">
              <Branch branch={authInfo.branch} />
            </LabelValue>
          )}
          <LabelValue label="権限">
            <ListValue list={roleList} value={authInfo.user.role.code} />
          </LabelValue>

          <Index value="アカウント切り替え" />
          <LabelValue label="アカウント">
            {user != null && (
              <>
                <Name user={user} /> / <>{role ? role.name : ""}</>
              </>
            )}
            <SearchUser
              open={open}
              onClose={() => setOpen(false)}
              onChange={handleChange}
            />
            <div>
              <Button
                variant="text"
                color="primary"
                onClick={() => setOpen(true)}
              >
                ユーザー選択
              </Button>
            </div>
            {user != null && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSwitchAccount()}
                >
                  設定
                </Button>
              </div>
            )}
          </LabelValue>
        </>
      )}
      <CallAPI params={apiParams} />
    </Layout>
  );
}
