import React from "react";
import PropTypes from "prop-types";
// import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { dateToString } from "../commons/Functions";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Datetime from "./Datetime";
import { PLAN_STATUS } from "../commons/Constants";

const useStyles = makeStyles((theme) => ({
  datetime: {
    margin: theme.spacing(0),
  },
  outline: {
    color: "#D83420",
    // verticalAlign: "middle",
  },
  icon: {
    fontSize: "15px",
    // paddingBottom: theme.spacing(-1),
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    color: "#D83420",
    backgroundColor: "#ffffff",
    border: "solid 1px #D83420",
  },
  customArrow: {
    // color: "#D83420",
    "&::before": {
      backgroundColor: "#ffffff",
      border: "1px solid #D83420",
    },
  },
}));

/**
 * 納品希望日
 */
export default function DesiredDeliveryDate(props) {
  const classes = useStyles();

  const datetime = props.date;
  let display = dateToString(props.date);

  let tooltip = null;
  const today = new Date();
  const in_date = new Date(datetime);
  let complete = null;

  if (props.standardDeliveryDateFlag != 1) {
    if (props.status == PLAN_STATUS.COMPLETE && props.postedAt) {
      complete = "納品済";
      display = dateToString(props.postedAt);
    } else if (props.status != PLAN_STATUS.COMPLETE && today > in_date) {
      tooltip = "納品希望日を過ぎています。";
    }
  }

  if (tooltip) {
    return (
      <span className={classes.outline}>
        <Tooltip
          title={tooltip}
          arrow
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
        >
          <InfoOutlinedIcon className={classes.icon} />
        </Tooltip>
        {props.standardDeliveryDateFlag == 1 ? (
          <>標準納期</>
        ) : (
          <>
            <Datetime value={display} isTime={false} />
          </>
        )}
      </span>
    );
  }
  return (
    <>
      {props.standardDeliveryDateFlag == 1 ? (
        <>標準納期</>
      ) : (
        <>
          <Datetime value={display} isTime={false} />
          {complete}
        </>
      )}
    </>
  );
}

DesiredDeliveryDate.propTypes = {
  standardDeliveryDateFlag: PropTypes.number,
  date: PropTypes.object,
  status: PropTypes.number,
  postedAt: PropTypes.object,
};
DesiredDeliveryDate.defaultProps = {
  standardDeliveryDateFlag: 0,
};
