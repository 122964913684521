import React, { useEffect } from "react";
import { gotoTopUrl } from "../../commons/Functions";
import user from "../../commons/User";

import Layout from "../../templates/Layout";

export default function Top() {
  useEffect(() => {
    const authInfo = user.getAuthInfo();
    // console.log(authInfo);
    if (authInfo) {
      gotoTopUrl(authInfo);
    }
  }, []);

  return (
    <Layout breadcrumb={[{ url: "self", text: "" }]} isAuth={false}></Layout>
  );
}
