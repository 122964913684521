import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Input from "../atoms/Input";
import LabelValue from "../templates/LabelValue";
import Checkbox from "../atoms/Checkbox";
import InputSalesUser from "../molecules/InputSalesUser";
import Button from "../atoms/Button";
import { contractStatusList, planStatusList } from "../commons/Constants";
import { isSalesAdmin } from "../commons/Functions";
import User from "../commons/User";

export default function ProjectSearch(props) {
  // const classes = useStyles();
  useEffect(() => {
    // console.log("init", props.searchParams);
    setSearch(props.searchParams);
    setAuthInfo(User.getAuthInfo());
  }, []);

  const [search, setSearch] = useState();
  const [authInfo, setAuthInfo] = useState();

  const handleKeyword = (e) => {
    setSearch({ ...search, keyword: e.target.value });
  };
  const handleSalesUser = (e) => {
    setSearch({
      ...search,
      supervisor: e.supervisor,
      user: e.user,
      branch_name: e.branch_name,
    });
  };
  const handleContractStatus = (e) => {
    // console.log(e);
    setSearch({ ...search, contract_status: e });
  };
  const handlePlanStatus = (e) => {
    setSearch({ ...search, plan_status: e });
  };
  const handleSearch = () => {
    props.onSearch(search);
  };

  if (!search) {
    return <></>;
  }

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          <LabelValue label="キーワード" underline={false}>
            <Input
              id="keyword"
              value={search.keyword}
              onChange={handleKeyword}
              placeholder={"例）顧客名,現場名"}
              onKeyEnter={handleSearch}
            />
          </LabelValue>
          <LabelValue label="営業担当" underline={false}>
            <InputSalesUser
              id="user"
              branch_name={search.branch_name}
              user={search.user}
              onChange={handleSalesUser}
              isCheckDeleteUser={isSalesAdmin(authInfo)}
            />
          </LabelValue>
          <LabelValue label="契約ステータス" underline={false}>
            <Checkbox
              id="contract_status"
              checked={search.contract_status}
              onChange={handleContractStatus}
              options={contractStatusList}
            />
          </LabelValue>
          <LabelValue label="プラン作成状況" underline={false}>
            <Checkbox
              id="plan_status"
              checked={search.plan_status}
              onChange={handlePlanStatus}
              options={planStatusList}
            />
          </LabelValue>

          <LabelValue label="" underline={false}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
            >
              検索
            </Button>
          </LabelValue>
        </Grid>
      </Grid>
    </>
  );
}

ProjectSearch.propTypes = {
  onSearch: PropTypes.func,
  searchParams: PropTypes.object,
};
ProjectSearch.defaultProps = {
  onSearch: null,
};
