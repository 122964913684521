import React, { useEffect, useState } from "react";

import {
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import BuildIcon from "@material-ui/icons/Build";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import MailIcon from "@material-ui/icons/Mail";

import Layout from "../../templates/Layout";
import User from "../../commons/User";
import LabelValue from "../../templates/LabelValue";
import { gotoTopUrl, isAdmin } from "../../commons/Functions";

export default function SettingMenu() {
  const env = `${process.env.REACT_APP_ENV}`;
  const env_name = env + "環境";

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    // console.log(authInfo);
    if (!isAdmin(authInfo) && env == "prod") {
      gotoTopUrl(authInfo);
      return;
    }
    setAuthInfo(authInfo);
  }, []);
  const [authInfo, setAuthInfo] = useState(null);

  return (
    <Layout
      title="DashBoard"
      breadcrumb={[{ url: "self", text: "システム設定" }]}
    >
      <LabelValue label={env_name} />

      <List component="nav" aria-label="main mailbox folders">
        {isAdmin(authInfo) && (
          <>
            <Link href="/setting/mail_template" color="inherit">
              <ListItem button>
                <ListItemIcon>
                  <MailOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="メールテンプレート設定" />
              </ListItem>
            </Link>
            <Link href="/setting/mail_history" color="inherit">
              <ListItem button>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText primary="メール送信履歴" />
              </ListItem>
            </Link>
            <Link href="/setting/switch_account" color="inherit">
              <ListItem button>
                <ListItemIcon>
                  <PersonOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="アカウント切り替え" />
              </ListItem>
            </Link>
          </>
        )}
        {env != "prod" && (
          <Link href="/setting/debug" color="inherit">
            <ListItem button>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="テスト用機能" />
            </ListItem>
          </Link>
        )}
      </List>
      <Divider />
    </Layout>
  );
}
