import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../atoms/Input";
import { Grid } from "@material-ui/core";
import Select from "../atoms/Select";
import { siteNumList } from "../commons/Constants";

const useStyles = makeStyles((theme) => ({
  list: {
    marginRight: theme.spacing(2),
  },
  item: {
    marginTop: theme.spacing(2),
  },
  add: {
    marginRight: theme.spacing(2),
  },
  header: {
    color: "rgba(0, 0, 0, 0.87)",
    borderBottom: "solid 2px #cccccc",
    fontWeight: "800",
  },
}));

export default function InputSiteNum(props) {
  const classes = useStyles();

  useEffect(() => {}, []);

  const handleSiteNum = (e) => {
    const num = e.target.value;
    let site_names = [...props.site_names];

    if (props.site_num <= num) {
      for (let i = props.site_num; i < num; i++) {
        site_names.push(null);
      }
    } else {
      site_names.splice(num, props.site_num - num);
    }

    props.onChange(parseInt(num), site_names);
  };

  const handleSiteInfo = (id, e) => {
    let val = [...props.site_names];
    val[id] = e.target.value;

    let name = e.target.value;
    for (let i = id + 1; i < props.site_num; i++) {
      val[i] = newName(name);
      name = val[i];
    }

    props.onChange(props.site_num, val);
  };

  const newName = (org) => {
    const suffix = org.replace(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*/g, "");
    let prefix = org.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*/g)[0];

    // console.log(prefix, suffix);
    let is_num_type = null;
    let buff = "";
    let pre_buff = "";

    for (let i = prefix.length - 1; i >= 0; i--) {
      const val = prefix.slice(i, i + 1);
      // console.log("val", i, buff);

      if (is_num_type === null) {
        is_num_type = isNum(val);
      }
      buff = val + buff;

      if (i === 0 || is_num_type !== isNum(prefix.slice(i - 1, i))) {
        pre_buff = prefix.slice(0, i);
        // console.log("pre_buff", pre_buff, prefix);
        if (is_num_type === true) {
          const no = parseInt(buff) + 1;
          String(no).padStart(buff.length, "0");
          buff = String(no).padStart(buff.length, "0");
        } else {
          let tmp = buff.slice(0, buff.length - 1);
          let c = buff.slice(buff.length - 1, buff.length);
          if (c == "z") {
            c = "aa";
          } else if (c == "Z") {
            c = "AA";
          } else {
            const code = c.codePointAt(0);
            if (
              ("a".codePointAt(0) <= code && code <= "z".codePointAt(0)) ||
              ("A".codePointAt(0) <= code && code <= "Z".codePointAt(0))
            ) {
              c = String.fromCodePoint(code + 1);
            }
          }
          buff = tmp + c;
          // console.log("uni", buff);
        }
        break;
      }
      // console.log("aaa", val, is_num_type, buff);
    }
    // console.log(pre_buff, buff, suffix);
    return pre_buff + buff + suffix;
  };

  const isNum = (val) => {
    if (!isNaN(parseFloat(val)) && isFinite(val)) {
      return true;
    } else {
      return false;
    }
  };

  var site_names = [];
  for (let i = 0; i < props.site_num; i++) {
    site_names.push(
      <Grid container key={i}>
        <Grid item xs={1} className={classes.item}>
          {String(i + 1).padStart(3, "0")}
        </Grid>
        <Grid item xs={8}>
          <Input
            id="site_name"
            value={props.site_names[i]}
            onChange={(e) => handleSiteInfo(i, e)}
            required
            error={
              props.error_site_names && props.error_site_names[i]
                ? props.error_site_names[i]
                : null
            }
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    );
  }

  return (
    <>
      <Select
        id="site_num"
        value={String(props.site_num)}
        onChange={handleSiteNum}
        options={siteNumList}
        placeholder="棟数"
        error={props.error}
      />
      <div className={classes.list}>
        <Grid container>
          <Grid item xs={1} className={classes.header}>
            No
          </Grid>
          <Grid item xs={8} className={classes.header}>
            号棟名
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        {site_names}
      </div>
    </>
  );
}

InputSiteNum.propTypes = {
  onChange: PropTypes.func,
  site_num: PropTypes.number,
  error: PropTypes.string,
  site_names: PropTypes.array,
  error_site_names: PropTypes.array,
};
InputSiteNum.defaultProps = {
  onChange: null,
};
