import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "../templates/Dialog";
import { sleep } from "../commons/Functions";
import {
  errorMessageSendEmailBody,
  errorMessageSendEmailTitle,
} from "../commons/Constants";
// import History from "../commons/History";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: "rgba(0, 0, 0, 0.2);",
  },
}));

/**
 * 通信制御
 */
export default function CallAPI(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const env = `${process.env.REACT_APP_ENV}`;

  useEffect(() => {
    if (
      Object.keys(props.params).length === 0 ||
      props.params.isSubmit === false ||
      loading
    ) {
      return;
    }

    const access = {
      url: props.params.url,
      method: props.params.method,
      params: props.params.params,
      setData: props.params.setData,
      retValue: props.params.retValue,
      setError: props.params.setError,
      responseType: props.params.responseType ?? "json",
      isLogin: props.params.isLogin ?? false,
    };
    loadData(access);
  }, [props.params, props.params.isSubmit]);

  // データ取得
  const loadData = (access) => {
    env != "prod" && console.log(access);
    setLoading(true);

    const method = access.method.toLowerCase();

    // おまじない
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

    let axios_obj;
    if (method === "get") {
      axios_obj = axios({
        method: "GET",
        url: access.url,
        params: access.params,
        responseType: access.responseType,
      });
    } else if (method === "file") {
      const params = new FormData();
      console.log(params);

      Object.keys(access.params).map((key) => {
        if (key == "request_files") {
          // let file_count = 0;
          access.params[key].forEach((file) => {
            // file_count++;
            params.append("request_files[]", file.file);
            params.append("document_types[]", file.document_type);
          });
        } else if (key == "post_files") {
          // let file_count = 0;
          access.params[key].forEach((file) => {
            // file_count++;
            params.append("post_files[]", file.file);
          });
        } else if (key == "review_files") {
          // let file_count = 0;
          access.params[key].forEach((file) => {
            // file_count++;
            params.append("review_files[]", file.file);
          });
        } else {
          params.append(key, access.params[key]);
          if (access.params[key] == null) {
            params.delete(key);
          }
        }
      });

      axios_obj = axios.post(access.url, params, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    } else {
      // post put delete
      axios_obj = axios[method](access.url, access.params, {
        responseType: access.responseType,
      });
    }

    axios_obj
      .then((response) => {
        sleep(props.params.sleep ?? 0).then(function () {
          setLoading(false);

          env != "prod" && console.log(response.data);
          if (response.status == 291) {
            handleOpenDialog(
              errorMessageSendEmailTitle,
              true,
              errorMessageSendEmailBody,
              false,
              access.setData != null
                ? () =>
                  access.setData(
                    response.data,
                    access.retValue,
                    response.headers
                  )
                : null
            );
          } else {
            if (access.successMessage != null) {
              handleOpenDialog(
                access.successMessage,
                false,
                access.successSubMessage,
                access.isAutoClose,
                access.setData != null
                  ? () =>
                    access.setData(
                      response.data,
                      access.retValue,
                      response.headers
                    )
                  : null
              );
            } else if (access.setData != null) {
              access.setData(response.data, access.retValue, response.headers);
            }
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log("access", error);
        if (!error) {
          return;
        }
        let errorMessage =
          props.params.errorMessage ?? "通信中にエラーが発生しました";
        if (error.response && error.response.status == 404) {
          errorMessage = "データがみつかりません。";
        } else if (error.response && error.response.status == 401) {
          window.open("/logout/", "_self");
          // History.push("/logout/");
          return;
        } else if (error.response && error.response.status == 400) {
          if (access.isLogin) {
            errorMessage = "メールアドレスもしくはパスワードが違います。";
          } else {
            errorMessage = "権限がありません。";
          }
        } else if (error.response && error.response.status == 403) {
          errorMessage = "権限がありません。";
        } else if (error.response && error.response.status > 500) {
          errorMessage =
            "通信エラーが発生しました。通信状態を確認し、再度実行してください。";
        }
        if (access.setError != null) {
          access.setError(error.response);
        }
        handleOpenDialog(errorMessage, true);
      })
      .finally(() => {
        // console.log("finally");
      });

    return;
  };

  // Dialog
  const [dialog, setDialog] = useState({
    dialogMessage: "",
    subMessage: null,
    isOpenDialog: false,
    isErrorDialog: false,
  });

  const handleOpenDialog = (
    message,
    is_error = false,
    sub_message = null,
    is_autoclose = true,
    closeAction = null
  ) => {
    setDialog({
      ...dialog,
      dialogMessage: message,
      subMessage: sub_message,
      isOpenDialog: true,
      isErrorDialog: is_error,
      isAutoClose: is_autoclose,
      closeAction: closeAction,
    });
  };
  const handleCloseDialog = () => {
    setDialog({ ...dialog, isOpenDialog: false });
    if (dialog.closeAction) {
      dialog.closeAction();
    }
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Dialog
        open={dialog.isOpenDialog}
        onClose={handleCloseDialog}
        isError={dialog.isErrorDialog}
        isAutoClose={dialog.isAutoClose}
        subMessage={dialog.subMessage}
      >
        {dialog.dialogMessage}
      </Dialog>
    </>
  );
}

CallAPI.propTypes = {
  params: PropTypes.object,
};

CallAPI.defaultProps = {
  params: {},
};
