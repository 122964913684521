import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import {
  addPageParams,
  dateToString,
  findDesigner,
  getDesignerId,
  getDesignerType,
  isDesignerAdmin,
  stringToDate,
} from "../commons/Functions";
import {
  planStatusList,
  PLAN_STATUS,
  requestPlanList,
  requestTypeList,
  reviewStatusList,
  rowsPerPage,
  yesNoList,
  desiredProductGradeList,
} from "../commons/Constants";
import Table from "../atoms/Table";
import Name from "../molecules/Name";
import Button from "../atoms/Button";
import CallAPI from "../molecules/CallAPI";
import ListValues from "../molecules/ListValues";
import ListValue from "../molecules/ListValue";
import Modal from "../templates/Modal";
import OkCancel from "../molecules/OkCancel";
import DesiredDeliveryDate from "../molecules/DesiredDeliveryDate";
import LabelValue from "../templates/LabelValue";
import History from "../commons/History";
import SearchDesigner from "../molecules/SearchDesigner";
import User from "../commons/User";
import Datetime from "../molecules/Datetime";

/**
 * 一覧
 */
const PlanList = (props) => {
  useEffect(() => {
    const params = props.page ?? {
      ...page,
      offset: 0,
    };
    setPage(params);
    loadData(params);
  }, [props.searchParams]);

  const [apiParams, setApiParams] = useState({});

  const [openStartModal, setOpenStartModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();

  const [users, setUsers] = useState([]);
  const [designers, setDesigners] = useState([]);

  const [openChangeDesigner, setOpenChangeDesigner] = useState(false);
  const [changeDesignerPlanId, setChangeDesignerPlanId] = useState();

  const [plans, setPlans] = useState({
    list: [],
    count: 0,
  });
  // ページ管理
  const [page, setPage] = useState({
    offset: 0,
    limit: rowsPerPage,
    sort: "designer",
    order: "asc",
  });

  // 改ページ
  const handleChangePageNo = (e, offset) => {
    const params = {
      ...page,
      offset: offset,
    };
    setPage(params);
    loadData(params);
  };

  const handleSort = (sort, order) => {
    // console.log(sort, order);
    const params = {
      ...page,
      offset: 0,
      sort: sort,
      order: order,
    };
    setPage(params);
    loadData(params);
  };

  const handleClickDetail = (id) => {
    const plan = plans.list.find((v) => v.id == id);
    if (plan) {
      // window.open(
      //   "/plan/" + encodeURI(plan.project_id) + "/" + encodeURI(plan.id),
      //   "_self"
      // );
      History.push(
        "/plan/" + encodeURI(plan.project_id) + "/" + encodeURI(plan.id)
      );
    }
  };

  const handleOpenStartModal = (id) => {
    const plan = plans.list.find((v) => v.id == id);
    if (plan) {
      setOpenStartModal(true);
      setSelectedPlan(plan);
    }
  };

  const handleCloseStartModal = () => {
    setOpenStartModal(false);
    setSelectedPlan(null);
  };

  const handleStart = () => {
    if (!selectedPlan) {
      setOpenStartModal(false);
      return;
    }
    startPlan(selectedPlan);
  };

  // データ取得
  const loadData = (page) => {
    setSearchState(props.searchParams, page);

    const search_params = {
      ...props.searchParams,
      start_date: dateToString(props.searchParams.start_date),
      end_date: dateToString(props.searchParams.end_date),
      plan_status: props.searchParams.plan_status,
      change_designer_flag: props.searchParams.change_designer_flag.includes(1),
    };

    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/search_plan/`,
      method: "GET",
      params: addPageParams(search_params, page),
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setData = (data) => {
    for (let plan of data.plan_requests.list) {
      plan.desired_delivery_date = stringToDate(plan.desired_delivery_date);
      plan.posted_at = stringToDate(plan.posted_at);
    }
    setPlans(data.plan_requests);
    setUsers(data.users);
    setDesigners(data.designers);
  };

  const setSearchState = (params, page) => {
    props.history.push({
      pathname: props.history.location.pathname,
      state: { search: params, page: page },
    });
  };

  const startPlan = (plan) => {
    const req_param = {
      plan_id: plan.id,
      project_id: plan.project_id,
    };
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/start/`,
      method: "PUT",
      params: req_param,
      setData: setStartData,
      retValue: req_param,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setStartData = (data, retValue) => {
    // window.open(
    //   "/plan/" +
    //     encodeURI(retValue.project_id) +
    //     "/" +
    //     encodeURI(retValue.plan_id),
    //   "_self"
    // );
    History.push(
      "/plan/" +
      encodeURI(retValue.project_id) +
      "/" +
      encodeURI(retValue.plan_id)
    );
  };

  const handleChengeDesigner = (row) => {
    // console.log(row);
    setChangeDesignerPlanId(row.id);
    setOpenChangeDesigner(true);
  };

  const handleDesigner = (user) => {
    // console.log(user);
    const newplans = [];
    for (let plan of plans.list) {
      if (plan.id == changeDesignerPlanId) {
        if (user.trader_id) {
          plan.designer = null;
          plan.design_office_id = user.trader_id;
        } else {
          plan.designer = user.id;
          plan.design_office_id = null;
        }
      }
      newplans.push(plan);
    }
    setPlans({ ...plans, list: newplans });
  };

  // カラム設定

  const getColumns = function (type) {
    // console.log("type", type);

    const columns = [
      {
        id: "request_type",
        label: "依頼区分",
        isSort: true,
        minWidth: 100,
        align: "center",
        format: (value) => {
          const getData = (value) => {
            return <ListValue list={requestTypeList} value={value} />;
          };
          return getData(value);
        },
      },
      {
        id: "status",
        label: "対応状況",
        isSort: true,
        minWidth: 50,
        format: (value) => {
          const getData = (value) => {
            return <ListValue list={planStatusList} value={value} />;
          };
          return getData(value);
        },
      },
      {
        id: "designer",
        label: "設計者",
        isSort: true,
        minWidth: 100,
        format: (value, id, row) => {
          const getDesigner = (id, row) => {
            const designer_id = getDesignerId(row);
            let designer = findDesigner(designers, designer_id, false);
            if (!designer) {
              designer = findDesigner(User.getDesigners(), designer_id, false);
            }
            return (
              <>
                <div>
                  <>{designer}</>
                </div>
                {row.change_designer_flag == 1 &&
                  (row.status == PLAN_STATUS.TEMP ||
                    row.status == PLAN_STATUS.REQUESTED) &&
                  isDesignerAdmin(props.authInfo) && (
                    <Button
                      color="primary"
                      onClick={() => handleChengeDesigner(row)}
                    >
                      担当変更
                    </Button>
                  )}
              </>
            );
          };
          return getDesigner(value, row);
        },
      },
      {
        id: "project_title",
        label: "現場名",
        isSort: true,
        minWidth: 100,
        // format: (value, id, row) => {
        //   return getProjectName(row);
        // },
      },
      {
        id: "desired_product_grade",
        label: "商品グレード",
        isSort: true,
        minWidth: 100,
        format: (value) => {
          const desired_product_grade = desiredProductGradeList.find(
            (v) => v.value == value
          );
          return desired_product_grade ? desired_product_grade.name : "";
        },
      },
      {
        id: "supervisor",
        label: "営業担当",
        isSort: true,
        minWidth: 100,
        format: (value) => {
          const getUserName = (id) => {
            const user = users.find((v) => v.id == id);
            return <Name user={user} />;
          };

          return getUserName(value);
        },
      },
      {
        id: "request_plan",
        label: "必要図面",
        isSort: false,
        minWidth: 50,
        format: (value) => {
          const getRequestPlan = (value) => {
            return (
              <ListValues list={requestPlanList} value={value.split(",")} />
            );
          };
          return getRequestPlan(value);
        },
      },
      {
        id: "plan_num",
        label: "プラン数",
        isSort: true,
        minWidth: 50,
        align: "center",
      },
      {
        id: "requested_at",
        label: "依頼日時",
        isSort: true,
        minWidth: 100,
        align: "center",
        format: (value) => {
          const getData = (value) => {
            return <Datetime value={value} />;
          };
          return getData(value);
        },
      },
      {
        id: "desired_delivery_date",
        label: "納品希望日",
        isSort: true,
        minWidth: 100,
        align: "center",
        format: (value, id, row) => {
          const getData = (value, row) => {
            return (
              <DesiredDeliveryDate
                date={value}
                standardDeliveryDateFlag={row.standard_delivery_date_flag}
                status={row.status}
                postedAt={row.posted_at}
              />
            );
          };
          return getData(value, row);
        },
      },
      {
        id: "review_status",
        label: "レビュー状態",
        isSort: false,
        minWidth: 30,
        format: (value, id) => {
          const getReviewStatus = (val) => {
            return (
              <div>
                <ListValue list={reviewStatusList} value={val} />
              </div>
            );
          };

          return getReviewStatus(value, id);
        },
        align: "center",
      },
      {
        id: "design_office_public_flag",
        label: "設計事務所依頼可否",
        isSort: false,
        minWidth: 50,
        format: (value, id) => {
          const getDesignOfficePublicFlag = (val) => {
            return (
              <div>
                <ListValue list={yesNoList} value={val} />
              </div>
            );
          };

          return getDesignOfficePublicFlag(value, id);
        },
        align: "center",
      },
      {
        id: "detail",
        label: "",
        isSort: false,
        minWidth: 50,
        format: (value, id) => {
          const getDetail = (id) => {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClickDetail(id)}
              >
                詳細
              </Button>
            );
          };
          return getDetail(id);
        },
        align: "center",
      },
      {
        id: "start",
        label: "",
        isSort: false,
        minWidth: 50,
        format: (value, id, row) => {
          const getStart = (id, row) => {
            return row.status == PLAN_STATUS.REQUESTED ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenStartModal(id)}
              >
                受付
              </Button>
            ) : (
              <></>
            );
          };
          return getStart(id, row);
        },
        align: "center",
      },
    ];

    // TODO debug
    if (type) {
      return columns;
    }

    if (type == "external") {
      return columns.filter(
        (v) => v.id != "designer" && v.id != "design_office_public_flag"
      );
    }
    if (type == "user") {
      return columns.filter((v) => v.id != "design_office_public_flag");
    }
    return columns.filter((v) => v.id != "start");
  };

  // console.log("plans", plans);
  const type = getDesignerType(props.authInfo);

  return (
    <>
      <Table
        columns={getColumns(type)}
        data={plans}
        page={page}
        onChangePageNo={handleChangePageNo}
        onClickSort={handleSort}
      />

      {selectedPlan && (
        <Modal
          title="この依頼を受付けます。"
          open={openStartModal}
          onClose={handleCloseStartModal}
          style="normal"
        >
          納品希望日を確認して、受付を確定してください。
          <br />
          <LabelValue label="現場名" grid={3}>
            {selectedPlan.project_title}
          </LabelValue>
          <LabelValue label="顧客名" grid={3}>
            {selectedPlan.customer_name}
          </LabelValue>
          <LabelValue label="納品希望日" grid={3}>
            <DesiredDeliveryDate
              standardDeliveryDateFlag={
                selectedPlan.standard_delivery_date_flag
              }
              date={selectedPlan.desired_delivery_date}
              status={selectedPlan.status}
            />
          </LabelValue>
          <OkCancel
            onCancel={handleCloseStartModal}
            onOk={handleStart}
            ok="受付ける"
          />
        </Modal>
      )}

      <SearchDesigner
        open={openChangeDesigner}
        onClose={() => setOpenChangeDesigner(false)}
        onChange={handleDesigner}
        planId={changeDesignerPlanId}
      />

      <CallAPI params={apiParams} />
    </>
  );
};

PlanList.propTypes = {
  history: PropTypes.object.isRequired,
  searchParams: PropTypes.object,
  page: PropTypes.object,
  authInfo: PropTypes.object,
};

PlanList.defaultProps = {
  searchParams: {},
  authInfo: {},
};

export default withRouter(PlanList);
