import React from "react";
import PropTypes from "prop-types";

import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
import InputSiteArea from "../molecules/InputSiteArea";
import InputUseDistrict from "../molecules/InputUseDistrict";
import InputConstructionVolumeRate from "../molecules/InputConstructionVolumeRate";
import InputHeightDifference from "../molecules/InputHeightDifference";
import InputRoadWidth from "../molecules/InputRoadWidth";
import InputRoadObstacle from "../molecules/InputRoadObstacle";
import InputFireProtection from "../molecules/InputFireProtection";
import InputHeightDistrict from "../molecules/InputHeightDistrict";
import InputAddress from "../molecules/InputAddress";

export default function InputSiteInfo(props) {
  const handleAddress = (e) => {
    // console.log(v);
    props.onChange({
      ...props.value,
      ...e.value,
      is_address: e.is_attached_file,
    });
  };

  const handleSiteArea = (e) => {
    // console.log("handleSiteArea", e);
    props.onChange({
      ...props.value,
      is_site_area: e.is_attached_file,
      site_area: e.value,
    });
  };

  const handleUseDistrict = (e) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      is_use_district: e.is_attached_file,
      use_district: e.value,
    });
  };

  const handleHeightDistrict = (e) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      is_height_district: e.is_attached_file,
      height_district: e.value,
    });
  };
  const handleFireProtection = (e) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      is_fire_protection: e.is_attached_file,
      fire_protection: e.value,
    });
  };
  const handleConstructionVolumeRate = (e) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      is_construction_volume_rate: e.is_attached_file,
      construction_rate: e.value.construction_rate,
      volume_rate: e.value.volume_rate,
    });
  };

  const handleHeightDifference = (e) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      height_difference: e.height_difference,
      height_difference_note: e.value,
    });
  };

  const handleRoadWidth = (e) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      is_road_width: e.is_attached_file,
      road_width_north: e.value.north,
      road_width_south: e.value.south,
      road_width_east: e.value.east,
      road_width_west: e.value.west,
    });
  };

  const handleRoadObstacle = (e) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      is_road_obstacle: e.is_attached_file,
      road_obstacle: e.value,
    });
  };

  // console.log("InputSiteInfo", props.error);

  return (
    <>
      <Index value="敷地情報" required />
      <LabelValue label="現場住所\n(代表地番)">
        <InputAddress
          onChange={handleAddress}
          isAttachedFile={props.value.is_address}
          value={{
            prefecture: props.value.prefecture,
            city: props.value.city,
            address_additional: props.value.address_additional,
          }}
          error={props.error}
        />
      </LabelValue>
      <LabelValue label="敷地面積">
        <InputSiteArea
          isAttachedFile={props.value.is_site_area}
          value={props.value.site_area}
          onChange={handleSiteArea}
          error={props.error.site_area}
        />
      </LabelValue>
      <LabelValue label="用途地域">
        <InputUseDistrict
          isAttachedFile={props.value.is_use_district}
          value={props.value.use_district}
          onChange={handleUseDistrict}
          error={props.error.use_district}
        />
      </LabelValue>
      <LabelValue label="高度地区">
        <InputHeightDistrict
          isAttachedFile={props.value.is_height_district}
          value={props.value.height_district}
          onChange={handleHeightDistrict}
          error={props.error.height_district}
        />
      </LabelValue>
      <LabelValue label="防火指定">
        <InputFireProtection
          isAttachedFile={props.value.is_fire_protection}
          value={props.value.fire_protection}
          onChange={handleFireProtection}
          error={props.error.fire_protection}
        />
      </LabelValue>

      <LabelValue label="建蔽率/容積率">
        <InputConstructionVolumeRate
          isAttachedFile={props.value.is_construction_volume_rate}
          value={{
            construction_rate: props.value.construction_rate,
            volume_rate: props.value.volume_rate,
          }}
          onChange={handleConstructionVolumeRate}
          error={{
            construction_rate: props.error.construction_rate,
            volume_rate: props.error.volume_rate,
          }}
        />
      </LabelValue>
      <LabelValue label="高低差">
        <InputHeightDifference
          heightDifference={props.value.height_difference}
          value={props.value.height_difference_note}
          onChange={handleHeightDifference}
          error={props.error.height_difference}
          error_note={props.error.height_difference_note}
        />
      </LabelValue>
      <LabelValue label="前面道路幅員">
        <InputRoadWidth
          isAttachedFile={props.value.is_road_width}
          value={{
            north: props.value.road_width_north,
            south: props.value.road_width_south,
            east: props.value.road_width_east,
            west: props.value.road_width_west,
          }}
          onChange={handleRoadWidth}
          error={{
            north: props.error.road_width_north,
            south: props.error.road_width_south,
            east: props.error.road_width_east,
            west: props.error.road_width_west,
          }}
        />
      </LabelValue>
      <LabelValue label="前面道路障害物">
        <InputRoadObstacle
          isAttachedFile={props.value.is_road_obstacle}
          value={props.value.road_obstacle}
          onChange={handleRoadObstacle}
          error={props.error.road_obstacle}
        />
      </LabelValue>
    </>
  );
}

InputSiteInfo.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
};
InputSiteInfo.defaultProps = {
  onChange: null,
  value: {},
  error: {},
};
