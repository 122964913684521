import React from "react";
import PropTypes from "prop-types";

import MuTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "material-ui-flat-pagination";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { InputLabel } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Button from "./Button";
// import ListValues from "../molecules/ListValues";

const useStyles = makeStyles((theme) => ({
  table: {
    // marginTop: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  hover: {
    "&$hover:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  pagination: {
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  circleButton: {
    borderRadius: "16px",
    minWidth: "28px",
    padding: "4px 4px",
  },
  sort: {
    display: "flex",
    flexFlow: "column",
    marginLeft: "2px",
  },
  th: {
    display: "flex",
    alignItems: "center",
  },
  on: {
    color: "#eeee00",
  },
}));

/**
 * table
 */
export default function Table(props) {
  const classes = useStyles();

  const handleClickRow = (row_id) => {
    if (props.onClickRow) {
      props.onClickRow(row_id);
    }
  };

  const handleClickSort = (id, order) => {
    if (props.onClickSort) {
      props.onClickSort(id, order);
    }
  };

  const handleClickAll = () => {
    if (props.onClickAll) {
      props.onClickAll();
    }
  };

  if (!props.columns || props.columns.length == 0) {
    return <></>;
  }
  if (props.data.length == 0) {
    return <></>;
  }


  const page_no = props.data.has_next !== undefined ? props.page.offset / props.page.limit + 1 : null;

  // console.log("render takken user list");
  return (
    <>
      <TableContainer className={classes.table}>
        {props.data.count != null && (
          <InputLabel>
            全{props.data.count.toLocaleString("jp")}
            {props.unit}
          </InputLabel>
        )}
        <MuTable>
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <div className={classes.th}>
                    <div>
                      {column.label.split(/\n/g).map((v, k) => {
                        return <div key={k}>{v}</div>;
                      })}
                    </div>

                    {column.isSort && (
                      <div className={classes.sort}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleClickSort(column.id, "asc")}
                          startIcon={<KeyboardArrowUpIcon />}
                          style="sort"
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleClickSort(column.id, "desc")}
                          startIcon={<KeyboardArrowDownIcon />}
                          style="sort"
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
              {props.onClickRow && (
                <TableCell align="right">
                  {props.onClickAll && props.data.count != 0 && (
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => handleClickAll()}
                    // className={classes.all}
                    >
                      一括ダウンロード
                    </Button>
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.list.map((row) => {
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={classes.hover}
                >
                  {props.columns.map((column) => {
                    const value = row[column.id];
                    // console.log(column.component);
                    // console.log(isset(column.component));
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format
                          ? column.format(value, row.id, row)
                          : value}
                      </TableCell>
                    );
                  })}

                  {props.onClickRow && (
                    <TableCell align="right">
                      <Button
                        variant={props.variant}
                        color="primary"
                        onClick={() => handleClickRow(row.id)}
                        className={
                          props.isCircleButton ? classes.circleButton : null
                        }
                      >
                        {props.buttonLabel}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </MuTable>
      </TableContainer>
      {props.page && (
        <>
          {props.data.count !== undefined && (
            <Pagination
              limit={props.page.limit}
              offset={props.page.offset}
              total={props.data.count}
              onClick={props.onChangePageNo}
              className={classes.pagination}
            />
          )}
          {props.data.has_next !== undefined && (
            <Pagination
              limit={props.page.limit}
              offset={props.page.offset}
              total={props.data.has_next ? props.page.limit * page_no + 1 : props.page.limit * page_no}
              onClick={props.onChangePageNo}
              className={classes.pagination}
            />
          )}
        </>
      )}
    </>
  );
}

Table.propTypes = {
  onClickRow: PropTypes.func,
  onChangePageNo: PropTypes.func,
  onClickSort: PropTypes.func,
  onClickAll: PropTypes.func,
  columns: PropTypes.array,
  data: PropTypes.object,
  page: PropTypes.object,
  buttonLabel: PropTypes.node,
  isCircleButton: PropTypes.bool,
  unit: PropTypes.string,
  variant: PropTypes.string,
};

Table.defaultProps = {
  columns: [],
  data: {},
  page: null,
  buttonLabel: <>詳細</>,
  isCircleButton: false,
  unit: "件",
  variant: "contained",
};
