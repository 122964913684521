import React from "react";
import PropTypes from "prop-types";

export default function ListValue(props) {
  const value = props.list.find((v) => v.value == props.value);

  return <>{value ? value.name : ""}</>;
}

ListValue.propTypes = {
  list: PropTypes.array,
  value: PropTypes.any,
};
ListValue.defaultProps = {
  list: [],
  value: 0,
};
