import React from "react";
import PropTypes from "prop-types";
import { publicFlagList } from "../commons/Constants";
import LabelValue from "../templates/LabelValue";

export default function PublicFlag(props) {
  const value = publicFlagList[props.value] ?? "";
  const label = props.isLabel ? "登録区分" : null;

  return (
    <LabelValue label={label} showType={props.showType}>
      {value}
    </LabelValue>
  );
}

PublicFlag.propTypes = {
  value: PropTypes.number,
  isLabel: PropTypes.bool,
  showType: PropTypes.number,
};
PublicFlag.defaultProps = {
  value: 0,
  isLabel: false,
  showType: 1,
};
