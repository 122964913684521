import React from "react";
import PropTypes from "prop-types";

import LabelValue from "../templates/LabelValue";
import DesiredDeliveryDate from "../molecules/DesiredDeliveryDate";
import Message from "../templates/Message";
import Datetime from "../molecules/Datetime";
import { dateToString, getFixNoteLabel, isset } from "../commons/Functions";
import { PLAN_STATUS } from "../commons/Constants";

export default function RequestInfoHead(props) {
  const note_label = getFixNoteLabel(props.plan);

  return (
    <>
      {props.plan.status == PLAN_STATUS.REMAND && (
        <LabelValue label="">
          <Message label="差戻しコメント" showType={2}>
            {props.plan.post_note}
          </Message>
        </LabelValue>
      )}
      <LabelValue label="現在の設計担当">
        <>{props.designer}</>
      </LabelValue>
      <LabelValue label="納品希望日">
        <DesiredDeliveryDate
          standardDeliveryDateFlag={props.plan.standard_delivery_date_flag}
          date={props.plan.desired_delivery_date}
          status={props.plan.status}
        />
      </LabelValue>
      <LabelValue label="" underline={false}>
        <Message
          label={note_label}
          showType={2}
          isDisplay={
            isset(props.plan.fix_note) && isset(props.plan.fix_note.trim())
          }
        >
          {props.plan.fix_note}
        </Message>
      </LabelValue>
      {(props.plan.status == PLAN_STATUS.COMPLETE ||
        props.plan.status == PLAN_STATUS.REMAND) && (
        <LabelValue label="投稿日時">
          <Datetime value={dateToString(props.plan.posted_at, true)} />
        </LabelValue>
      )}
    </>
  );
}

RequestInfoHead.propTypes = {
  onChange: PropTypes.func,
  plan: PropTypes.object,
  designer: PropTypes.string,
};
RequestInfoHead.defaultProps = {
  onChange: null,
  plan: {},
};
