import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "95%",
    marginTop: theme.spacing(1),
  },
  asterisk: {
    color: "#D83420",
  },
  flex: {
    display: "flex",
    alignItems: "flex-end",
    // margin: "auto 0px",
  },
}));

/**
 * inputタグ
 */
export default function Input(props) {
  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <TextField
        id={props.id}
        type={props.type}
        label={props.placeholder ?? ""}
        value={props.value ?? ""}
        variant="outlined"
        onChange={props.onChange}
        onClick={props.onClick}
        className={classes.inputField}
        disabled={props.disabled}
        error={props.error !== null}
        helperText={props.error === null ? "" : props.error}
        required={props.required}
        InputLabelProps={{
          classes: {
            asterisk: classes.asterisk,
          },
        }}
        inputProps={props.inputProps}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            if (props.onKeyEnter) {
              props.onKeyEnter();
            }
          }
        }}
      />
      {props.endText && <div>&nbsp;{props.endText}</div>}
    </div>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  inputProps: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  endText: PropTypes.string,
  onKeyEnter: PropTypes.func,
};
Input.defaultProps = {
  id: "",
  type: "text",
  inputProps: {},
  value: "",
  disabled: false,
  error: null,
  required: false,
  placeholder: null,
  endText: null,
};
