import React from "react";
import PropTypes from "prop-types";

import MuDialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import { makeStyles } from "@material-ui/core/styles";
import { dialogShowTime } from "../commons/Constants";
import Button from "../atoms/Button";

const useStyles = makeStyles((theme) => ({
  dialog_content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    minWidth: "320px",
  },
  paper: {
    textAlign: "center",
    marginTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
  },
  icon: {
    textAlign: "center",
    color: "#1e90ff",
    fontSize: "60px",
    margin: theme.spacing(0),
  },
  iconError: {
    textAlign: "center",
    color: "#dc143c",
    fontSize: "60px",
    margin: theme.spacing(0),
  },
  sub_message: {
    textAlign: "center",
    marginTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    whiteSpace: "pre-wrap",
    wordRrap: "break-word",
  },
  button: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
}));

export default function Dialog(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  // const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    props.onClose();
  };

  if (props.open && props.isAutoClose) {
    setTimeout(() => {
      handleClose();
    }, dialogShowTime);
  }

  return (
    <MuDialog
      open={props.open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="common-dialog-title"
      aria-describedby="common-dialog-description"
      style={{
        zIndex: 1301,
      }}
    >
      <DialogContent className={classes.dialog_content}>
        <div>
          {!props.isError && (
            <h2 id="simple-modal-title" className={classes.icon}>
              <CheckCircleOutlineIcon color="inherit" fontSize="inherit" />
            </h2>
          )}
          {props.isError && (
            <h2 id="simple-modal-title" className={classes.iconError}>
              <ErrorOutlineIcon color="inherit" fontSize="inherit" />
            </h2>
          )}

          <h2 id="simple-modal-description" className={classes.paper}>
            {props.children}
          </h2>
          {props.subMessage != null && (
            <div className={classes.sub_message}>{props.subMessage}</div>
          )}
          {!props.isAutoClose && (
            <div className={classes.button}>
              <Button color="primary" onClick={handleClose}>
                とじる
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </MuDialog>
  );
}

Dialog.propTypes = {
  open: PropTypes.bool,
  label: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  update: PropTypes.number,
  isError: PropTypes.bool,
  isAutoClose: PropTypes.bool,
  subMessage: PropTypes.string,
};
Dialog.defaultProps = {
  isError: false,
  isAutoClose: true,
  subMessage: null,
};
