import React from "react";
import PropTypes from "prop-types";
import { desiredDeliveryDateList } from "../commons/Constants";
import Radio from "../atoms/Radio";
import DatePicker from "../atoms/DatePicker";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    margin: theme.spacing(0),
  },
}));

export default function InputDesiredDeliveryDate(props) {
  const classes = useStyles();

  const handleSelect = (e) => {
    const selected = e.target.value;

    if (selected == 1) {
      props.onChange({
        standard_delivery_date_flag: 1,
        desired_delivery_date: null,
      });
    }
    if (selected == 2) {
      props.onChange({
        standard_delivery_date_flag: 0,
        desired_delivery_date: props.desiredDeliveryDate,
      });
    }
  };

  const handleDate = (e) => {
    props.onChange({
      standard_delivery_date_flag: 0,
      desired_delivery_date: e,
    });
  };
  const handleOnClick = () => {
    props.onChange({
      standard_delivery_date_flag: 0,
      desired_delivery_date: props.desiredDeliveryDate,
    });
  };

  const selected = props.standardDeliveryDateFlag == 0 ? 2 : 1;

  return (
    <div className={classes.flex}>
      <Radio
        id="desired_delivery_date_list"
        onChange={handleSelect}
        options={desiredDeliveryDateList}
        value={String(selected)}
        // error={props.error.desired_delivery_date_list}
      />
      <DatePicker
        id="desired_delivery_date"
        value={props.desiredDeliveryDate}
        onChange={handleDate}
        onClick={handleOnClick}
        disabled={selected == "1"}
        error={props.error}
      />
    </div>
  );
}

InputDesiredDeliveryDate.propTypes = {
  onChange: PropTypes.func,
  standardDeliveryDateFlag: PropTypes.number,
  desiredDeliveryDate: PropTypes.object,
  error: PropTypes.string,
};
InputDesiredDeliveryDate.defaultProps = {
  onChange: null,
  standardDeliveryDateFlag: null,
  desiredDeliveryDate: null,
};
