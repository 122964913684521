import React from "react";
import PropTypes from "prop-types";

import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
import Radio from "../atoms/Radio";
import {
  constructTypeList,
  desiredProductGradeList,
  existenceList,
  floorNumList,
} from "../commons/Constants";
import Input from "../atoms/Input";
import Textarea from "../atoms/Textarea";

export default function InputDesiredBuildingInfo(props) {
  const handleAttachedFile = (e) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      desired_attached_file_flag: e.target.value,
    });
  };

  const handleConstructType = (e) => {
    props.onChange({ ...props.value, desired_construct_type: e.target.value });
  };

  const handledProductGrade = (e) => {
    props.onChange({ ...props.value, desired_product_grade: e.target.value });
  };

  const handleFloorNum = (e) => {
    props.onChange({ ...props.value, desired_floor_num: e.target.value });
  };

  const handleBuildingArea = (e) => {
    props.onChange({ ...props.value, desired_building_area: e.target.value });
  };

  const handleFloorPlan = (e) => {
    props.onChange({ ...props.value, desired_floor_plan: e.target.value });
  };

  const handleGrageNum = (e) => {
    props.onChange({ ...props.value, desired_garage_num: e.target.value });
  };

  const handleNote = (e) => {
    props.onChange({ ...props.value, desired_note: e.target.value });
  };

  // console.log("InputDesiredBuildingInfo", props.error);

  return (
    <>
      <Index value="建物の希望" />
      <LabelValue label="資料の有無">
        <Radio
          id="desired_attached_file_flag"
          onChange={handleAttachedFile}
          options={existenceList}
          value={String(props.value.desired_attached_file_flag)}
          // row={false}
          error={props.error.desired_attached_file_flag}
        />
      </LabelValue>
      <LabelValue label="建物種別">
        <Radio
          id="desired_construct_type"
          onChange={handleConstructType}
          options={constructTypeList}
          value={String(props.value.desired_construct_type)}
          // row={false}
          error={props.error.desired_construct_type}
        />
      </LabelValue>
      <LabelValue label="商品グレード">
        <Radio
          id="desired_product_grade"
          onChange={handledProductGrade}
          options={desiredProductGradeList}
          value={String(props.value.desired_product_grade)}
          // row={false}
          error={props.error.desired_product_grade}
        />
      </LabelValue>
      <LabelValue label="階数">
        <Radio
          id="desired_floor_num"
          onChange={handleFloorNum}
          options={floorNumList}
          value={String(props.value.desired_floor_num)}
          // row={false}
          error={props.error.desired_floor_num}
        />
      </LabelValue>
      <LabelValue label="希望建物面積">
        <Input
          id="desired_building_area"
          value={props.value.desired_building_area}
          onChange={handleBuildingArea}
          placeholder="例)50㎡"
          error={props.error.desired_building_area}
          inputProps={{ maxLength: 50 }}
        />
      </LabelValue>
      <LabelValue label="希望間取り">
        <Input
          id="desired_floor_plan"
          value={props.value.desired_floor_plan}
          onChange={handleFloorPlan}
          placeholder="例)3LDK"
          error={props.error.desired_floor_plan}
          inputProps={{ maxLength: 50 }}
        />
      </LabelValue>

      <LabelValue label="車庫数">
        <Input
          id="desired_garage_num"
          value={props.value.desired_garage_num}
          onChange={handleGrageNum}
          placeholder="例)5台分"
          error={props.error.desired_garage_num}
          inputProps={{ maxLength: 50 }}
        />
      </LabelValue>
      <LabelValue label="その他要望/補足">
        <Textarea
          id="desired_note"
          value={props.value.desired_note}
          onChange={handleNote}
          error={props.error.desired_note}
          inputProps={{ maxLength: 1000 }}
        />
      </LabelValue>
    </>
  );
}

InputDesiredBuildingInfo.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
};
InputDesiredBuildingInfo.defaultProps = {
  onChange: null,
  value: {},
  error: {},
};
