import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { dateFormat, isset } from "../commons/Functions";

const useStyles = makeStyles((theme) => ({
  datetime: {
    margin: theme.spacing(0),
  },
}));

/**
 * 日付
 */
export default function Datetime(props) {
  const classes = useStyles();

  let datetime = isset(props.value)
    ? dateFormat(props.value, props.isTime)
    : "";

  return <span className={classes.datetime}>{datetime}</span>;
}

Datetime.propTypes = {
  value: PropTypes.string,
  isTime: PropTypes.bool,
};
Datetime.defaultProps = {
  value: null,
  isTime: true,
};
