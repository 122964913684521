import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// import Button from "../../atoms/Button";
import Modal from "../templates/Modal";
import OkCancel from "../molecules/OkCancel";
import { Grid } from "@material-ui/core";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import Table from "../atoms/Table";
import { rowsPerPage } from "../commons/Constants";
import { addPageParams } from "../commons/Functions";
import CallAPI from "./CallAPI";

const columns = [{ id: "customer_name", label: "顧客名", minWidth: 150 }];

export default function SearchCustomer(props) {
  useEffect(() => {}, []);

  const [apiParams, setApiParams] = useState({});
  const [keyword, setKeywoed] = useState("");
  const [kizunaCustomers, setKizunaCustomers] = useState({
    list: [],
    count: 0,
  });

  // ページ管理
  const [page, setPage] = useState({
    offset: 0,
    limit: rowsPerPage,
  });

  const handleKeyword = (e) => {
    // props.onClose();
    setKeywoed(e.target.value);
  };

  const handleSearch = () => {
    const params = {
      ...page,
      offset: 0,
    };
    setPage(params);
    loadData(params);
  };

  const loadData = (page) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/kizuna/search_customer/`,
      method: "GET",
      params: addPageParams({ keyword: keyword }, page, false),
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = (data) => {
    // console.log(data);
    setKizunaCustomers(data);
  };

  // 改ページ
  const handleChangePageNo = (e, offset) => {
    const params = {
      ...page,
      offset: offset,
    };
    setPage(params);
    loadData(params);
  };

  const handleClickRow = (id) => {
    const customer = kizunaCustomers.list.find((c) => c.id == id);
    // console.log(customer);
    props.onChange(customer);
    props.onClose();
  };

  const handleCancel = () => {
    props.onClose();
  };

  return (
    <Modal
      title="既存顧客選択"
      open={props.open}
      onClose={props.onClose}
      style="normal"
    >
      <Grid container alignItems="baseline" justifyContent="center">
        <Grid item xs={7}>
          <Input
            id="keyword"
            value={keyword}
            onChange={handleKeyword}
            placeholder={"例）顧客名"}
            onKeyEnter={handleSearch}
          />
        </Grid>
        <Grid item xs={5}>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            検索
          </Button>
        </Grid>
      </Grid>

      <Table
        columns={columns}
        data={kizunaCustomers}
        page={page}
        onChangePageNo={handleChangePageNo}
        onClickRow={handleClickRow}
        buttonLabel={<>選択</>}
      />

      <OkCancel onCancel={handleCancel} />
      <CallAPI params={apiParams} />
    </Modal>
  );
}

SearchCustomer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};
SearchCustomer.defaultProps = {
  open: false,
};
