import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  FormControlLabel,
  FormHelperText,
  RadioGroup,
} from "@material-ui/core";
import MuRadio from "@material-ui/core/Radio";

const useStyles = makeStyles((theme) => ({
  inputField: {
    // width: "100%",
    marginTop: theme.spacing(1),
  },
  asterisk: {
    margin: theme.spacing(1),
    color: "#D83420",
  },
}));

/**
 * radio
 */
export default function Radio(props) {
  const classes = useStyles();

  const options = props.options.sort((a, b) => a.sort_order - b.sort_order);

  var select = [];
  for (var v of options) {
    select.push(
      <FormControlLabel
        key={v.value}
        value={String(v.value)}
        control={<MuRadio color="primary" />}
        label={v.name}
      />
    );
  }

  return (
    <div className={classes.inputField}>
      <RadioGroup
        aria-label={props.id}
        name={props.id}
        value={props.value}
        onChange={props.onChange}
        row={props.row}
      >
        {select}
      </RadioGroup>
      <FormHelperText error={props.error !== null}>
        {props.error}
      </FormHelperText>
    </div>
  );
}

Radio.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  error: PropTypes.string,
  row: PropTypes.bool,
};
Radio.defaultProps = {
  id: "",
  value: null,
  onChange: null,
  options: [],
  error: null,
  row: true,
};
