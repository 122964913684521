import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { Button, InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  outline: {
    width: "100%",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "flex-end",
  },
  underline: {
    marginBottom: theme.spacing(1),
    borderBottom: "solid 2px #cccccc",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  index: {
    fontSize: "15px",
    // fontWeight: "800",
    marginLeft: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(-1),
  },
  required: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-1) / 2,
    background: "#D83420",
    borderRadius: "2px",
    color: "#ffffff",
    fontWeight: "600",
    // width: "45px",
    textAlign: "center",
    padding: "2px 8px",
    fontSize: "10px",
    lineHeight: "1",
  },
}));

export default function Index(props) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        props.isUnderline ? classes.underline : null,
        classes.outline
      )}
    >
      <InputLabel className={props.isUnderline ? classes.index : null}>
        {props.startIcon}
        {props.value}
      </InputLabel>
      {props.required && <span className={classes.required}>必須</span>}

      {props.button && (
        <Button
          variant="contained"
          color="primary"
          onClick={props.onClick}
          className={classes.button}
        >
          {props.button}
        </Button>
      )}
    </div>
  );
}

Index.propTypes = {
  value: PropTypes.string,
  button: PropTypes.string,
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  isUnderline: PropTypes.bool,
  required: PropTypes.bool,
};
Index.defaultProps = {
  value: "",
  button: null,
  onClick: null,
  isUnderline: true,
  required: false,
};
