import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  name: {
    width: "100px",
    border: "1px solid #999999",
    textAlign: "center",
  },
}));

/**
 * 拠点
 */
export default function Branch(props) {
  const classes = useStyles();

  // console.log("BranchName", props.name, props.branch);

  let name = props.name ?? null;

  if (props.branch && props.branch.name) {
    name = props.branch.name;
  }

  return (
    <>
      {name && <div className={props.isRaw ? null : classes.name}>{name}</div>}
    </>
  );
}

Branch.propTypes = {
  branch: PropTypes.object,
  name: PropTypes.string,
  isRaw: PropTypes.bool,
};
Branch.defaultProps = {
  branch: null,
  name: null,
  isRaw: false,
};
