import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { withRouter } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import CreateNewFolder from "@material-ui/icons/CreateNewFolderOutlined";
import { FormHelperText } from "@material-ui/core";
// import { Link } from "@material-ui/core";

import { getFileSize, isValid, validateString } from "../commons/Functions";
import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
import Table from "../atoms/Table";
import Button from "../atoms/Button";
import Textarea from "../atoms/Textarea";
import OkCancel from "../molecules/OkCancel";
import { acceptFileTypeList } from "../commons/Constants";
import Modal from "../templates/Modal";
import CallAPI from "../molecules/CallAPI";
import { getFileError, validatePostFile } from "../pages/plan/Validate";
import User from "../commons/User";
import ReviewFileList from "../molecules/ReviewFileList";
import PostFileList from "../molecules/PostFileList";
// import History from "../commons/History";

const useStyles = makeStyles((theme) => ({
  outline: {
    width: "500px",
    height: "100px",
    border: "1px solid #999999",
    borderRadius: "8px",
    display: "table-cell",
    verticalAlign: "middle",
    backgroundColor: "#eeeeee",
    padding: "8px",
  },
  drop: {
    textAlign: "center",
  },
  showFiles: {
    marginBottom: theme.spacing(1),
    width: "640px",
  },
  icon: {
    fontSize: "50px",
  },
}));

const InputPostFile = (props) => {
  const classes = useStyles();

  const plan_id = props.plan.id;
  const project_id = props.plan.project_id;

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    setIsReview(
      authInfo.user.position_id == "03" ||
        authInfo.user.trader_classification == 1
    );

    setDbFiles(props.dbFiles);
    setPostData({ ...postData, post_note: props.plan.post_note });
  }, [props.dbFiles]);

  const [isReview, setIsReview] = useState(false);
  const [dbFiles, setDbFiles] = useState([]);

  const [postData, setPostData] = useState({
    files: [],
    post_note: null,
  });
  const [postDataError, setPostDataError] = useState({
    post_files: {},
    post_note: null,
  });

  const onDropAccepted = useCallback(
    (files) => {
      const newfiles = [...postData.files];
      for (var file of files) {
        newfiles.push({
          file: file,
        });
      }
      setPostData({ ...postData, files: newfiles });
    },
    [postData]
  );

  const onDropRejected = useCallback((files) => {
    console.log("rejected files", files);
    // TODO エラーファイル処理
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFileTypeList,
    onDropAccepted,
    onDropRejected,
  });

  const handleDeleteFile = (id) => {
    const ids = id.split("_");

    if (ids[0] === "input") {
      const newfiles = [...postData.files];
      newfiles.splice(ids[1], 1);

      setPostData({ ...postData, files: newfiles });
    } else if (ids[0] === "db") {
      const newfiles = dbFiles.filter((v) => v.id != ids[1]);

      setDbFiles(newfiles);
    }
  };

  const handlePostNote = (e) => {
    // console.log(postData);
    setPostData({ ...postData, post_note: e.target.value });
  };

  const [apiParams, setApiParams] = useState({});
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  // 受付前に戻す
  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
  };
  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  const handleCancel = () => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/cancel/`,
      method: "PUT",
      params: {
        project_id: project_id,
        plan_id: plan_id,
      },
      setData: setDataCancel,
      isSubmit: true,
    };
    setApiParams(params);
  };
  const setDataCancel = (data) => {
    window.open(
      "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id),
      "_self"
    );
    // History.push(
    //   "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id)
    // );

    return;
  };

  // 投稿完了
  const handleOpenSaveModal = () => {
    // 登録済みの修正
    let length = 0;
    let size = 0;
    for (let data of dbFiles) {
      length++;
      size += data.size;
    }

    const error = {
      post_files: validatePostFile(postData.files, length, size),
      post_note: validateString("伝達事項", postData.post_note, 1000),
    };

    setPostDataError(error);
    if (!isValid(error)) {
      console.log("error", error);
      return;
    }
    setOpenSaveModal(true);
  };
  const handleCloseSaveModal = () => {
    setOpenSaveModal(false);
  };

  const handleSave = () => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/post/`,
      method: "FILE",
      params: {
        project_id: project_id,
        plan_id: plan_id,
        post_files: postData.files,
        edit_post_files: JSON.stringify(dbFiles),
        post_note: postData.post_note,
        timestamp: new Date(),
      },
      setData: setDataSave,
      isSubmit: true,
    };
    setApiParams(params);
  };
  const setDataSave = (data) => {
    window.open(
      "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id),
      "_self"
    );
    // History.push(
    //   "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id)
    // );

    return;
  };

  var file_error = getFileError(postDataError.post_files);

  const db_show_files = dbFiles.map((data) => {
    return {
      id: "db_" + data.id,
      filename: data.filename,
      size: getFileSize(data.size),
    };
  });

  const input_show_files = postData.files.map((data, key) => {
    return {
      id: "input_" + key,
      no: parseInt(key) + 1,
      filename: data.file.name,
      size: getFileSize(data.file.size),
    };
  });

  const show_files = [...db_show_files, ...input_show_files];

  const getFileName = (value) => {
    // console.log(row);
    // return <Link onClick={() => handleClickPreview(id)}>{value}</Link>;
    return <>{value}</>;
  };

  const columns = [
    // { id: "no", label: "No", minWidth: 10 },
    {
      id: "filename",
      label: "ファイル名",
      minWidth: 150,
      format: (value, id, row) => {
        return getFileName(value, id, row);
      },
    },
    { id: "size", label: "サイズ", minWidth: 50 },
  ];

  return (
    <>
      {props.isPostal && <Index value="作成したプラン図面を投稿してください" />}
      {props.plan.review_note !== null && (
        <LabelValue
          label="設計部責任者から\n右記理由で差戻されました"
          isWrap={true}
        >
          <>{props.plan.review_note}</>
        </LabelValue>
      )}
      {props.reviewFiles.length !== 0 && (
        <LabelValue label="レビューファイル">
          <ReviewFileList
            project_id={project_id}
            plan_id={plan_id}
            data={props.reviewFiles}
            users={props.users}
          />
        </LabelValue>
      )}
      {props.isPostal ? (
        <>
          <LabelValue label="ファイル" required>
            <div {...getRootProps()} className={classes.outline}>
              <div className={classes.drop}>
                <div>
                  <CreateNewFolder className={classes.icon} />
                </div>
                <div>ここにファイルをドラッグ＆ドロップ</div>
                <div>もしくは、</div>
                <div>
                  <Button variant="contained" color="primary">
                    ファイル選択
                  </Button>
                </div>
                <input {...getInputProps()} />
              </div>
            </div>

            <div className={classes.showFiles}>
              <Table
                columns={columns}
                data={{
                  count: show_files.length,
                  list: show_files,
                }}
                onClickRow={handleDeleteFile}
                buttonLabel="削除"
              />
            </div>
            <FormHelperText error={file_error.length !== 0}>
              {file_error}
            </FormHelperText>
          </LabelValue>
          <LabelValue label="伝達事項">
            <Textarea
              id="post_note"
              value={postData.post_note}
              onChange={handlePostNote}
              error={postDataError.post_note}
            />
          </LabelValue>
          <OkCancel
            onCancel={handleOpenCancelModal}
            cancel="受付前に戻す"
            onOk={handleOpenSaveModal}
            ok={
              isReview ? "投稿を完了してレビューに回します" : "投稿を完了します"
            }
          />
        </>
      ) : (
        <LabelValue label="添付ファイル">
          <PostFileList
            data={props.dbFiles}
            users={props.users}
            project_id={props.plan.project_id}
            plan_id={props.plan.id}
          />
        </LabelValue>
      )}

      <Modal
        title="受付前に戻します。"
        open={openCancelModal}
        onClose={handleCloseCancelModal}
        style="small"
      >
        添付ファイル内容は破棄されます。よろしいですか？
        <OkCancel
          onCancel={handleCloseCancelModal}
          onOk={handleCancel}
          ok="受付前に戻す"
        />
      </Modal>
      <Modal
        title={"投稿を完了します。"}
        open={openSaveModal}
        onClose={handleCloseSaveModal}
        style="normal"
      >
        {isReview && (
          <>
            投稿を完了すると、設計部責任者がレビュー出来る状態になります。
            <br />
            レビューが完了した後、営業担当に通知されます。
            <br />
          </>
        )}
        よろしいですか？
        <OkCancel
          onCancel={handleCloseSaveModal}
          onOk={handleSave}
          ok={"投稿を完了する"}
        />
      </Modal>
      <CallAPI params={apiParams} />
    </>
  );
};

InputPostFile.propTypes = {
  plan: PropTypes.object,
  dbFiles: PropTypes.array,
  reviewFiles: PropTypes.array,
  users: PropTypes.array,
  isPostal: PropTypes.bool,
};
InputPostFile.defaultProps = {
  plan: {},
  dbFiles: [],
  reviewFiles: [],
  users: [],
  isPostal: false,
};

export default withRouter(InputPostFile);
