import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Button from "../atoms/Button";
import SearchSalesUser from "./SearchSalesUser";
import Name from "./Name";
import Branch from "./Branch";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    alignItems: "center",
  },
  user: {
    marginLeft: theme.spacing(2),
  },
  branch: {
    marginLeft: theme.spacing(2),
  },
}));

export default function InputSalesUser(props) {
  const classes = useStyles();

  useEffect(() => {
    setUser({
      branch_name: props.branch_name,
      user: props.user,
    });
  }, [props.user, props.branch_name]);

  const [openSearchSalesUser, setOpenSearchSalesUser] = useState(false);

  const [user, setUser] = useState({
    branch_name: "",
    user: {},
  });

  const handleOpenSearchSalesUser = () => {
    setOpenSearchSalesUser(true);
  };
  const handleCloseSearchSalesUser = () => {
    setOpenSearchSalesUser(false);
  };

  const handleSelectedUser = (user, branch_name) => {
    props.onChange({
      supervisor: user.id,
      branch_name: branch_name,
      user: user,
    });
    setUser({
      branch_name: branch_name,
      user: user,
    });
  };

  return (
    <>
      <>
        <div className={classes.flex}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenSearchSalesUser()}
            className={classes.item}
          >
            担当者選択
          </Button>
          <span className={user.branch_name ? classes.branch : null}>
            <Branch name={user.branch_name} />
          </span>
          <span className={classes.user}>
            <Name user={user.user} />
          </span>
        </div>
      </>
      <SearchSalesUser
        open={openSearchSalesUser}
        onClose={handleCloseSearchSalesUser}
        onChange={handleSelectedUser}
        isCheckDeleteUser={props.isCheckDeleteUser}
      />
    </>
  );
}

InputSalesUser.propTypes = {
  onChange: PropTypes.func,
  user: PropTypes.object,
  branch_name: PropTypes.string,
  isCheckDeleteUser: PropTypes.bool
};
InputSalesUser.defaultProps = {
  onChange: null,
  isCheckDeleteUser: false
};
