import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
// import InputContractType from "../molecules/InputContractType";
import {
  requestPlanList,
  requestTypeList,
  yesNoList,
} from "../commons/Constants";
import Message from "../templates/Message";
import Name from "../molecules/Name";
import DesiredDeliveryDate from "../molecules/DesiredDeliveryDate";
import ListValue from "../molecules/ListValue";
import ListValues from "../molecules/ListValues";
import Button from "../atoms/Button";
import { isset } from "../commons/Functions";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    backgroundColor: "#cccccc",
    padding: "3px",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  left_mergin: {
    marginLeft: theme.spacing(2),
  },
  left_mergin2: {
    marginLeft: theme.spacing(4),
  },
}));

export default function RequestInfo(props) {
  const classes = useStyles();

  const handleBefore = () => {
    window.open(
      "/plan/" +
        encodeURI(props.value.project_id) +
        "/" +
        encodeURI(props.value.before_id),
      "_blank"
    );
    return;
  };

  return (
    <>
      <Index value="依頼情報" />
      <LabelValue label="依頼区分">
        <ListValue list={requestTypeList} value={props.value.request_type} />
        {props.value.before_id && (
          <>
            <span className={classes.left_mergin}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleBefore}
              >
                変更修正前の依頼を見る
              </Button>
            </span>
          </>
        )}
      </LabelValue>
      {/* <LabelValue label="依頼ID">
        {props.value.display_id}
        {props.value.before_id && (
          <>
            <span className={classes.left_mergin2}>修正元依頼ID:</span>
            <span className={classes.left_mergin}>
              {props.value.before_display_id}
            </span>
            <span className={classes.left_mergin}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleBefore}
              >
                変更修正前の依頼を見る
              </Button>
            </span>
          </>
        )}
      </LabelValue> */}
      {/* {props.value.request_type != 1 && (
        <LabelValue label="">
          <Message label="修正依頼内容" showType={2}>
            {props.value.fix_note}
          </Message>
        </LabelValue>
      )} */}
      <LabelValue label="営業担当">
        <LabelValue label="名前">
          <Name user={props.user} />
        </LabelValue>
        <LabelValue label="電話番号">{props.user.phone_number}</LabelValue>
        <LabelValue label="メールアドレス">{props.user.email}</LabelValue>
      </LabelValue>
      <LabelValue label="設計">
        <LabelValue label="現在の設計担当">
          <>{props.designer}</>
        </LabelValue>
        {props.initial_designer && (
          <LabelValue label="当初設計担当">
            <>{props.initial_designer}</>
          </LabelValue>
        )}

        <Message
          label="設計担当者からのメッセージ"
          showType={2}
          isIcon={true}
          isDisplay={
            isset(props.designerMessage) &&
            isset(props.designerMessage.message.trim())
          }
        >
          {props.designerMessage ? props.designerMessage.message : null}
        </Message>
        <LabelValue label="設計担当変更可否">
          <ListValue
            list={yesNoList}
            value={props.value.change_designer_flag}
          />
        </LabelValue>
      </LabelValue>
      <LabelValue label="プラン作成件数">{props.value.plan_num}件</LabelValue>
      <LabelValue label="依頼図面">
        <ListValues list={requestPlanList} value={props.value.request_plan} />
      </LabelValue>

      <LabelValue label="納品希望日">
        <DesiredDeliveryDate
          standardDeliveryDateFlag={props.value.standard_delivery_date_flag}
          date={props.value.desired_delivery_date}
          status={props.value.status}
        />
      </LabelValue>
      <LabelValue label="備考" isWrap={true}>
        <>{props.value.note}</>
      </LabelValue>
    </>
  );
}

RequestInfo.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  user: PropTypes.object,
  designer: PropTypes.string,
  designerMessage: PropTypes.object,
  initial_designer: PropTypes.string,
};
RequestInfo.defaultProps = {
  onChange: null,
  value: {},
  user: {},
};
