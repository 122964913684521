import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { roadObstacleList } from "../commons/Constants";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Checkbox from "../atoms/Checkbox";

const useStyles = makeStyles((theme) => ({
  flex: {
    // display: "flex",
    // alignItems: "flex-end",
  },
  inputField: {
    // marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(0),
  },
  right: {
    marginLeft: "auto",
    textAlign: "right",
  },
}));

export default function InputRoadObstacle(props) {
  const classes = useStyles();

  const handleAttachedFile = (e, value) => {
    if (value === null) {
      return;
    }
    props.onChange({
      is_attached_file: value,
      value: value == 1 ? [] : props.value,
    });
  };

  const handleRoadObstacle = (e) => {
    props.onChange({
      is_attached_file: 0,
      value: e,
    });
  };

  const selected = props.isAttachedFile;

  return (
    <div className={classes.flex}>
      <div className={classes.right}>
        <ToggleButtonGroup
          value={props.isAttachedFile}
          exclusive
          onChange={handleAttachedFile}
          aria-label="text alignment"
        >
          <ToggleButton value={0} aria-label="left aligned">
            直接入力
          </ToggleButton>
          <ToggleButton value={1} aria-label="centered">
            入力省略(添付参照)
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.inputField}>
        <Checkbox
          id="road_obstacle"
          checked={props.value}
          onChange={handleRoadObstacle}
          options={roadObstacleList}
          disabled={selected === 1}
          error={props.error}
        />
      </div>
    </div>
  );
}

InputRoadObstacle.propTypes = {
  onChange: PropTypes.func,
  isAttachedFile: PropTypes.number,
  value: PropTypes.array,
  error: PropTypes.string,
};
InputRoadObstacle.defaultProps = {
  onChange: null,
  isAttachedFile: 1,
  value: [],
  error: null,
};
