import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

// import Radio from "../atoms/Radio";
import Input from "../atoms/Input";

const useStyles = makeStyles(() => ({
  flex: {
    // display: "flex",
    // alignItems: "flex-end",
  },
  inputField: {
    width: "200px",
  },
  right: {
    marginLeft: "auto",
    textAlign: "right",
  },
}));

export default function InputSiteArea(props) {
  const classes = useStyles();

  const handleAttachedFile = (e, value) => {
    if (value === null) {
      return;
    }

    props.onChange({
      is_attached_file: value,
      value: value == 1 ? null : e.target.value,
    });
  };

  const handleSiteArea = (e) => {
    props.onChange({
      is_attached_file: 0,
      value: e.target.value,
    });
  };

  const handleOnClick = () => {
    props.onChange({
      is_attached_file: 0,
      value: props.value,
    });
  };

  const selected = props.isAttachedFile;

  return (
    <div className={classes.flex}>
      <div className={classes.right}>
        <ToggleButtonGroup
          value={props.isAttachedFile}
          exclusive
          onChange={handleAttachedFile}
          aria-label="text alignment"
        >
          <ToggleButton value={0} aria-label="left aligned">
            直接入力
          </ToggleButton>
          <ToggleButton value={1} aria-label="centered">
            入力省略(添付参照)
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.inputField}>
        <Input
          id="site_area"
          type="number"
          inputProps={{ step: "0.01" }}
          value={props.value}
          onChange={handleSiteArea}
          onClick={handleOnClick}
          disabled={selected === 1}
          placeholder="xx.xx"
          endText="㎡"
          error={props.error}
        />
      </div>
    </div>
  );
}

InputSiteArea.propTypes = {
  onChange: PropTypes.func,
  isAttachedFile: PropTypes.number,
  value: PropTypes.string,
  error: PropTypes.string,
};
InputSiteArea.defaultProps = {
  onChange: null,
  isAttachedFile: 1,
  value: null,
  error: null,
};
