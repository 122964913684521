class ConfigLoader {
  constructor() {
    // console.log("constructor");
    this.authInfo = this.get(AUTH_INFO_KEY);
  }

  get = (key, default_value = null) => {
    const json = localStorage.getItem(key);
    try {
      if (json === null) {
        return default_value;
      }
      return JSON.parse(json);
    } catch (e) {
      return default_value;
    }
  };

  getSelectOptions = (key) => {
    if (!this.authInfo || !this.authInfo.select_settings) {
      return [];
    }
    const data = this.authInfo.select_settings.filter((v) => v.key == key);
    return data;
  };

  getSystemSetting = (key) => {
    if (!this.authInfo || !this.authInfo.system_settings) {
      return null;
    }
    const data = this.authInfo.system_settings.find((v) => v.key == key);
    return data && data.value;
  };
}

const AUTH_INFO_KEY = "login_key";
const dialogShowTime = 1000;
const rowsPerPage = 10;
const drawerWidth = 240;
// const rowsPerPage = 3;

const ROLE_ADMIN = "a5d9cff52098466dbc9235dc069dae4a";
const ROLE_SALES_USER = "f455e2f8f0ad456882b60e8c3000d524";
const ROLE_SALES_MANAGER = "b3b2ec111ee544e8b222aaef5fee6ec5";
const ROLE_SALES_ADMIN = "df2deac5395d4f7cba442eefda63dfc7";
const ROLE_DESIGNER_USER = "b5a453d84ca94cdd9b491461fa75c183";
const ROLE_DESIGNER_MANAGER = "4f755afffefc49b78fa53da5b93bcc7f";
const ROLE_DESIGNER_ADMIN = "0e1ca8a41b3e40bf84add762af8a5972";
const ROLE_DESIGNER_EXTERNAL = "62375e38cea747f49a69b7e53bb0e664";

const loader = new ConfigLoader(); // const authInfo = User;

const contractStatusList = loader.getSelectOptions("契約ステータス");
const customerTypeList = loader.getSelectOptions("顧客区分");
const contractTypeList = loader.getSelectOptions("契約形態");
const planStatusList = loader.getSelectOptions("プラン作成状況");
const planStatusRequestList = loader.getSelectOptions("プラン作成状況(検索)");
const registTypeList = loader.getSelectOptions("登録区分");
const constructTypeList = loader.getSelectOptions("建物種別");
const requestTypeList = loader.getSelectOptions("依頼種別");
const yesNoList = loader.getSelectOptions("可否");
const requestPlanList = loader.getSelectOptions("依頼図面");
const useDistrictList = loader.getSelectOptions("用途地域");
const heightDistrictList = loader.getSelectOptions("高度地区");
const heightDifferenceList = loader.getSelectOptions("高低差");
const roadObstacleList = loader.getSelectOptions("道路障害物");
const fireProtectionList = loader.getSelectOptions("防火指定");
const floorNumList = loader.getSelectOptions("階数");
const documentTypeList = loader.getSelectOptions("資料分類");
const existenceList = loader.getSelectOptions("有無");
const prefectureList = loader.getSelectOptions("都道府県");
const desiredProductGradeList = loader.getSelectOptions("商品グレード");
const reviewStatusList = loader.getSelectOptions("レビュー状態");

const errorMessageSendEmailTitle = loader.getSystemSetting(
  "error_message_send_email_title"
);
const errorMessageSendEmailBody = loader.getSystemSetting(
  "error_message_send_email_body"
);

const siteProgressList = {
  0: "未契約",
  1: "未契約",
  2: "未契約",
  3: "契約申請中",
  4: "契約済",
  5: "契約済",
  6: "契約済",
  7: "契約済",
  8: "解約",
};

// 上記２項目は、site.sales_typeに(0:建売,1:売建,2:土地売,3:その他(法人),4:注文,5:その他(個人))

const progressList = {
  0: "未対応",
  1: "対応中",
  2: "対応済",
  3: "契約前",
  4: "着工前",
  5: "施工中",
  6: "決済前",
  7: "引渡後",
  8: "取下",
};

const existingCustomerList = [
  { value: 1, name: "既存顧客から選ぶ", sort_order: 1 },
  { value: 2, name: "新規顧客", sort_order: 2 },
];

const desiredDeliveryDateList = [
  { value: 1, name: "標準納期", sort_order: 1 },
  { value: 2, name: "日付指定", sort_order: 2 },
];

const attachedFileList = [
  { value: 1, name: "添付参照(入力省略)", sort_order: 1 },
  { value: 0, name: "添付資料なし", sort_order: 2 },
];

const publicFlagList = {
  0: "非公開",
  1: "公開",
};

const roleList = [
  { value: ROLE_ADMIN, name: "システム管理者", sort_order: 1 },
  { value: ROLE_SALES_USER, name: "営業担当", sort_order: 2 },
  { value: ROLE_SALES_MANAGER, name: "営業管理課", sort_order: 3 },
  { value: ROLE_SALES_ADMIN, name: "営業拠点長", sort_order: 4 },
  { value: ROLE_DESIGNER_USER, name: "設計担当", sort_order: 5 },
  { value: ROLE_DESIGNER_MANAGER, name: "設計Mgr", sort_order: 6 },
  { value: ROLE_DESIGNER_ADMIN, name: "設計部長", sort_order: 7 },
  {
    value: ROLE_DESIGNER_EXTERNAL,
    name: "プラン作成設計事務所",
    sort_order: 8,
  },
];

const planNumMax = 99;
const planNumList = [];
for (let i = 1; i <= planNumMax; i++) {
  planNumList.push({
    value: i,
    name: i,
    sort_order: i,
  });
}

const siteNumMax = 30;
const siteNumList = [];
for (let i = 1; i <= siteNumMax; i++) {
  siteNumList.push({
    value: i,
    name: String(i),
    sort_order: i,
  });
}

const acceptFileTypeList = null;
// "image/*, text/*, .pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx";

const mailWordList = {
  "((#現場名#))": "現場名",
  "((#顧客名#))": "顧客名",
  "((#設計担当名称#))": "設計担当の名称",
  "((#営業担当名称#))": "営業担当の名称",
  "((#営業担当電話番号#))": "営業担当の電話番号",
  "((#営業担当メールアドレス#))": "営業担当のメールアドレス",
  "((#設計担当メールアドレス#))": "設計担当のメールアドレス",
  "((#プラン数#))": "プラン数",
  "((#依頼図面#))": "依頼図面",
  "((#希望納期#))": "希望納期",
  "((#設計担当変更可否#))": "設計担当の変更可否",
  "((#備考#))": "備考",
  "((#修正変更コメント#))": "修正変更コメント",
  "((#差戻しコメント#))": "差戻しコメント",
  "((#レビュー差戻しコメント#))": "レビュー差戻しコメント",
  "((#レビュー状態#))": "レビュー状態",
  "((#レビュー完了コメント#))": "レビュー完了コメント",
  "((#依頼区分#))": "依頼区分",
  "((#プラン依頼詳細画面URL#))": "プラン依頼詳細画面のURL",
  "((#プラン依頼リスト画面URL#))": "プラン依頼リスト画面のURL",
};

const mailTemplateList = [
  { value: "mail_template_request", name: "プラン作成依頼", sort_order: 1 },
  { value: "mail_template_withdraw", name: "プラン作成取下げ", sort_order: 2 },
  { value: "mail_template_remand", name: "プラン作成差戻し", sort_order: 3 },
  { value: "mail_template_start", name: "プラン作成受付", sort_order: 4 },
  { value: "mail_template_response", name: "プラン作成投稿", sort_order: 5 },
  {
    value: "mail_template_fix_request",
    name: "プラン作成修正依頼",
    sort_order: 6,
  },
  {
    value: "mail_template_change_designer",
    name: "プラン作成担当変更",
    sort_order: 7,
  },
  {
    value: "mail_template_review_request",
    name: "レビュー依頼",
    sort_order: 8,
  },
  {
    value: "mail_template_review_remand",
    name: "レビュー差戻し",
    sort_order: 9,
  },
];

const emailDeliveryStatusList = {
  0: "予約",
  1: "配信待機中",
  2: "配信中",
  3: "配信済",
  4: "配信キャンセル",
  5: "予約エラー",
  6: "キャンセルエラー",
  9: "テストメール",
};

// 1: "一時保存",  2: "依頼中",  3: "作成中",  4: "完了",  5: "差戻し",  6: "取下げ",  7: "レビュー"
const PLAN_STATUS = {
  TEMP: 1,
  REQUESTED: 2,
  MAKING: 3,
  COMPLETE: 4,
  REMAND: 5,
  WITHDRAW: 6,
  REVIEW: 7,
};

export {
  AUTH_INFO_KEY,
  dialogShowTime,
  rowsPerPage,
  drawerWidth,
  ROLE_ADMIN,
  ROLE_SALES_USER,
  ROLE_SALES_MANAGER,
  ROLE_SALES_ADMIN,
  ROLE_DESIGNER_USER,
  ROLE_DESIGNER_MANAGER,
  ROLE_DESIGNER_ADMIN,
  ROLE_DESIGNER_EXTERNAL,
  contractStatusList,
  siteProgressList,
  customerTypeList,
  contractTypeList,
  planStatusList,
  planStatusRequestList,
  registTypeList,
  constructTypeList,
  progressList,
  existingCustomerList,
  requestTypeList,
  prefectureList,
  yesNoList,
  planNumList,
  siteNumList,
  requestPlanList,
  desiredDeliveryDateList,
  attachedFileList,
  useDistrictList,
  heightDifferenceList,
  roadObstacleList,
  fireProtectionList,
  heightDistrictList,
  existenceList,
  floorNumList,
  documentTypeList,
  acceptFileTypeList,
  publicFlagList,
  roleList,
  mailWordList,
  mailTemplateList,
  desiredProductGradeList,
  reviewStatusList,
  emailDeliveryStatusList,
  PLAN_STATUS,
  errorMessageSendEmailTitle,
  errorMessageSendEmailBody,
};
