import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import user from "../../commons/User";
import Input from "../../atoms/Input";
import Textarea from "../../atoms/Textarea";
import CallAPI from "../../molecules/CallAPI";
import OkCancel from "../../molecules/OkCancel";
import SelectMailWord from "../../molecules/SelectMailWord";
import Modal from "../../templates/Modal";
import LabelValue from "../../templates/LabelValue";
import Layout from "../../templates/Layout";
import {
  isAdmin,
  validateEmail,
  validateString,
  isValid,
  gotoTopUrl,
  validateExistKey,
} from "../../commons/Functions";
import Select from "../../atoms/Select";
import Radio from "../../atoms/Radio";
import { mailTemplateList, yesNoList } from "../../commons/Constants";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    alignItems: "flex-end",
  },
  inputField: {
    width: "200px",
  },
  right: {
    marginLeft: "auto",
    textAlign: "right",
  },
}));

export default function MailTemplate() {
  const classes = useStyles();

  useEffect(() => {
    const authInfo = user.getAuthInfo();
    console.log(authInfo);
    if (!isAdmin(authInfo)) {
      gotoTopUrl(authInfo);
      return;
    }

    loadData("mail_template_request");
  }, []);
  const [mailTemplate, setMailTemplate] = useState("");
  const [editError, setEditError] = useState({});
  const [mail, setMail] = useState({
    is_send: 0,
    subject: null,
    from_name: null,
    from_email: null,
    bcc_email: null,
    body: null,
    signature: null,
    mail_type: "text",
  });

  const [testMail, setTestMail] = useState({
    isOpen: false,
    email: null,
    error: null,
  });
  const [apiParams, setApiParams] = useState({});

  const handleMailTemplate = (e) => {
    loadData(e.target.value);
  };

  const loadData = (template) => {
    setMailTemplate(template);
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/mail/get_template/`,
      method: "GET",
      params: { mail_template: template },
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setData = (data) => {
    const mail_template = { ...data.mail_template };

    if (!mail_template.mail_type) {
      mail_template.mail_type = "text";
    }
    setMail(mail_template);
    setEditError({});
  };

  const handleIsSend = (e) => {
    console.log(e);
    setMail({ ...mail, is_send: e.target.value });
  };
  const handleSubject = (e) => {
    setMail({ ...mail, subject: e.target.value });
  };
  // const handleSubjectWord = (e) => {
  //   let subject = mail.subject ?? "";
  //   subject += e.target.value;
  //   setMail({ ...mail, subject: subject });
  // };
  const handleFromName = (e) => {
    setMail({ ...mail, from_name: e.target.value });
  };
  const handleFromEmail = (e) => {
    setMail({ ...mail, from_email: e.target.value });
  };
  const handleBccEmail = (e) => {
    setMail({ ...mail, bcc_email: e.target.value });
  };
  const handleBody = (e) => {
    setMail({ ...mail, body: e.target.value });
  };
  const handleBodyWord = (e) => {
    let body = mail.body ?? "";
    body += e.target.value;
    setMail({ ...mail, body: body });
  };
  const handleSignature = (e) => {
    setMail({ ...mail, signature: e.target.value });
  };

  const validate = (is_test = false) => {
    // const body = mail.mail_type == "text" ? mail.body : removeHtml(mail.body);
    // const signature =
    // mail.mail_type == "text" ? mail.signature : removeHtml(mail.signature);

    const editError = {
      is_send: validateExistKey("メール送信可否", mail.is_send, yesNoList),
      subject: validateString("件名", mail.subject, 255, true),
      from_name: validateString("送信者名", mail.from_name, 255, true),
      from_email: validateEmail("送信者メールアドレス", mail.from_email, true),
      bcc_email: validateEmail("Bcc", mail.bcc_email, false),
      body: validateString("本文", mail.body, 10240, true),
      signature: validateString("署名", mail.signature, 10240, true),
    };

    console.log(is_test, isValid(editError));
    setEditError(editError);
    return isValid(editError);
  };

  const handleSendTestMail = () => {
    if (!validate(true)) {
      return;
    }
    setTestMail({ ...testMail, isOpen: true });
  };

  // 保存
  const handleSave = () => {
    if (!validate()) {
      return;
    }
    const req_params = { ...mail, mail_template: mailTemplate };
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/mail/update_template/`,
      method: "PUT",
      params: req_params,
      setData: setTestMailData,
      successMessage: "保存しました。",
      isSubmit: true,
    };
    setApiParams(params);
  };

  const handleCloseTestMail = () => {
    setTestMail({ ...testMail, isOpen: false });
  };

  const handleTestMail = (e) => {
    setTestMail({ ...testMail, email: e.target.value });
  };

  const handleSubmitTestMail = () => {
    const error = {
      email: validateEmail("送信者メールアドレス", testMail.email, true),
    };

    if (!isValid(error)) {
      setTestMail({ ...testMail, error: error.email });
      return;
    }

    setTestMail({ ...testMail, error: null, isOpen: false });

    const req_params = { ...mail, test_email: testMail.email };
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/mail/test_mail/`,
      method: "POST",
      params: req_params,
      setData: setTestMailData,
      successMessage: "テストメールを配信しました",
      successSubMessage:
        "入力したメールアドレスへ送信しています。確認してください。",
      isAutoClose: false,
      isSubmit: true,
    };
    setApiParams(params);
  };
  const setTestMailData = () => {
    // window.open("/manage/email_delivery/", "_self");
  };

  return (
    <Layout
      title="メールテンプレート設定"
      breadcrumb={[
        { url: "/setting/", text: "システム設定" },
        { url: "self", text: "メールテンプレート設定" },
      ]}
    >
      <Select
        id="mail_template"
        value={mailTemplate}
        onChange={handleMailTemplate}
        options={mailTemplateList}
        placeholder="テンプレート種別"
      />

      <LabelValue label="メール送信">
        <Radio
          id="is_send"
          onChange={handleIsSend}
          options={yesNoList}
          value={String(mail.is_send)}
          error={editError.is_send}
        />
      </LabelValue>
      <LabelValue label="件名">
        <Input
          id="subject"
          label="件名"
          className={classes.subject}
          value={mail.subject}
          onChange={handleSubject}
          error={editError.subject}
        />
        {/* <SelectMailWord onClick={handleSubjectWord} /> */}
      </LabelValue>
      <LabelValue label="送信者名">
        <Input
          id="from_name"
          label="送信者名"
          className={classes.from_name}
          value={mail.from_name}
          onChange={handleFromName}
          error={editError.from_name}
        />
      </LabelValue>

      <LabelValue label="送信者メールアドレス">
        <Input
          id="from_email"
          label="送信者メールアドレス"
          className={classes.from_email}
          value={mail.from_email}
          onChange={handleFromEmail}
          error={editError.from_email}
        />
      </LabelValue>

      <LabelValue label="Bcc">
        <Input
          id="bcc_email"
          label="Bcc"
          className={classes.bcc_email}
          value={mail.bcc_email}
          onChange={handleBccEmail}
          error={editError.bcc_email}
        />
      </LabelValue>

      <LabelValue label="本文">
        <div className={classes.flex}>
          <Textarea
            id="body"
            label="本文"
            className={classes.body}
            value={mail.body}
            error={editError.body}
            onChange={handleBody}
            isShowLabel={true}
            rows={10}
          />
          <SelectMailWord onClick={handleBodyWord} isShowLabel />
        </div>
      </LabelValue>

      <LabelValue label="署名">
        <Textarea
          id="signature"
          label="署名"
          className={classes.signature}
          value={mail.signature}
          error={editError.signature}
          onChange={handleSignature}
          isShowLabel={true}
          rows={10}
        />
      </LabelValue>
      <OkCancel
        onCancel={handleSendTestMail}
        onOk={handleSave}
        cancel="テストメール"
        ok={"保存する"}
      />

      <Modal
        title="テストメール送信"
        open={testMail.isOpen}
        onClose={handleCloseTestMail}
        // isShort={true}
      >
        テスト送信するメールアドレスを入力してください。
        <Input
          id="bcc_email"
          label="to_address"
          value={testMail.email}
          onChange={handleTestMail}
          error={testMail.error}
        />
        <OkCancel
          onCancel={handleCloseTestMail}
          onOk={handleSubmitTestMail}
          ok="テストメール送信"
        />
      </Modal>

      <CallAPI params={apiParams} />
    </Layout>
  );
}
