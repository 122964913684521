import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  prefecture: {
    marginRight: theme.spacing(1),
  },
  city: {
    marginRight: theme.spacing(1),
  },
  address_additional: {
    // marginRight: theme.spacing(1),
  },
}));

/**
 * 住所
 */
export default function Address(props) {
  const classes = useStyles();
  // console.log("c", props.value.city);
  return (
    <>
      <span className={classes.prefecture}>{props.value.prefecture}</span>
      <span className={classes.city}>{props.value.city}</span>
      <span className={classes.address_additional}>
        {props.value.address_additional}
      </span>
    </>
  );
}

Address.propTypes = {
  value: PropTypes.object,
};
Address.defaultProps = {
  value: null,
};
