import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";

import Button from "../atoms/Button";
import { drawerWidth } from "../commons/Constants";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  inner: {
    marginTop: theme.spacing(1),
    display: "table-cell",
    verticalAlign: "middle",
    width: "100%",
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  right: {
    marginTop: theme.spacing(1),
    marginLeft: "auto",
    textAlign: "right",
  },
  button: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  normal: {
    display: "table",
    width: "100%",
    height: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
  fixColor: {
    backgroundColor: "rgba(0, 0, 0, 0.06)",
  },
  fix: {
    position: "fixed",
    display: "table",
    width: "100%",
    height: theme.spacing(8),
    bottom: "0",
    zIndex: "1000",
    left: theme.spacing(0),
    right: theme.spacing(0),
  },
  openMenu: {
    left: theme.spacing(0),
    width: drawerWidth,
  },
  closeMenu: {
    width: theme.spacing(7),
  },
}));

/**
 * OK Cancelボタン
 */
export default function OkCancel(props) {
  const classes = useStyles();

  const menu = props.isFix
    ? props.menuOpen
      ? classes.openMenu
      : classes.closeMenu
    : null;
  const style = props.isFix ? classes.fix : classes.normal;
  const position = clsx(
    classes[props.position],
    classes.inner,
    props.isFix ? classes.fixColor : null
  );

  return (
    <>
      {props.isDivider && <Divider className={classes.divider} />}
      <div className={style}>
        <div className={menu}></div>
        <div className={position}>
          {props.onOk && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.onOk}
              className={classes.button}
              disabled={props.disabled}
              startIcon={props.okIcon}
            >
              {props.ok}
            </Button>
          )}
          {props.onOk2 && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.onOk2}
              className={classes.button}
              disabled={props.disabled}
              startIcon={props.okIcon2}
            >
              {props.ok2}
            </Button>
          )}
          {props.onCancel && (
            <Button
              color="primary"
              onClick={props.onCancel}
              type="reset"
              className={classes.button}
              disabled={props.disabled}
            >
              {props.cancel}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

OkCancel.propTypes = {
  onOk: PropTypes.func,
  onOk2: PropTypes.func,
  onCancel: PropTypes.func,
  ok: PropTypes.string,
  ok2: PropTypes.string,
  okIcon: PropTypes.node,
  okIcon2: PropTypes.node,
  cancel: PropTypes.string,
  isDivider: PropTypes.bool,
  position: PropTypes.string,
  disabled: PropTypes.bool,
  isFix: PropTypes.bool,
  menuOpen: PropTypes.bool,
};
OkCancel.defaultProps = {
  ok: "登録",
  okIcon: null,
  cancel: "キャンセル",
  isDivider: false,
  position: "center",
  disabled: false,
  isFix: false,
  menuOpen: true,
};
