import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import user from "../../commons/User";
import CallAPI from "../../molecules/CallAPI";
import Layout from "../../templates/Layout";
import { isAdmin, gotoTopUrl, addPageParams } from "../../commons/Functions";
import Table from "../../atoms/Table";
import { emailDeliveryStatusList, rowsPerPage } from "../../commons/Constants";
import Datetime from "../../molecules/Datetime";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    alignItems: "flex-end",
  },
  inputField: {
    width: "200px",
  },
  right: {
    marginLeft: "auto",
    textAlign: "right",
  },
}));

export default function MailHistory() {
  const classes = useStyles();

  useEffect(() => {
    const authInfo = user.getAuthInfo();
    console.log(authInfo);
    if (!isAdmin(authInfo)) {
      gotoTopUrl(authInfo);
      return;
    }

    loadData();
  }, []);

  const [emailDelivery, setEmailDelivery] = useState({ list: [], has_next: 0 });
  const [page, setPage] = useState({
    offset: 0,
    limit: rowsPerPage,
  });

  const [apiParams, setApiParams] = useState({});

  const loadData = (page) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/mail/history/`,
      method: "GET",
      params: addPageParams({}, page, false),
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setData = (data) => {
    setEmailDelivery(data);
  };

  // 改ページ
  const handleChangePageNo = (e, offset) => {
    const params = {
      ...page,
      offset: offset,
    };
    setPage(params);
    loadData(params);
  };

  const handleClickRow = (id) => {
    window.open(`/setting/mail_history/${id}`, "_self");
  };

  const columns = [
    {
      id: "id",
      label: "ID",
      minWidth: 50,
    },
    {
      id: "status",
      label: "状態",
      minWidth: 50,
      format: (value) => {
        return emailDeliveryStatusList[value];
      },
    },
    {
      id: "delivery_date",
      label: "送信日時",
      minWidth: 50,
      format: (value) => {
        const date = (v) => <Datetime value={v} />;
        return date(value);
      },
    },
    {
      id: "subject",
      label: "件名",
      minWidth: 150,
    },
  ];

  return (
    <Layout
      title="メール送信履歴"
      breadcrumb={[
        { url: "/setting/", text: "システム設定" },
        { url: "self", text: "メール送信履歴" },
      ]}
    >
      <div className={classes.a}></div>
      <Table
        columns={columns}
        data={emailDelivery}
        page={page}
        onChangePageNo={handleChangePageNo}
        onClickRow={handleClickRow}
        buttonLabel={<>詳細</>}
      />

      <CallAPI params={apiParams} />
    </Layout>
  );
}
