import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import LabelValue from "../templates/LabelValue";
import { planStatusRequestList } from "../commons/Constants";
import Checkbox from "../atoms/Checkbox";
import DatePicker from "../atoms/DatePicker";
import Button from "../atoms/Button";
import SelectGroup from "../atoms/SelectGroup";
import { isDesignerAdmin } from "../commons/Functions";
import User from "../commons/User";
import Input from "../atoms/Input";

const useStyles = makeStyles((theme) => ({
  date: {
    display: "flex",
    alignItems: "center",
  },
  tilda: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  grid: {
    height: theme.spacing(6),
  },
}));

export default function PlanSearch(props) {
  const classes = useStyles();

  useEffect(() => {
    // console.log("init", props.searchParams);
    setSearch(props.searchParams);
    setDesigners(User.getDesigners());

    // loadData();
  }, []);

  const [designers, setDesigners] = useState([]);
  // const [apiParams, setApiParams] = useState({});

  const [search, setSearch] = useState({});

  const handleStartDate = (e) => {
    setSearch({ ...search, start_date: e });
  };
  const handleEndDate = (e) => {
    setSearch({ ...search, end_date: e });
  };
  const handlePlanStatus = (e) => {
    setSearch({ ...search, plan_status: e });
  };
  const handleDesigner = (e) => {
    // props.onClose();
    setSearch({ ...search, designer: e.target.value });
  };
  const handleProjectTitle = (e) => {
    setSearch({ ...search, project_title: e.target.value });
  };
  const handleSupervisor = (e) => {
    setSearch({ ...search, supervisor: e.target.value });
  };

  const handleSearch = () => {
    // console.log(search);
    const req = { ...search };

    delete req["is_mine"];
    delete req["change_designer_flag"];
    props.onSearch(req);
  };

  if (!props.authInfo) return <></>;

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          <LabelValue label="対象期間" underline={false}>
            <div className={classes.date}>
              <DatePicker
                id="start_date"
                value={search.start_date}
                onChange={handleStartDate}
              />
              <div className={classes.tilda}>〜</div>
              <DatePicker
                id="end_date"
                value={search.end_date}
                onChange={handleEndDate}
              />
            </div>
          </LabelValue>
          {props.tabNo == 0 && (
            <LabelValue label="対応状況" underline={false}>
              <Checkbox
                id="plan_status"
                checked={search.plan_status}
                onChange={handlePlanStatus}
                options={planStatusRequestList}
              />
            </LabelValue>
          )}
          {isDesignerAdmin(props.authInfo) && (
            // 設計管理者
            <>
              <LabelValue label="設計担当者" underline={false}>
                <SelectGroup
                  id="designer"
                  value={String(search.designer)}
                  onChange={handleDesigner}
                  options={designers}
                  // error={props.error.designer}
                  isBlank={true}
                  blankLabel=""
                />
              </LabelValue>
            </>
          )}
          <LabelValue label="現場名" underline={false}>
            <Input
              id="project_title"
              value={search.project_title}
              onChange={handleProjectTitle}
              placeholder={"例）中野"}
              onKeyEnter={handleSearch}
            />
          </LabelValue>
          <LabelValue label="営業担当" underline={false}>
            <Input
              id="supervisor"
              value={search.supervisor}
              onChange={handleSupervisor}
              placeholder={"例）山田 太郎"}
              onKeyEnter={handleSearch}
            />
          </LabelValue>

          <LabelValue label="" underline={false}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
            >
              検索
            </Button>
          </LabelValue>
        </Grid>
      </Grid>
    </>
  );
}

PlanSearch.propTypes = {
  onSearch: PropTypes.func,
  searchParams: PropTypes.object,
  authInfo: PropTypes.object,
  tabNo: PropTypes.number,
};
PlanSearch.defaultProps = {
  onSearch: null,
  tabNo: 0,
};
