import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Index from "../molecules/Index";
import ContractStatus from "../molecules/ContractStatus";
import LabelValue from "../templates/LabelValue";
// import RegistType from "../molecules/RegistType";
import ContractType from "../molecules/ContractType";
import Name from "../molecules/Name";
import Branch from "../molecules/Branch";
import ListValue from "../molecules/ListValue";
import { registTypeList } from "../commons/Constants";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    alignItems: "center",
  },
  user: {
    // marginLeft: theme.spacing(2),
  },
  branch: {
    marginRight: theme.spacing(2),
  },
  dash: {
    width: "100px",
    backgroundColor: "#eeeeee",
    border: "1px solid #999999",
    textAlign: "center",
    // height: theme.spacing(4),
  },
}));

export default function ContractInfo(props) {
  const classes = useStyles();

  const user = props.users.find((v) => v.id == props.project.supervisor);

  return (
    <>
      <Index value="契約情報" />
      <LabelValue label="登録区分">
        <ListValue list={registTypeList} value={props.project.regist_type} />
      </LabelValue>
      <LabelValue label="契約形態">
        <ContractType
          customerType={props.project.customer_type}
          contractType={props.project.contract_type}
          isLabel={false}
        />
      </LabelValue>
      <LabelValue label="契約ステータス">
        {props.sites.list.length == 0 ? (
          <>&nbsp;</>
        ) : (
          <ContractStatus sites={props.sites.list} />
        )}
      </LabelValue>
      <LabelValue label="顧客名">
        {props.project.customer_name ? (
          <>{props.project.customer_name}</>
        ) : (
          <>&nbsp;</>
        )}
        {props.kizunaCustomer && props.kizunaCustomer.is_invitation_dash && (
          <div className={classes.dash}>Dash加入済み</div>
        )}
      </LabelValue>
      <LabelValue label="営業担当">
        <div className={classes.flex}>
          <span className={props.branch.name ? classes.branch : null}>
            <Branch branch={props.branch} />
          </span>
          <span className={classes.user}>
            {user ? <Name user={user} /> : <>&nbsp;</>}
          </span>
        </div>
      </LabelValue>
    </>
  );
}

ContractInfo.propTypes = {
  project: PropTypes.object,
  sites: PropTypes.object,
  users: PropTypes.array,
  branch: PropTypes.object,
  kizunaCustomer: PropTypes.object,
};
ContractInfo.defaultProps = {
  project: {},
  sites: {},
  users: [],
};
