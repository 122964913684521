import React from "react";
import PropTypes from "prop-types";
import PlanTitle from "../../organisms/PlanTitle";
import RequestInfoHead from "../../organisms/RequestInfoHead";
import PostPlanInfo from "../../organisms/PostPlanInfo";
import RequestInfo from "../../organisms/RequestInfo";
import LocationInfo from "../../organisms/LocationInfo";
import SiteInfo from "../../organisms/SiteInfo";
import DesiredBuildingInfo from "../../organisms/DesiredBuildingInfo";
import RequestFileList from "../../molecules/RequestFileList";
import { makeStyles } from "@material-ui/core/styles";
import { PLAN_STATUS } from "../../commons/Constants";

const useStyles = makeStyles((theme) => ({
  outline: {
    padding: theme.spacing(2),
  },
}));

const DetailPrint = React.forwardRef(function printPlan(props, ref) {
  const classes = useStyles();

  const dbPlanRequest = props.dbPlanRequest;
  const project_id = props.project_id;
  const plan_id = props.plan_id;
  const initial_designer = props.initial_designer;
  const designer = props.designer;
  const users = props.users;
  const user = props.user;
  const dbPostFiles = props.dbPostFiles;
  const dbRequestFiles = props.dbRequestFiles;
  const project = props.project;
  const sites = props.sites;

  return (
    <div ref={ref} className={classes.outline}>
      <PlanTitle plan={dbPlanRequest} isPrint={true} />
      {(dbPlanRequest.status == PLAN_STATUS.REQUESTED ||
        dbPlanRequest.status == PLAN_STATUS.MAKING ||
        dbPlanRequest.status == PLAN_STATUS.COMPLETE ||
        dbPlanRequest.status == PLAN_STATUS.REMAND) && (
        <RequestInfoHead
          plan={dbPlanRequest}
          designer={designer}
          //   designer
          //     ? joinName(designer.last_name, designer.first_name)
          //     : null
          // }
        />
      )}
      {/*
      {dbPlanRequest.status == PLAN_STATUS.MAKING && isDesigner(authInfo) && (
        // 依頼受付中
        <InputPostFile value={dbPlanRequest} />
      )}
      */}

      {dbPlanRequest.status == PLAN_STATUS.COMPLETE && (
        // 完了
        <PostPlanInfo
          plan={dbPlanRequest}
          designer={designer}
          postFiles={dbPostFiles}
          users={users}
        />
      )}
      <RequestInfo
        value={dbPlanRequest}
        designer={designer}
        designerMessage={dbPlanRequest.designer_message}
        initial_designer={initial_designer}
        user={user}
      />
      <LocationInfo showType={2} project={project} sites={sites} />
      <RequestFileList
        project_id={project_id}
        plan_id={plan_id}
        data={dbRequestFiles}
      />
      <SiteInfo value={dbPlanRequest} />
      <DesiredBuildingInfo value={dbPlanRequest} />
    </div>
  );
});

DetailPrint.propTypes = {
  project_id: PropTypes.string,
  plan_id: PropTypes.string,
  dbPlanRequest: PropTypes.object,
  users: PropTypes.array,
  user: PropTypes.object,
  designer: PropTypes.string,
  initial_designer: PropTypes.string,
  project: PropTypes.object,
  sites: PropTypes.object,
  dbPostFiles: PropTypes.array,
  dbRequestFiles: PropTypes.array,
};

// export default function DetailPrint;
export default DetailPrint;
