import React from "react";
import PropTypes from "prop-types";

import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
// import InputContractType from "../molecules/InputContractType";
import Input from "../atoms/Input";
import Select from "../atoms/Select";
import { constructTypeList, prefectureList } from "../commons/Constants";
import Textarea from "../atoms/Textarea";
import InputSiteNames from "../molecules/InputSiteNames";
import Radio from "../atoms/Radio";
import InputSiteNum from "../molecules/InputSiteNum";

export default function InputLocationInfo(props) {
  const handleProjectTitle = (e) => {
    // console.log(e);
    props.onChange({ ...props.value, project_title: e.target.value });
  };

  const handlePrefecture = (e) => {
    // console.log("prefecture", e.target.value);
    props.onChange({ ...props.value, prefecture: e.target.value });
  };

  const handleCity = (e) => {
    // console.log(e);
    props.onChange({ ...props.value, city: e.target.value });
  };

  const handleAddress = (e) => {
    // console.log(e);
    props.onChange({ ...props.value, address_additional: e.target.value });
  };

  const handleConstructType = (e) => {
    // console.log(e);
    props.onChange({ ...props.value, construct_type: e.target.value });
  };

  const handleSiteNum = (site_num, site_names) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      site_num: site_num,
      site_names: site_names,
    });
  };

  const handleSiteNames = (e) => {
    // console.log(e);
    props.onChange({ ...props.value, sites: e });
  };

  const handleNote = (e) => {
    props.onChange({ ...props.value, note: e.target.value });
  };

  return (
    <>
      <Index value="現場概要" />
      <LabelValue label="現場名" required>
        <Input
          id="project_title"
          value={props.value.project_title}
          onChange={handleProjectTitle}
          required
          error={props.error.project_title}
        />
      </LabelValue>

      <LabelValue label="号棟情報" required>
        {props.type == "regist" ? (
          // 登録
          <InputSiteNum
            id="site_names"
            onChange={handleSiteNum}
            site_num={props.value.site_num}
            error={props.error.site_num}
            site_names={props.value.site_names}
            error_site_names={props.error.site_names}
          />
        ) : (
          // 編集
          <InputSiteNames
            id="site_names"
            value={props.value.sites}
            onChange={handleSiteNames}
            error={props.error.sites}
          />
        )}
      </LabelValue>

      <LabelValue label="現場住所\n(代表地番)">
        <Select
          id="prefecture"
          value={props.value.prefecture}
          onChange={handlePrefecture}
          options={prefectureList}
          placeholder="都道府県"
          error={props.error.prefecture}
          isBlank={true}
          blankLabel=""
        />
        <Input
          id="city"
          value={props.value.city}
          onChange={handleCity}
          placeholder="市区(資料では「市区町村」)"
          error={props.error.city}
        />
        <Input
          id="address_additional"
          value={props.value.address_additional}
          onChange={handleAddress}
          placeholder="それ以降"
          error={props.error.address_additional}
        />
      </LabelValue>
      <LabelValue label="建物種別">
        <Radio
          id="construct_type"
          onChange={handleConstructType}
          options={constructTypeList}
          value={String(props.value.construct_type)}
          error={props.error.construct_type}
        />
      </LabelValue>
      <LabelValue label="案件メモ">
        <Textarea
          id="note"
          value={props.value.note}
          onChange={handleNote}
          error={props.error.note}
        />
      </LabelValue>
    </>
  );
}

InputLocationInfo.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
  type: PropTypes.string,
};
InputLocationInfo.defaultProps = {
  onChange: null,
  value: {},
  error: {},
  type: "edit",
};
