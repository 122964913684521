import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import Input from "../atoms/Input";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    alignItems: "flex-end",
  },
  inputField: {
    width: "200px",
  },
  right: {
    marginLeft: "auto",
    textAlign: "right",
  },
}));

export default function InputConstructionVolumeRate(props) {
  const classes = useStyles();

  const handleAttachedFile = (e, value) => {
    if (value === null) {
      return;
    }
    props.onChange({
      is_attached_file: value,
      value:
        value == 1
          ? {
              construction_rate: null,
              volume_rate: null,
            }
          : props.value,
    });
  };

  const handleConstructionRate = (e) => {
    props.onChange({
      is_attached_file: 0,
      value: {
        construction_rate: e.target.value,
        volume_rate: props.value.volume_rate,
      },
    });
  };

  const handleVolumeRate = (e) => {
    props.onChange({
      is_attached_file: 0,
      value: {
        construction_rate: props.value.construction_rate,
        volume_rate: e.target.value,
      },
    });
  };

  const handleOnClick = () => {
    props.onChange({
      is_attached_file: 0,
      value: props.value,
    });
  };

  const selected = props.isAttachedFile;

  return (
    <div>
      <div className={classes.right}>
        <ToggleButtonGroup
          value={props.isAttachedFile}
          exclusive
          onChange={handleAttachedFile}
          aria-label="text alignment"
        >
          <ToggleButton value={0} aria-label="left aligned">
            直接入力
          </ToggleButton>
          <ToggleButton value={1} aria-label="centered">
            入力省略(添付参照)
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div className={classes.flex}>
        <div className={classes.inputField}>
          <Input
            id="construction_rate"
            type="number"
            inputProps={{ step: "1", min: "0", max: "100" }}
            value={props.value.construction_rate}
            onChange={handleConstructionRate}
            onClick={handleOnClick}
            disabled={selected === 1}
            placeholder="建蔽率"
            endText="％"
            error={props.error.construction_rate}
          />
        </div>
        <div>&nbsp;／&nbsp;</div>
        <div className={classes.inputField}>
          <Input
            id="volume_rate"
            type="number"
            inputProps={{ step: "1", min: "0", max: "100" }}
            value={props.value.volume_rate}
            onChange={handleVolumeRate}
            onClick={handleOnClick}
            disabled={selected === 1}
            placeholder="容積率"
            endText="％"
            error={props.error.volume_rate}
          />
        </div>
      </div>
    </div>
  );
}

InputConstructionVolumeRate.propTypes = {
  onChange: PropTypes.func,
  isAttachedFile: PropTypes.number,
  value: PropTypes.object,
  error: PropTypes.object,
};
InputConstructionVolumeRate.defaultProps = {
  onChange: null,
  isAttachedFile: 1,
  value: {},
  error: {},
};
