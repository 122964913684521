import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CallAPI from "../../molecules/CallAPI";
import User from "../../commons/User";
import { fileDownload } from "../../commons/Functions";
// import DocViewer, { PDFRenderer, PNGRenderer } from "react-doc-viewer";

export default function FileView(props) {
  const file_type = props.match.params.file_type;
  const project_id = props.match.params.project_id;
  const plan_id = props.match.params.plan_id;
  const file_id = props.match.params.file_id;

  // console.log(file_type, project_id, plan_id, file_id);

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    if (!authInfo) {
      return;
    }
    loadData();
  }, []);

  const [apiParams, setApiParams] = useState({});
  // const [file, setFile] = useState();

  const loadData = () => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/${file_type}/`,
      method: "POST",
      params: { project_id: project_id, plan_id: plan_id, file_id: file_id },
      setData: setFileDwonload,
      isSubmit: true,
      responseType: "arraybuffer",
    };
    setApiParams(params);
  };

  const getFilename = (disposition) => {
    // console.log("disposition", disposition);

    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // 正規表現でfilenameを抜き出す
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      const fileName = matches[1].replace(/['"]/g, "");
      return decodeURI(fileName); // 日本語対応
    } else {
      return null;
    }
  };

  const setFileDwonload = (data, ret, header) => {
    //  react -> ブラウザ
    const mimeType = header["content-type"];
    const filename = getFilename(header["content-disposition"]);

    console.log(mimeType, filename);

    fileDownload(data, mimeType, filename);
    // setFile([
    //   {
    //     uri: null,
    //     fileType: "png",
    //     fileData: data,
    //     // name: filename, // for download
    //   },
    // ]);
  };

  return (
    <>
      {/* {file && (
        <DocViewer
          pluginRenderers={[PDFRenderer, PNGRenderer]}
          documents={file}
        />
      )} */}
      <CallAPI params={apiParams} />
    </>
  );
}

FileView.propTypes = {
  match: PropTypes.object.isRequired,
};
