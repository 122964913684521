import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Table from "../atoms/Table";
import LabelValue from "../templates/LabelValue";
import Index from "./Index";
import { getFileSize } from "../commons/Functions";
import ListValue from "./ListValue";
import { documentTypeList } from "../commons/Constants";

const useStyles = makeStyles((theme) => ({
  outline: {
    width: "500px",
    height: "100px",
    border: "1px solid #999999",
    borderRadius: "8px",
    display: "table-cell",
    verticalAlign: "middle",
    backgroundColor: "#eeeeee",
    padding: "8px",
  },
  drop: {
    textAlign: "center",
  },
  showFiles: {
    marginBottom: theme.spacing(1),
    width: "640px",
  },
  icon: {
    fontSize: "50px",
  },
}));

export default function RequestFiles(props) {
  const classes = useStyles();

  const db_show_files = props.dbFiles.map((data) => {
    return {
      id: "db_" + data.id,
      filename: data.filename,
      size: getFileSize(data.size),
      document_type: String(data.document_type),
    };
  });

  const input_show_files = props.requestFiles.map((data, key) => {
    // console.log(key);
    return {
      id: "input_" + key,
      filename: data.file.name,
      size: getFileSize(data.file.size),
      document_type: data.document_type,
    };
  });

  const show_files = [...db_show_files, ...input_show_files];
  // console.log(show_files);

  const getDocumentType = (val) => {
    return <ListValue list={documentTypeList} value={parseInt(val)} />;
  };

  const columns = [
    // { id: "no", label: "No", minWidth: 10 },
    { id: "filename", label: "ファイル名", minWidth: 150 },
    {
      id: "document_type",
      label: "資料分類",
      minWidth: 150,
      format: (value) => {
        return getDocumentType(value);
      },
      align: "center",
    },
    { id: "size", label: "サイズ", minWidth: 50 },
  ];

  return (
    <>
      <Index value="添付資料" />
      <LabelValue label="ファイル" required>
        <div className={classes.showFiles}>
          <Table
            columns={columns}
            data={{
              count: show_files.length,
              list: show_files,
            }}
          />
        </div>
      </LabelValue>
    </>
  );
}

RequestFiles.propTypes = {
  dbFiles: PropTypes.array,
  requestFiles: PropTypes.array,
};
RequestFiles.defaultProps = {
  dbFiles: [],
  requestFiles: [],
};
