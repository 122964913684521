import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  planNumList,
  PLAN_STATUS,
  requestPlanList,
  requestTypeList,
  yesNoList,
} from "../commons/Constants";
import { isset } from "../commons/Functions";

import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
// import InputContractType from "../molecules/InputContractType";
import Textarea from "../atoms/Textarea";
import SelectGroup from "../atoms/SelectGroup";
import Radio from "../atoms/Radio";
import Message from "../templates/Message";
import Select from "../atoms/Select";
import Checkbox from "../atoms/Checkbox";
import InputDesiredDeliveryDate from "../molecules/InputDesiredDeliveryDate";
import Name from "../molecules/Name";
import ListValue from "../molecules/ListValue";
import { getDesignerMessage } from "../pages/plan/Validate";
import User from "../commons/User";
import { FormHelperText } from "@material-ui/core";

export default function InputRequestInfo(props) {
  useEffect(() => {
    setDesignerMessage(props.value.designer_message);
    setDesigners(User.getDesigners());
  }, []);

  const [designerMessage, setDesignerMessage] = useState({});
  const [designers, setDesigners] = useState([]);

  const handleFixNote = (e) => {
    props.onChange({ ...props.value, fix_note: e.target.value });
  };

  const handleDesigner = (e) => {
    // console.log(e);
    getDesignerMessage(e.target.value, (designer, designer_message) => {
      props.onChange({
        ...props.value,
        designer: designer,
        designer_message: designer_message,
      });
      setDesignerMessage(designer_message);
    });
  };
  const handleChangeDesignerFlag = (e) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      change_designer_flag: parseInt(e.target.value),
    });
  };

  const handlePlanNum = (e) => {
    // console.log(e);
    props.onChange({ ...props.value, plan_num: e.target.value });
  };

  const handleRequestPlan = (e) => {
    // console.log(e);
    props.onChange({ ...props.value, request_plan: e });
  };

  const handleDesiredDeliveryDate = (e) => {
    // console.log(e);

    props.onChange({
      ...props.value,
      standard_delivery_date_flag: e.standard_delivery_date_flag,
      desired_delivery_date: e.desired_delivery_date,
    });
  };

  const handleNote = (e) => {
    props.onChange({ ...props.value, note: e.target.value });
  };

  return (
    <>
      <Index value="依頼情報" />
      {props.value.status == PLAN_STATUS.REMAND && (
        <LabelValue label="">
          <Message label="差戻しコメント" showType={2}>
            {props.value.post_note}
          </Message>
        </LabelValue>
      )}

      <LabelValue label="依頼区分">
        <ListValue list={requestTypeList} value={props.value.request_type} />
      </LabelValue>
      {props.value.request_type != 1 && (
        <LabelValue label="修正/変更 依頼内容">
          <Textarea
            id="fix_note"
            value={props.value.fix_note}
            onChange={handleFixNote}
            error={props.error.fix_note}
          />
        </LabelValue>
      )}
      <LabelValue label="営業担当">
        <LabelValue label="名前">
          <Name user={props.user} />
        </LabelValue>
        <LabelValue label="電話番号">{props.user.phone_number}</LabelValue>
        <LabelValue label="メールアドレス">{props.user.email}</LabelValue>
      </LabelValue>
      <LabelValue label="設計担当" required>
        <LabelValue label="現在の設計担当">
          <SelectGroup
            id="designer"
            value={String(props.value.designer)}
            onChange={handleDesigner}
            options={designers}
            error={props.error.designer}
            isBlank={true}
            blankLabel=""
          />
        </LabelValue>
        <Message
          label="設計担当者からのメッセージ"
          showType={2}
          isIcon={true}
          isDisplay={
            isset(designerMessage) &&
            isset(designerMessage.message) &&
            isset(designerMessage.message.trim())
          }
        >
          {designerMessage ? designerMessage.message : null}
        </Message>

        <LabelValue label="設計担当変更可否">
          <Radio
            id="change_designer_flag"
            onChange={handleChangeDesignerFlag}
            options={yesNoList}
            value={String(props.value.change_designer_flag)}
            error={props.error.change_designer_flag}
          />
          <FormHelperText error>
            【注意】現在の設計担当が「※指名なし」の場合、「不可」を選ばないでください
          </FormHelperText>
        </LabelValue>
      </LabelValue>
      <LabelValue label="プラン作成件数">
        <Select
          id="plan_num"
          onChange={handlePlanNum}
          options={planNumList}
          value={String(props.value.plan_num)}
          error={props.error.plan_num}
          endText="件"
        />
      </LabelValue>
      <LabelValue label="依頼図面" required>
        <Checkbox
          id="request_plan"
          checked={props.value.request_plan}
          onChange={handleRequestPlan}
          options={requestPlanList}
          error={props.error.request_plan}
        />
      </LabelValue>
      <LabelValue label="納品希望日" required>
        <InputDesiredDeliveryDate
          standardDeliveryDateFlag={props.value.standard_delivery_date_flag}
          desiredDeliveryDate={props.value.desired_delivery_date}
          onChange={handleDesiredDeliveryDate}
          error={props.error.desired_delivery_date}
        />
      </LabelValue>
      <LabelValue label="備考">
        <Textarea
          id="note"
          value={props.value.note}
          onChange={handleNote}
          error={props.error.note}
          label="納品物や納期に関する全般的な伝達事項があればご記入ください"
          inputProps={{ maxLength: 1000 }}
        />
      </LabelValue>
    </>
  );
}

InputRequestInfo.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
  user: PropTypes.object,
};
InputRequestInfo.defaultProps = {
  onChange: null,
  value: {},
  error: {},
  user: {},
};
