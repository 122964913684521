import React from "react";
import PropTypes from "prop-types";

import MuModal from "@material-ui/core/Modal";

import { makeStyles } from "@material-ui/core/styles";

function getModalStyle(style) {
  const top = 50;
  const left = 50;

  const width = {
    small: "320px",
    login: "480px",
    normal: "640px",
    large: "1120px",
  };

  return {
    margin: "auto",
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "absolute",
    overflow: style == "large" ? "scroll" : "none",
    maxHeight: style == "large" ? "80%" : "none",
    width: width[style],
    display: "block",
    outline: "none",
    borderRadius: "6px",
  };
}

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
}));

export default function Modal(props) {
  const classes = useStyles();

  const modalStyle = getModalStyle(props.style);

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div>
      <MuModal open={props.open} onClose={handleClose}>
        <div style={modalStyle} className={classes.content}>
          {props.title && <h3 className={classes.title}>{props.title}</h3>}
          {props.children}
        </div>
      </MuModal>
    </div>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  style: PropTypes.string,
};

Modal.defaultProps = {
  title: "",
  style: "normal",
};
