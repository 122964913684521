import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import User from "../../commons/User";
import Layout from "../../templates/Layout";
import Button from "../../atoms/Button";
import ProjectSearch from "../../organisms/ProjectSearch";
import ProjectList from "../../organisms/ProjectList";
import AddIcon from "@material-ui/icons/Add";
import { gotoTopUrl, isSalesAdmin, isSales } from "../../commons/Functions";
import History from "../../commons/History";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(1),
  },
  right: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    marginLeft: "auto",
    textAlign: "right",
  },
}));

export default function List(props) {
  const classes = useStyles();

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    if (!isSales(authInfo)) {
      gotoTopUrl(authInfo);
      return;
    }

    setPage(getPageState() ?? null);

    const params = getSearchState();

    setSearch(
      params ?? {
        keyword: "",
        supervisor: isSalesAdmin(authInfo) ? null : authInfo.user.id,
        contract_status: ["1", "2"],
        plan_status: [],
        user: isSalesAdmin(authInfo) ? null : authInfo.user,
        branch_name:
          !isSalesAdmin(authInfo) && authInfo.branch
            ? authInfo.branch.name
            : null,
      }
    );
  }, []);

  const [search, setSearch] = useState({});
  const [page, setPage] = useState(null);

  const handleSearch = (params) => {
    const search = { ...params };
    console.log("search", search);
    setPage(null);
    setSearch(search);
  };

  const getSearchState = () => {
    const params = props.location.state ? props.location.state.search : {};
    return params && Object.keys(params).length != 0 ? params : null;
  };
  const getPageState = () => {
    const params = props.location.state ? props.location.state.page : null;
    return params && Object.keys(params).length != 0 ? params : null;
  };

  const handleRegistProject = () => {
    History.push("/project/regist/");
    return;
  };

  return (
    <Layout title="案件一覧" breadcrumb={[{ url: "self", text: "案件一覧" }]}>
      <div className={classes.page}>
        <ProjectSearch
          searchParams={search}
          onSearch={(e) => handleSearch(e)}
        />
        <div className={classes.right}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegistProject}
            startIcon={<AddIcon />}
          >
            新規案件入力
          </Button>
        </div>
        <ProjectList searchParams={search} page={page} />
      </div>
    </Layout>
  );
}

List.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
