import React, { useState, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import clsx from "clsx";

import { FormHelperText } from "@material-ui/core";

import Layout from "../../templates/Layout";
import Modal from "../../templates/Modal";
import User from "../../commons/User";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  body: {
    minHeight: "480px",
  },
  center: {
    textAlign: "center",
  },
  message: {
    marginTop: theme.spacing(4),
    textAlign: "left",
  },
  button: {
    padding: 0,
    border: "none",
    background: "transparent",
  },
  logo: {
    marginRight: theme.spacing(0),
  },
  buttonOut: {
    marginTop: "-16px",
    width: "220px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
  },
}));

export default function Auth2Login() {
  const classes = useStyles();
  const [error, setError] = useState(null);

  const client_id = process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_OAUTH2_CLIENT_ID
  useLayoutEffect(() => {
    User.logout(false)
    window.handleCredentialResponse = handleCredentialResponse;
  }, []);

  const handleCredentialResponse = (params) => {
    console.log("handleCredentialResponse", params);
    dashAuthenticate(params);
  };

  const dashAuthenticate = (params) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/login/`;
    console.log(url);
    axios
      .post(url, params)
      .then((response) => {
        console.log(response);
        window.open("/", "_self");
      })
      .catch((error) => {
        console.log(error);
        setError("認証エラーが発生しました。");
      });
  };

  return (
    <Layout isAuth={false}>
      <Helmet>
        <script
          src="https://accounts.google.com/gsi/client"
          async
          defer
        ></script>
      </Helmet>
      <div className={classes.body}></div>
      <div>
        <Modal open={true} title="Dash案件管理" style="login">
          <div className={classes.center}>
            <div
              id="g_id_onload"
              data-client_id={client_id}
              data-callback="handleCredentialResponse"
              data-auto_select="true"
              data-use_fedcm_for_prompt="true"
            ></div>
            <div className={classes.buttonOut}>
              <div
                className={clsx("g_id_signin", classes.button)}
                data-type="standard"
                data-size="large"
                data-theme="filled_blue"
                data-text="signin"
                data-shape="rectangular"
                data-logo_alignment="left"
                data-width="220"
              >
                {/* Google Sign In */}
              </div>
            </div>
            <br />
            <div className={classes.message}>
              ※承認エラーになる場合は、すべてのGoogleアカウントをログアウトしてから、再度、ログインしてください。
              <br />
            </div>
            <FormHelperText error={error !== null}>{error}</FormHelperText>
          </div>
        </Modal>
      </div>
    </Layout>
  );
}
