import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// import Button from "../../atoms/Button";
import Modal from "../templates/Modal";
import OkCancel from "../molecules/OkCancel";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import Table from "../atoms/Table";
import { rowsPerPage } from "../commons/Constants";
import LabelValue from "../templates/LabelValue";
import CallAPI from "./CallAPI";
import { addPageParams } from "../commons/Functions";
import Name from "./Name";
import User from "../commons/User";
// import Select from "../atoms/Select";
import SelectBranch from "./SelectBranch";
import Checkbox from "../atoms/Checkbox";

export default function SearchSalesUser(props) {
  useEffect(() => {
    const branches = User.getSalesBranches();
    setBranches(branches);

    const branch = User.getBranch();
    const branch_id = branch ? branch.id : "";
    // console.log("branch_id", branch);
    setBranchId(branch_id);
    loadData(page, branch_id);
    // loadBranches();
  }, []);

  const [branches, setBranches] = useState([]);
  // console.log(branches);

  // const [kizunaBranches, setKizunaBranches] = useState([]);
  const [apiParams, setApiParams] = useState({});
  const [branchId, setBranchId] = useState("");
  const [keyword, setKeywoed] = useState("");
  const [includeDeleteUser, setIncludeDeleteUser] = useState(false);
  const [users, setUsers] = useState({ list: [], count: 0 });
  const includeDeleteUserOptions = [{ value: 1, name: "削除済ユーザーも含める", sort_order: 1 }]
  // ページ管理
  const [page, setPage] = useState({
    offset: 0,
    limit: rowsPerPage,
  });

  // // データ取得
  // const loadBranches = () => {
  //   const params = {
  //     url: `${process.env.REACT_APP_API_ENDPOINT}/kizuna/branches/`,
  //     method: "GET",
  //     params: {},
  //     setData: setBranchesData,
  //     isSubmit: true,
  //   };
  //   setApiParams(params);
  // };

  // // 取得データセット
  // const setBranchesData = (data) => {
  //   console.log(data.kizuna_branches);

  //   var branches = [];

  //   for (var v of data.kizuna_branches) {
  //     branches.push({
  //       value: v.id,
  //       name: v.branch_name,
  //     });
  //   }
  //   // const obj = {};
  //   // data.kizuna_branches.forEach((data) => {
  //   //   obj[data.id] = data.branch_name;
  //   // });

  //   setKizunaBranches(branches);
  // };

  const handleBranchId = (e) => {
    // props.onClose();
    // console.log("AA branch_id", e, typeof e);
    setBranchId(e);
  };

  const handleKeyword = (e) => {
    // props.onClose();
    setKeywoed(e.target.value);
  };

  const handleIncludeDeleteUser = (e) => {
    const checked = e.includes(1);
    setIncludeDeleteUser(checked);
  };

  const handleSearch = () => {
    const params = {
      ...page,
      offset: 0,
    };
    setPage(params);
    loadData(params, branchId);
  };

  const loadData = (page, branch_id) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/user/search_sales/`,
      method: "GET",
      params: addPageParams(
        { keyword: keyword, branch_id: branch_id, include_delete_user: includeDeleteUser },
        page,
        false
      ),
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = (data) => {
    // console.log(data);
    setUsers(data);
  };

  // 改ページ
  const handleChangePageNo = (e, offset) => {
    const params = {
      ...page,
      offset: offset,
    };
    setPage(params);
    loadData(params, branchId);
  };

  const handleClickRow = (id) => {
    // console.log(id);
    const user = users.list.find((v) => v.id == id);
    const kizuna_branch = users
      ? branches.find((v) => v.id == user.branch_id)
      : null;

    props.onChange(user, kizuna_branch ? kizuna_branch.name : "");
    props.onClose();
  };

  const handleCancel = () => {
    props.onChange({});
    props.onClose();
  };

  const getBranchName = (value) => {
    const branch = branches.find((v) => v.id == value);
    return <>{branch ? branch.name : ""}</>;
  };
  const getUserName = (value, id, row) => {
    return <Name user={row} />;
  };

  const columns = [
    {
      id: "user_name",
      label: "担当者名",
      minWidth: 150,
      format: (value, id, row) => {
        return getUserName(value, id, row);
      },
    },
    {
      id: "branch_id",
      label: "支店",
      minWidth: 150,
      format: (value, id, row) => {
        return getBranchName(value, id, row);
      },
    },
  ];

  return (
    <Modal
      title="担当者選択"
      open={props.open}
      onClose={props.onClose}
      style="normal"
    >
      <LabelValue label="支店名" underline={false}>
        <SelectBranch
          id="branch_id"
          branchId={branchId}
          onChange={handleBranchId}
          options={branches}
        />
      </LabelValue>

      <LabelValue label="営業担当名" underline={false}>
        <Input
          id="keyword"
          value={keyword}
          onChange={handleKeyword}
          // placeholder={"例)営業担当名"}
          onKeyEnter={handleSearch}
        />
      </LabelValue>
      {props.isCheckDeleteUser && (
        <LabelValue label="">
          <Checkbox
            id="include_delete_user"
            checked={includeDeleteUser ? [1] : []}
            onChange={handleIncludeDeleteUser}
            options={includeDeleteUserOptions}
          />
        </LabelValue>
      )}

      <LabelValue label="" underline={false}>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          検索
        </Button>
      </LabelValue>

      <Table
        columns={columns}
        data={users}
        page={page}
        onChangePageNo={handleChangePageNo}
        onClickRow={handleClickRow}
        buttonLabel={<>選択</>}
      />

      <OkCancel ok="クリア" onOk={handleCancel} onCancel={props.onClose} />
      <CallAPI params={apiParams} />
    </Modal>
  );
}

SearchSalesUser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  isCheckDeleteUser: PropTypes.bool
};
SearchSalesUser.defaultProps = {
  open: false,
  isCheckDeleteUser: false
};
