import React, { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import { makeStyles } from "@material-ui/core/styles";
// import CreateNewFolder from "@material-ui/icons/CreateNewFolder";
// import CreateNewFolderTwoToneIcon from "@material-ui/icons/CreateNewFolderTwoTone";
import CreateNewFolder from "@material-ui/icons/CreateNewFolderOutlined";
import { FormHelperText } from "@material-ui/core";

import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
import Table from "../atoms/Table";
import { getFileSize } from "../commons/Functions";
import Button from "../atoms/Button";
import InputDocumentType from "../molecules/InputDocumentType";
import { acceptFileTypeList } from "../commons/Constants";
import { getFileError } from "../pages/plan/Validate";
// import InputContractType from "../molecules/InputContractType";

const useStyles = makeStyles((theme) => ({
  dropArea: {
    width: "500px",
    height: "150px",
    border: "1px solid #999999",
    borderRadius: "8px",
    display: "table-cell",
    verticalAlign: "middle",
    backgroundColor: "#eeeeee",
    padding: "8px",
    textAlign: "center",
  },
  dropAreaModal: {
    width: "250px",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  showFiles: {
    marginBottom: theme.spacing(1),
    width: "640px",
  },
  icon: {
    fontSize: "50px",
  },
  flex: {
    display: "flex",
  },
}));

export default function InputRequestFile(props) {
  const classes = useStyles();

  useEffect(() => {
    setDbFiles(props.dbFiles);
    setRequestFiles(props.inputFiles);
    // console.log("useEffect", props.dbFiles);
  }, [props.dbFiles]);

  const [requestFiles, setRequestFiles] = useState([]);
  const [dbFiles, setDbFiles] = useState([]);

  const onDropAccepted = useCallback(
    (files) => {
      const newfiles = [...requestFiles];

      for (var file of files) {
        newfiles.push({
          file: file,
          document_type: "0",
        });
      }
      // console.log(newfiles, dbFiles);

      setRequestFiles(newfiles);
      props.onChange(newfiles, dbFiles);
    },
    [requestFiles, dbFiles]
  );

  const onDropRejected = useCallback((files) => {
    console.log("rejected files", files);
    // TODO エラーファイル処理
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFileTypeList,
    onDropAccepted,
    onDropRejected,
  });

  const handleDeleteFile = (id) => {
    // console.log(id);
    const ids = id.split("_");

    if (ids[0] === "input") {
      const newfiles = [...requestFiles];

      newfiles.splice(ids[1], 1);

      setRequestFiles(newfiles);
      props.onChange(newfiles, dbFiles);
    } else if (ids[0] === "db") {
      const newfiles = dbFiles.filter((v) => v.id != ids[1]);

      setDbFiles(newfiles);
      props.onChange(requestFiles, newfiles);
    }
  };

  const handleClick = () => {};

  const handleDocumentType = (e, id) => {
    // console.log("ファイル", e, id);

    const ids = id.split("_");

    if (ids[0] === "input") {
      const newfiles = [...requestFiles];
      newfiles[ids[1]].document_type = e.target.value;

      setRequestFiles(newfiles);
      props.onChange(newfiles, dbFiles);
    } else if (ids[0] === "db") {
      const newfiles = dbFiles.map((data) => {
        if (data.id != ids[1]) {
          return data;
        }
        data.document_type = e.target.value;
        return data;
      });

      setDbFiles(newfiles);
      props.onChange(requestFiles, newfiles);
    }
  };

  var file_error = getFileError(props.error);

  const db_show_files = dbFiles.map((data) => {
    return {
      id: "db_" + data.id,
      filename: data.filename,
      size: getFileSize(data.size),
      document_type: String(data.document_type),
    };
  });

  const input_show_files = requestFiles.map((data, key) => {
    // console.log(key);
    return {
      id: "input_" + key,
      filename: data.file.name,
      size: getFileSize(data.file.size),
      document_type: data.document_type,
    };
  });

  const show_files = [...db_show_files, ...input_show_files];
  // console.log(show_files);

  const getDocumentType = (val, id) => {
    return (
      <InputDocumentType
        value={val}
        onChange={(e) => handleDocumentType(e, id)}
      />
    );
  };

  const columns = [
    // { id: "no", label: "No", minWidth: 10 },
    { id: "filename", label: "ファイル名", minWidth: 150 },
    {
      id: "document_type",
      label: "資料分類",
      minWidth: 150,
      format: (value, id) => {
        return getDocumentType(value, id);
      },
      align: "center",
    },
    { id: "size", label: "サイズ", minWidth: 50 },
  ];

  return (
    <>
      {props.isModal == false && <Index value="添付資料" />}
      <LabelValue label="ファイル" required={!props.isModal} grid={2}>
        <div className={props.isModal ? classes.flex : null}>
          <div
            {...getRootProps()}
            className={clsx(
              classes.dropArea,
              props.isModal ? classes.dropAreaModal : null
            )}
          >
            <div>
              <CreateNewFolder className={classes.icon} />
            </div>
            <div>ここにファイルをドラッグ＆ドロップ</div>
            <div>もしくは、</div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClick()}
            >
              ファイル選択
            </Button>
            <input {...getInputProps()} />
          </div>

          <div className={classes.showFiles}>
            <Table
              columns={columns}
              data={{
                count: props.isModal ? null : show_files.length,
                list: show_files,
              }}
              onClickRow={handleDeleteFile}
              buttonLabel="削除"
            />
          </div>
        </div>
        <FormHelperText error={file_error.length !== 0}>
          {file_error}
        </FormHelperText>
      </LabelValue>
    </>
  );
}

InputRequestFile.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.object,
  dbFiles: PropTypes.array,
  inputFiles: PropTypes.array,
  isModal: PropTypes.bool,
};
InputRequestFile.defaultProps = {
  onChange: null,
  error: {},
  dbFiles: [],
  inputFiles: [],
  isModal: false,
};
