import React from "react";
import PropTypes from "prop-types";
import MuCheckbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  asterisk: {
    color: "#D83420",
  },
}));

/**
 * checkbox
 */
export default function Checkbox(props) {
  const classes = useStyles();

  const handleChange = (value) => {
    let checked = [...props.checked];
    if (checked.includes(value)) {
      checked = checked.filter((v) => v != value);
    } else {
      checked.push(value);
    }

    // console.log("checked", checked);
    if (props.onChange) {
      props.onChange(checked);
    }
  };

  const options = props.options.sort((a, b) => a.sort_order - b.sort_order);

  var select = [];

  for (var v of options) {
    // const vv = { ...v };
    // console.log(v.value, v.name);
    const value = v.value ?? v.id;
    const checked = props.checked.includes(value);

    select.push(
      <FormControlLabel
        key={value}
        control={
          <MuCheckbox
            checked={checked}
            onChange={() => handleChange(value)}
            name={props.id}
            color="primary"
            style={{
              transform: "scale(1.2)",
            }}
          />
        }
        label={v.name}
      />
    );
  }

  return (
    <>
      <div className={classes.inputField}>{select}</div>
      <FormHelperText error={props.error !== null}>
        {props.error}
      </FormHelperText>
    </>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  checked: PropTypes.array,
  error: PropTypes.string,
};
Checkbox.defaultProps = {
  id: "",
  label: null,
  options: [],
  checked: [],
  error: null,
};
