import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { isset, joinName } from "../commons/Functions";

const useStyles = makeStyles((theme) => ({
  unit_name: {
    marginRight: theme.spacing(1),
  },
  position_name: {
    marginRight: theme.spacing(1),
  },
  name: {
    // marginRight: theme.spacing(1),
  },
}));

/**
 * 名前
 */
export default function Name(props) {
  const classes = useStyles();

  let unit_name = null;
  if (props.user && props.isUnit) {
    if (isset(props.user.user_unit) && props.user.user_unit.length > 0) {
      unit_name = props.user.user_unit[0].unit.official_name;
    }
  }

  let position_name = null;
  if (props.user && props.isPosition) {
    if (isset(props.user.user_unit) && props.user.user_unit.length > 0) {
      position_name = props.user.user_unit[0].position.name;
    }
  }

  let name = "";
  if (props.user) {
    if (props.isKana) {
      name = joinName(
        props.user.last_name_kana,
        props.isFirstName ? props.user.first_name_kana : null
      );
    } else {
      name = joinName(
        props.user.last_name,
        props.isFirstName ? props.user.first_name : null
      );
    }
  }

  const honorific = props.isHonorific && name != "" ? " 様" : "";

  return (
    <>
      {props.isUnit && unit_name != null && (
        <span className={classes.unit_name}>{unit_name}</span>
      )}
      {props.isPosition && position_name != null && (
        <span className={classes.position_name}>{position_name}</span>
      )}
      <span className={classes.name}>
        {name}
        {honorific}
      </span>
    </>
  );
}

Name.propTypes = {
  user: PropTypes.object,
  isKana: PropTypes.bool,
  isUnit: PropTypes.bool,
  isPosition: PropTypes.bool,
  isFirstName: PropTypes.bool,
  isHonorific: PropTypes.bool,
};
Name.defaultProps = {
  user: null,
  isKana: false,
  isUnit: false,
  isPosition: false,
  isFirstName: true,
  isHonorific: false,
};
