// import logo from './logo.svg';
import "./App.css";

import { Router, Route, Switch } from "react-router-dom";
import Top from "./pages/utils/Top";
import Profile from "./pages/utils/Profile";
// import DashBoard from "./pages/utils/DashBoard";
import SettingMenu from "./pages/utils/SettingMenu";
import MailTemplate from "./pages/utils/MailTemplate";
import MailHistory from "./pages/utils/MailHistory";
import MailHistoryDetail from "./pages/utils/MailHistoryDetail";
import SwitchAccount from "./pages/utils/SwitchAccount";
import Debug from "./pages/utils/Debug";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import AuthLogin from "./pages/auth/AuthLogin";
import Auth2Login from "./pages/auth/Auth2Login";
import ProjectList from "./pages/project/List";
import ProjectDetail from "./pages/project/Detail";
import ProjectRegist from "./pages/project/Regist";
import PlanList from "./pages/plan/List";
import PlanDetail from "./pages/plan/Detail";
import PlanRegist from "./pages/plan/Regist";
import PlanFileView from "./pages/plan/FileView";
import History from "./commons/History";

function App() {
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
  return (
    <Router history={History}>
      <Switch>
        {/*  */}
        <Route path="/" exact component={Top} />
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/auth/login" exact component={AuthLogin} />
        <Route path="/auth2/login" exact component={Auth2Login} />
        {/* <Route path="/auth/logout" exact component={Logout} /> */}
        <Route path="/profile" exact component={Profile} />
        {/* <Route path="/dashboard" exact component={DashBoard} /> */}
        <Route path="/setting" exact component={SettingMenu} />
        <Route path="/setting/mail_template" exact component={MailTemplate} />
        <Route path="/setting/mail_history" exact component={MailHistory} />
        <Route
          path="/setting/mail_history/:id(\d+)"
          exact
          component={MailHistoryDetail}
        />
        <Route path="/setting/switch_account" exact component={SwitchAccount} />
        <Route path="/setting/debug" exact component={Debug} />
        <Route path="/project" exact component={ProjectList} />
        <Route path="/project/regist" exact component={ProjectRegist} />
        <Route
          path="/project/:project_id(\w+)"
          exact
          component={ProjectDetail}
        />
        <Route path="/plan" exact component={PlanList} />
        <Route
          path="/plan/:project_id(\w+)/:plan_id(\d+)"
          exact
          component={PlanDetail}
        />
        <Route
          path="/plan/regist/:project_id(\w+)/"
          exact
          component={PlanRegist}
        />
        <Route
          path="/plan/:file_type(\w+)/:project_id(\w+)/:plan_id(\d+)/:file_id(\d+)"
          exact
          component={PlanFileView}
        />
      </Switch>
    </Router>
  );
}

export default App;
