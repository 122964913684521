import React from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputField: {
    // width: "100%",
    width: "95%",
    marginTop: theme.spacing(1),
  },
  asterisk: {
    color: "#D83420",
  },
  inputOutline: {
    width: "100%",
  },
}));

/**
 * textarea
 */
export default function Textarea(props) {
  const classes = useStyles();

  const value = props.value ?? "";

  return (
    <div className={classes.inputOutline}>
      <TextField
        id={props.id}
        label={props.label}
        multiline
        value={value}
        error={props.error !== null}
        helperText={props.error === null ? "" : props.error}
        rows={props.rows}
        // maxRows={props.rows}
        variant="outlined"
        onChange={props.onChange}
        onClick={props.onClick}
        className={classes.inputField}
        disabled={props.disabled}
        inputProps={props.inputProps}
      />
    </div>
  );
}

Textarea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  error: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
};
Textarea.defaultProps = {
  label: null,
  value: "",
  error: null,
  rows: 7,
  disabled: false,
  inputProps: {},
};
