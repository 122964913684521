import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
// import CreateIcon from "@material-ui/icons/Create";

import Table from "../atoms/Table";
import LabelValue from "../templates/LabelValue";
import Index from "../molecules/Index";
// import ConstructType from "../molecules/ConstructType";
import Address from "../molecules/Address";
import ListValue from "../molecules/ListValue";
import { constructTypeList, siteProgressList } from "../commons/Constants";
import ContractType from "../molecules/ContractType";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
}));

export default function LocationInfo(props) {
  const classes = useStyles();

  const handleProjectDetail = (id) => {
    const url = `${process.env.REACT_APP_DASH_URL}/sales/project/project-details-content/projectProgress?projectId=${props.project.id}&siteId=${id}`;
    // console.log(url);
    window.open(url, "dash");
  };

  const getProgress = (value) => {
    const progress = siteProgressList[value] ?? "";
    return <>{progress}</>;
  };

  const columns = [
    { id: "display_id", label: "No", minWidth: 10 },
    { id: "site_name", label: "号棟名", minWidth: 110 },
    {
      id: "progress",
      label: "状態",
      minWidth: 150,
      format: (value) => {
        return getProgress(value);
      },
      // align: "center",
    },
  ];

  return (
    <>
      <Index value="現場概要" />
      {props.showType == 2 && (
        <LabelValue label="契約形態">
          <ContractType
            customerType={props.project.customer_type}
            contractType={props.project.contract_type}
            isLabel={false}
          />
        </LabelValue>
      )}
      <LabelValue label="現場名">{props.project.project_title}</LabelValue>

      <LabelValue label="号棟情報">
        <Grid container className={classes.row}>
          <Grid item xs={7}>
            <Table
              columns={columns}
              data={props.sites}
              onClickRow={
                props.showType == 1 && props.project.public_flag == 1
                  ? handleProjectDetail
                  : null
              }
              // buttonLabel={<CreateIcon />}
              // isCircleButton={true}
              unit="棟"
              buttonLabel={<>顧客共有画面</>}
            />
          </Grid>
        </Grid>
      </LabelValue>

      <LabelValue label="現場所在地">
        <Address value={props.project} />
      </LabelValue>

      {props.showType == 1 && (
        <LabelValue label="建物種別">
          <ListValue
            list={constructTypeList}
            value={props.project.construct_type}
          />
        </LabelValue>
      )}

      {/* {props.showType == 1 && (
        <>
          <LabelValue label="プラン作成">必要</LabelValue>

          <Index value="案件メモ" />
          <Grid container className={classes.row}>
            <Grid item xs={12}>
              <LabelValue isWrap={true}>{props.project.note}</LabelValue>
            </Grid>
          </Grid>
        </>
      )} */}

      {props.showType == 2 && (
        <LabelValue label="顧客名">{props.project.customer_name}</LabelValue>
      )}

      {props.showType == 1 && (
        <>
          <Index value="案件メモ" />
          <Grid container className={classes.row}>
            <Grid item xs={12}>
              <LabelValue isWrap={true}>{props.project.note}</LabelValue>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

LocationInfo.propTypes = {
  onSearch: PropTypes.func,
  showType: PropTypes.number,
  project: PropTypes.object,
  sites: PropTypes.object,
};
LocationInfo.defaultProps = {
  onSearch: null,
  showType: 1,
  project: {},
  sites: {},
};
