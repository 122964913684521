import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Element } from "react-scroll";
import queryString from "query-string";
import { makeStyles } from "@material-ui/core/styles";

import Layout from "../../templates/Layout";
import ProjectTitle from "../../organisms/ProjectTitle";
import ProjectTabs from "../../organisms/ProjectTabs";
import TabPanel from "../../templates/TabPanel";
import ContractInfo from "../../organisms/ContractInfo";
import LocationInfo from "../../organisms/LocationInfo";
import OkCancel from "../../molecules/OkCancel";
import PlanRequestList from "../../molecules/PlanRequestList";
import InputContractInfo from "../../organisms/InputContractInfo";
import InputLocationInfo from "../../organisms/InputLocationInfo";
import CallAPI from "../../molecules/CallAPI";
import {
  gotoTopUrl,
  isSales,
  isValid,
  scrollTo,
} from "../../commons/Functions";
import User from "../../commons/User";
import Modal from "../../templates/Modal";
import History from "../../commons/History";
import { validateCustomerInfo, validateLocationInfo } from "./Validate";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(1),
  },
}));

export default function Detail(props) {
  const classes = useStyles();

  const project_id = props.match.params.project_id;

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    if (!isSales(authInfo)) {
      gotoTopUrl(authInfo);
      return;
    }

    loadData(project_id);
    setMenuOpen(User.isMenuOpen());

    const params = queryString.parse(props.location.search);

    setTabNo(params.tab == "plan" ? 1 : 0);
  }, [project_id]);

  const [project, setProject] = useState();
  const [sites, setSites] = useState({
    list: [],
    count: 0,
  });
  const [planRequests, setPlanRequests] = useState({
    list: [],
    count: 0,
  });
  const [publicFlag, setPublicFlag] = useState(true);
  const [bookmark, setBookmark] = useState({});
  const [users, setUsers] = useState([]);
  const [branch, setBranch] = useState({});
  const [postFiles, setPostFiles] = useState([]);
  const [designers, setDesigners] = useState([]);
  const [kizunaCustomer, setKizunaCustomer] = useState({});

  const [tabNo, setTabNo] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const [customerInfo, setCustomerInfo] = useState({});
  const [locationInfo, setLocationInfo] = useState({});
  const [customerInfoError, setCustomerInfoError] = useState(false);
  const [locationInfoError, setLocationInfoError] = useState(false);
  const [apiParams, setApiParams] = useState({});

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // データ取得
  const loadData = (project_id) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/project/${project_id}/`,
      method: "GET",
      params: {},
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = (data) => {
    // console.log(data);

    setProject(data.project);
    setPlanRequests({
      list: data.plan_requests,
      count: data.plan_requests.length,
    });
    setSites({
      list: data.sites,
      count: data.sites.length,
    });
    setBookmark(data.project_bookmark);
    setUsers(data.users);
    setBranch(data.kizuna_branch);
    setPostFiles(data.post_files);

    const user = data.users.find((v) => v.id == data.project.supervisor);
    // console.log("user", user);
    // console.log("kizuna_branch", data.kizuna_branch);

    setCustomerInfo({
      ...data.project,
      existing_customer: data.project.customer_no != 0 ? 1 : 2,
      input_customer_name:
        data.project.customer_no != 0 ? "" : data.project.customer_name,
      customer_name:
        data.project.customer_no != 0 ? data.project.customer_name : "",
      user: user,
      branch: data.kizuna_branch,
    });

    setLocationInfo({ ...data.project, sites: data.sites });
    setPublicFlag(data.project.public_flag == 1);
    setDesigners(data.designers);
    setKizunaCustomer(data.kizuna_customer);
  };

  const handleChangeTabNo = (id) => {
    setTabNo(id);
    // console.log(id);
  };

  // const handleDisable = () => {};

  const handleRequestPlan = () => {
    // window.open("/plan/regist/" + encodeURI(project_id), "_self");
    History.push("/plan/regist/" + encodeURI(project_id));
    return;
  };

  const handleEdit = () => {
    setIsEdit(true);

    scrollTo("top");

    setCustomerInfoError();
    setLocationInfoError();
  };

  const handleRegist = () => {
    // バリデーション
    const customerError = validateCustomerInfo(customerInfo);
    setCustomerInfoError(customerError);

    const locationError = validateLocationInfo(locationInfo, "update");
    setLocationInfoError(locationError);

    if (!isValid(customerError)) {
      scrollTo("InputContractInfo");
      return false;
    }
    if (!isValid(locationError)) {
      scrollTo("InputLocationInfo");
      return false;
    }

    updateProject(customerInfo, locationInfo);
  };

  const handleRegistCancel = () => {
    setIsEdit(false);
  };
  const handleCustomer = (e) => {
    // console.log(e);
    setCustomerInfo({ ...customerInfo, ...e });
  };
  const handleLocation = (e) => {
    // console.log(e);
    setLocationInfo({ ...locationInfo, ...e });
  };

  const updateProject = (customerInfo, locationInfo) => {
    // let sites = [];
    // for (let site of locationInfo.sites) {
    //   sites.push(site);
    //   error_site_names[key] = err;
    // }

    const update_params = {
      customer_type: customerInfo.customer_type,
      contract_type: customerInfo.contract_type,
      customer_no:
        customerInfo.existing_customer == 1 ? customerInfo.customer_no : 0,
      customer_name:
        customerInfo.existing_customer == 1
          ? customerInfo.customer_name
          : customerInfo.input_customer_name,
      supervisor: customerInfo.supervisor,
      project_title: locationInfo.project_title,
      prefecture: locationInfo.prefecture,
      city: locationInfo.city,
      address_additional: locationInfo.address_additional,
      construct_type: locationInfo.construct_type,
      note: locationInfo.note,
      sites: locationInfo.sites,
      timestamp: new Date(),
    };
    // console.log(update_params);

    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/project/${project_id}/`,
      method: "PUT",
      params: update_params,
      setData: setDataUpdate,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setDataUpdate = () => {
    // setIsEdit(false);
    window.open("/project/" + encodeURI(project_id), "_self");
    // History.push("/project/" + encodeURI(project_id));
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/project/${project_id}/`,
      method: "DELETE",
      params: {},
      setData: setDataDelete,
      isSubmit: true,
    };
    setApiParams(params);
  };
  const setDataDelete = () => {
    // setIsEdit(false);
    // window.open("/project/", "_self");
    History.push("/project/");
  };

  const [menuOpen, setMenuOpen] = useState(null);
  const changeDrawer = (v) => {
    setMenuOpen(v);
  };
  return (
    <Layout
      title="案件詳細"
      breadcrumb={[
        { url: "/project/", text: "案件一覧" },
        { url: "self", text: "案件詳細" },
      ]}
      onChangeDrawer={changeDrawer}
    >
      {project && (
        <>
          <ProjectTitle
            project={project}
            sites={sites}
            bookmark={bookmark}
            kizunaCustomer={kizunaCustomer}
            onChangePublicFlag={(v) => setPublicFlag(v)}
          />

          <ProjectTabs onChange={handleChangeTabNo} tabNo={tabNo} />
          <TabPanel value={tabNo} index={0} className={classes.page}>
            {/* 案件情報 */}

            {!isEdit && (
              <>
                <ContractInfo
                  project={project}
                  sites={sites}
                  users={users}
                  branch={branch}
                  kizunaCustomer={kizunaCustomer}
                />
                <LocationInfo
                  showType={1}
                  project={{ ...project, public_flag: publicFlag }}
                  sites={sites}
                />

                <OkCancel
                  ok="案件情報を編集する"
                  onOk={handleEdit}
                  cancel="案件を削除する"
                  onCancel={publicFlag == 0 ? handleOpenDeleteModal : null}
                  isFix={true}
                  menuOpen={menuOpen}
                />
              </>
            )}
            {isEdit && (
              <>
                <Element id="InputContractInfo" />
                <InputContractInfo
                  value={customerInfo}
                  onChange={handleCustomer}
                  error={customerInfoError}
                />
                <Element id="InputLocationInfo" />
                <InputLocationInfo
                  value={locationInfo}
                  onChange={handleLocation}
                  error={locationInfoError}
                  type="edit"
                />
                <OkCancel
                  ok="保存する"
                  onOk={handleRegist}
                  onCancel={handleRegistCancel}
                  isFix={true}
                  menuOpen={menuOpen}
                />
              </>
            )}
          </TabPanel>

          <TabPanel value={tabNo} index={1} className={classes.page}>
            {/* プラン情報 */}
            <PlanRequestList
              plans={planRequests}
              users={users}
              postFiles={postFiles}
              designers={designers}
            />

            <OkCancel
              ok="プランの作成を依頼する"
              onOk={handleRequestPlan}
              isFix={true}
              menuOpen={menuOpen}
            />
          </TabPanel>
          <Modal
            title={
              planRequests.list.length == 0
                ? "案件情報を削除します。"
                : "案件情報を削除できません。"
            }
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            style="small"
          >
            {planRequests.list.length == 0 ? (
              <>
                よろしいですか？
                <OkCancel
                  onCancel={handleCloseDeleteModal}
                  onOk={handleDelete}
                  ok="削除する"
                />
              </>
            ) : (
              <>
                プラン依頼が存在するため削除することは出来ません。
                <br />
                プラン依頼を削除した後に削除してください。
                <OkCancel onCancel={handleCloseDeleteModal} cancel="もどる" />
              </>
            )}
          </Modal>
        </>
      )}

      <CallAPI params={apiParams} />
    </Layout>
  );
}

Detail.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
