import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Element } from "react-scroll";

import Layout from "../../templates/Layout";
import InputRequestInfo from "../../organisms/InputRequestInfo";
import LocationInfo from "../../organisms/LocationInfo";
import InputRequestFile from "../../organisms/InputRequestFile";
import InputSiteInfo from "../../organisms/InputSiteInfo";
import InputDesiredBuildingInfo from "../../organisms/InputDesiredBuildingInfo";
import OkCancel from "../../molecules/OkCancel";
import Modal from "../../templates/Modal";
import User from "../../commons/User";
import CallAPI from "../../molecules/CallAPI";
import RequestInfo from "../../organisms/RequestInfo";

import {
  dateToString,
  findDesigner,
  gotoTopUrl,
  isSales,
  isset,
} from "../../commons/Functions";
import SiteInfo from "../../organisms/SiteInfo";
import DesiredBuildingInfo from "../../organisms/DesiredBuildingInfo";
import RequestFiles from "../../molecules/RequestFiles";
import History from "../../commons/History";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "20px",
    marginTop: theme.spacing(3),
  },
}));

export default function Regist(props) {
  const classes = useStyles();

  const project_id = props.match.params.project_id;
  // console.log("project_id", project_id);

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    if (!isSales(authInfo)) {
      gotoTopUrl(authInfo);
      return;
    }
    setDesigners(User.getDesigners());
    loadData(project_id);
  }, [project_id]);

  const [mode, setMode] = useState("input");
  const [apiParams, setApiParams] = useState({});
  const [project, setProject] = useState({});
  const [sites, setSites] = useState({
    list: [],
    count: 0,
  });
  const [user, setUser] = useState({});
  const [designers, setDesigners] = useState([]);

  // データ取得
  const loadData = (project_id) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/project/${project_id}/?type=plan_regist`,
      method: "GET",
      params: {},
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = (data) => {
    // console.log(data);

    setProject(data.project);
    // setPlanRequests({
    //   list: data.plan_requests,
    //   count: data.plan_requests.length,
    // });
    setSites({
      list: data.sites,
      count: data.sites.length,
    });
    // setBookmark(data.project_bookmark);

    setUser(data.users.find((u) => u.id == data.project.supervisor));
    // console.log(data.users.find((u) => u.id == data.project.supervisor));
    // setBranch(data.kizuna_branch);
    // setDesigners(data.designers);
    // setCustomerInfo({
    //   ...data.project,
    //   existing_customer: data.project.customer_no != 0 ? 1 : 2,
    //   input_customer_name:
    //     data.project.customer_no != 0 ? "" : data.project.customer_name,
    //   customer_name:
    //     data.project.customer_no != 0 ? data.project.customer_name : "",
    // });

    // setLocationInfo({ ...data.project, sites: data.sites });
    setSiteInfo({
      is_address: 0,
      prefecture: isset(data.project.prefecture)
        ? data.project.prefecture
        : "東京都",
      city: data.project.city,
      address_additional: data.project.address_additional,
      is_site_area: 0,
      is_use_district: 0,
      is_height_district: 0,
      is_fire_protection: 0,
      is_construction_volume_rate: 0,
      is_road_width: 0,
      is_road_obstacle: 0,
      use_district: null,
      height_district: null,
      fire_protection: null,
      site_area: null,
      construction_rate: null,
      volume_rate: null,
      height_difference: -1,
      height_difference_note: null,
      road_width_north: null,
      road_width_south: null,
      road_width_east: null,
      road_width_west: null,
      road_obstacle: [],
    });
  };

  const [requestInfo, setRequestInfo] = useState({
    request_type: 1,
    change_designer_flag: 1,
    note: "",
    plan_num: 1,
    request_plan: [],
    status: 1,
    designer: null,
    standard_delivery_date_flag: 1,
    fix_note: null,
  });

  const [requestFiles, setRequestFiles] = useState([]);

  const [siteInfo, setSiteInfo] = useState({});

  const [desiredBuildingInfo, setDesiredBuildingInfo] = useState({
    desired_attached_file_flag: null,
    desired_construct_type: null,
    desired_product_grade: null,
    desired_floor_num: null,
    desired_building_area: null,
    desired_floor_plan: null,
    desired_garage_num: null,
    desired_note: null,
  });

  const handleRequestInfo = (e) => {
    // console.log(e);
    setRequestInfo({ ...requestInfo, ...e });
  };

  const handleRequestFiles = (e) => {
    // console.log(e);
    setRequestFiles(e);
  };

  const handleSiteInfo = (e) => {
    // console.log("handleSiteInfo", e);
    setSiteInfo({ ...siteInfo, ...e });
  };

  const handleDesiredBuildingInfo = (e) => {
    // console.log(e);
    setDesiredBuildingInfo(e);
  };

  const handleConfirm = () => {
    createPlan("confirm");
  };

  const handleTemporarySave = () => {
    createPlan("temp");
  };

  const handleCancel = () => {
    // window.open("/project/" + encodeURI(project.id), "_self");
    History.push("/project/" + encodeURI(project.id));
  };

  // 確認画面
  const handleCancelConfirm = () => {
    setMode("input");
  };

  const handleSave = () => {
    createPlan("save");
  };

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
  };
  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  const createPlan = (type) => {
    const create_params = {
      project_id: project_id,
      request_info: JSON.stringify({
        ...requestInfo,
        status: type == "save" ? 2 : 1,
        // request_plan: requestInfo.request_plan,
        desired_delivery_date: dateToString(requestInfo.desired_delivery_date),
      }),
      site_info: JSON.stringify({
        ...siteInfo,
        // road_obstacle: siteInfo.road_obstacle,
      }),
      desired_building_info: JSON.stringify({ ...desiredBuildingInfo }),
      request_files: requestFiles,
      timestamp: new Date(),
    };
    // console.log(create_params.request_info.request_plan);

    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/`,
      method: "FILE",
      params: create_params,
      setData: setPlanData,
      retValue: type,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setPlanData = (data, type) => {
    if (type == "save") {
      // window.open(
      //   "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id),
      //   "_self"
      // );
      History.push(
        "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id)
      );
      return;
    }
    if (type == "temp") {
      // window.open(
      //   "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id),
      //   "_self"
      // );
      History.push(
        "/plan/" + encodeURI(project_id) + "/" + encodeURI(data.plan_id)
      );
      return;
    }
    if (type == "confirm") {
      window.open(
        "/plan/" +
          encodeURI(project_id) +
          "/" +
          encodeURI(data.plan_id) +
          "/?mode=confirm",
        "_self"
      );
      return;
    }

    // window.open("/project/", "_self");
    History.push("/project/");
  };

  let designer = null;
  if (mode === "confirm") {
    designer = findDesigner(designers, requestInfo.designer);
  }

  return (
    <Layout
      title="プラン作成依頼"
      breadcrumb={[
        { url: "/project/", text: "案件一覧" },
        { url: "/project/" + encodeURI(project_id), text: "案件詳細" },
        { url: "self", text: "プラン作成依頼" },
      ]}
    >
      {mode === "input" && (
        <>
          <Element id="InputRequestInfo" />
          <InputRequestInfo
            value={requestInfo}
            onChange={handleRequestInfo}
            user={user}
          />

          <LocationInfo showType={2} project={project} sites={sites} />

          <Element id="InputRequestFile" />
          <InputRequestFile
            onChange={handleRequestFiles}
            inputFiles={requestFiles}
          />
          <Element id="InputSiteInfo" />
          <InputSiteInfo value={siteInfo} onChange={handleSiteInfo} />
          <Element id="InputDesiredBuildingInfo" />
          <InputDesiredBuildingInfo
            value={desiredBuildingInfo}
            onChange={handleDesiredBuildingInfo}
          />
          <OkCancel
            ok="内容を確認する"
            onOk={handleConfirm}
            ok2="一時保存する"
            onOk2={handleTemporarySave}
            cancel="キャンセル"
            onCancel={handleOpenCancelModal}
          />
        </>
      )}
      {mode === "confirm" && (
        <>
          <div className={classes.label}>依頼内容をご確認ください</div>
          <RequestInfo
            value={requestInfo}
            user={user}
            designer={designer}
            designerMessage={requestInfo.designer_message}
          />
          {/* <LocationInfo showType={2} project={project} sites={sites} /> */}
          <RequestFiles requestFiles={requestFiles} />
          <SiteInfo value={siteInfo} />
          <DesiredBuildingInfo value={desiredBuildingInfo} />
          <OkCancel
            ok="この内容で依頼する"
            onOk={handleSave}
            cancel="キャンセル"
            onCancel={handleCancelConfirm}
          />
        </>
      )}

      <Modal
        open={openCancelModal}
        onClose={handleCloseCancelModal}
        style="small"
      >
        依頼はまだ完了していません。 内容を一時保存しますか？
        <OkCancel
          onCancel={handleCancel}
          onOk={handleTemporarySave}
          ok="一時保存する"
        />
      </Modal>
      <CallAPI params={apiParams} />
    </Layout>
  );
}

Regist.propTypes = {
  match: PropTypes.object.isRequired,
};
