import axios from "axios";
import { AUTH_INFO_KEY } from "./Constants";
import { isset, jsonParse, isSales } from "./Functions";

const LOGIN_LOCATION = "login_location";
const MENU_OPEN = "menu_open";

class User {
  /**
   * constructor
   */
  //   constructor() {}

  /**
   * setter
   */
  setValue = (key, value) => {
    const set_value = isset(value) ? JSON.stringify(value) : null;
    localStorage.setItem(key, set_value);
  };

  /**
   * getter
   */
  getValue = (key, def = null) => {
    const json = localStorage.getItem(key);
    return jsonParse(json, def);
  };

  /**
   * remove
   */
  remove = (key) => {
    localStorage.setItem(key, null);
  };

  isMenuOpen = () => {
    const open = this.getValue(MENU_OPEN, true);
    return open;
  };

  setMenuOpen = (open) => {
    this.setValue(MENU_OPEN, open);
  };

  /**
   * 認証情報取得
   */
  getAuthInfo = () => {
    const authInfo = this.getValue(AUTH_INFO_KEY);

    if (authInfo != null) {
      // 認証情報読み込み済み
      return authInfo;
    }

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ENDPOINT}/auth/is_login/`,
      responseType: "json",
    })
      .then((response) => {
        // 認証情報設定
        this.setValue(AUTH_INFO_KEY, response.data);

        this.redirectFirstUrl(response.data);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response && error.response.status == 400) {
          // ログインしていない
          this.setValue(LOGIN_LOCATION, location.href);
        }
        window.open("/logout/", "_self");
        return;
      });
  };

  /**
   * 初期URL
   */
  redirectFirstUrl = (authInfo) => {
    let default_url = "/plan/";

    if (isSales(authInfo)) {
      default_url = "/project/";
    }

    const location = this.getValue(LOGIN_LOCATION) ?? default_url;

    this.remove(LOGIN_LOCATION);

    window.open(location, "_self");
  };

  /**
   * 認証情報再取得
   */
  resetAuthInfo = (setAuthInfo) => {
    this.setValue(AUTH_INFO_KEY, setAuthInfo);
  };

  findAuthInfo = () => {
    const authInfo = this.getValue(AUTH_INFO_KEY);
    return authInfo;
  };

  /**
   * 認証情報破棄
   */
  logout = async (is_redirect = true) => {
    const authInfo = this.getValue(AUTH_INFO_KEY);
    if (authInfo != null) {
      this.remove(AUTH_INFO_KEY);
    }
    const logout_url = `${process.env.REACT_APP_API_ENDPOINT}/auth/logout/`;
    console.log(logout_url);

    const location = this.getValue(LOGIN_LOCATION);
    let auth = null;
    if (location) {
      const url = new URL(location);

      auth = url.searchParams.get("auth");
    }

    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

    axios({
      method: "GET",
      url: logout_url,
      maxRedirects: 0,
      validateStatus: (status) => {
        return status >= 200 && status <= 302;
      },
    })
      .then((response) => {
        console.log("logout", response);

        if (!is_redirect) {
          return;
        }
        if (auth == "dash") {
          window.open("/auth2/login/", "_self");
        } else {
          window.open("/login/", "_self");
        }
      })
      .catch((error) => {
        console.log("error", error);
        return;
      });
  };

  getSalesBranches() {
    const authInfo = this.getValue(AUTH_INFO_KEY);
    return authInfo.sales_branches;
  }
  getDesignerBranches() {
    const authInfo = this.getValue(AUTH_INFO_KEY);
    return authInfo.designer_branches;
  }
  getBranch() {
    const authInfo = this.getValue(AUTH_INFO_KEY);
    return authInfo.branch;
  }
  getDesigners() {
    const authInfo = this.getValue(AUTH_INFO_KEY);
    return authInfo.designers;
  }
}

export default new User();
