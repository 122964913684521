import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ContractStatus from "../molecules/ContractStatus";
import Button from "../atoms/Button";
import { makeStyles } from "@material-ui/core/styles";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import CallAPI from "../molecules/CallAPI";
import { isset } from "../commons/Functions";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    backgroundColor: "#ffffff",
    padding: "3px",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    minHeight: "40px",
  },
  contract_status: {
    width: "100px",
    fontSize: "15px",
    backgroundColor: "#dddddd",
    border: "1px solid #999999",
    textAlign: "center",
    borderRadius: "16px",
    // height: theme.spacing(4),
  },
  title: {
    fontSize: "15px",
    paddingLeft: "10px",
  },
  right: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    marginLeft: "auto",
    textAlign: "right",
    backgroundColor: "#ffffff",
    padding: theme.spacing(1) / 2,
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  star: {
    width: "30px",
    textAlign: "center",
    backgroundColor: "#ffffff",
  },
  on: {
    color: "#eeee00",
  },
}));

export default function ProjectTitle(props) {
  const classes = useStyles();

  useEffect(() => {
    setIsBookmark(isset(props.bookmark.id));
    setPublicFlag(props.project.public_flag);
  }, [props.project, props.sites, props.bookmark]);

  const [isBookmark, setIsBookmark] = useState(false);
  const [publicFlag, setPublicFlag] = useState(true);
  const [apiParams, setApiParams] = useState({});

  const handleClickPublic = () => {
    if (publicFlag) {
      return;
    }
    changePublicFlag(props.project.id, 1);
  };
  const handleClickDisable = () => {
    if (!publicFlag) {
      return;
    }
    for (let site of props.sites.list) {
      if (site.progress >= 3) {
        return;
      }
    }
    changePublicFlag(props.project.id, 0);
  };

  const handleClickCheckbox = () => {
    changeBookmark(props.project.id);
  };

  const changeBookmark = (project_id) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/project/change_bookmark/`,
      method: "PUT",
      params: { project_id: project_id },
      setData: setDataBookmark,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setDataBookmark = (data) => {
    const is_checked = data.project_bookmarks.find((e) => {
      return e.project == props.project.id;
    });
    setIsBookmark(is_checked !== undefined);
  };

  const changePublicFlag = (project_id, flag) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/project/change_public_flag/`,
      method: "PUT",
      params: { project_id: project_id, flag: flag },
      setData: setDataPublicFalg,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setDataPublicFalg = (data) => {
    setPublicFlag(data.public_flag == 1);
    props.onChangePublicFlag(data.public_flag == 1);
  };

  if (!props.project) {
    return <></>;
  }
  return (
    <div className={classes.flex}>
      {props.project && (
        <>
          <div className={classes.star}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickCheckbox()}
              style="star"
            >
              {isBookmark ? (
                <StarRoundedIcon className={classes.on} />
              ) : (
                <StarBorderRoundedIcon />
              )}
            </Button>
          </div>
          {props.sites.list.length != 0 && (
            <div className={classes.contract_status}>
              <ContractStatus sites={props.sites.list} showType={2} />
            </div>
          )}
          <div className={classes.title}>{props.project.project_title}</div>
          {props.kizunaCustomer && props.kizunaCustomer.is_invitation_dash && (
            <div className={classes.right}>
              <Button
                variant={publicFlag ? "text" : "contained"}
                color={publicFlag ? "primary" : "primary"}
                onClick={handleClickDisable}
                className={classes.button}
              >
                非公開
              </Button>
              <Button
                variant={publicFlag ? "contained" : "text"}
                color={publicFlag ? "primary" : "primary"}
                onClick={handleClickPublic}
                className={classes.button}
              >
                公開
              </Button>
            </div>
          )}
        </>
      )}
      <CallAPI params={apiParams} />
    </div>
  );
}

ProjectTitle.propTypes = {
  project: PropTypes.object,
  sites: PropTypes.object,
  bookmark: PropTypes.object,
  kizunaCustomer: PropTypes.object,
  onChangePublicFlag: PropTypes.func,
};
ProjectTitle.defaultProps = {
  project: null,
  sites: {},
  bookmark: {},
  kizunaCustomer: {},
};
