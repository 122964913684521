import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";

import { fileDownload } from "../commons/Functions";
import Table from "../atoms/Table";
import CallAPI from "./CallAPI";
import Name from "./Name";
import Datetime from "./Datetime";

export default function ReviewFileList(props) {
  const project_id = props.project_id;
  const plan_id = props.plan_id;

  const [apiParams, setApiParams] = useState({});

  const handleClickDwonload = (file_id) => {
    // console.log("file_id", file_id);

    const data = props.data.find((v) => v.id == file_id);

    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/review_file/`,
      method: "POST",
      params: { project_id: project_id, plan_id: plan_id, file_id: file_id },
      setData: setFileDwonload,
      retValue: {
        filename: data.filename,
        content_type: data.content_type,
      },
      isSubmit: true,
      responseType: "arraybuffer",
    };
    setApiParams(params);
  };

  const setFileDwonload = (data, retValue) => {
    // react -> ブラウザ
    fileDownload(data, retValue.content_type, retValue.filename);
  };

  const handleClickAllDwonload = () => {
    // console.log("bulk download");
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/review_file_all/`,
      method: "POST",
      params: { project_id: project_id, plan_id: plan_id },
      setData: setFileDwonload,
      retValue: {
        filename: "review_" + plan_id + ".zip",
        content_type: "application/zip",
      },
      isSubmit: true,
      responseType: "arraybuffer",
    };
    setApiParams(params);
  };

  const handleClickPreview = (file_id) => {
    // console.log("file_id", file_id);
    window.open(
      "/api/plan/review_file_view/?" +
        "project_id=" +
        encodeURI(project_id) +
        "&plan_id=" +
        encodeURI(plan_id) +
        "&file_id=" +
        encodeURI(file_id),
      "_blank"
    );
  };

  const getFileName = (value, id) => {
    // console.log(row);
    return <Link onClick={() => handleClickPreview(id)}>{value}</Link>;
  };

  const getUserName = (id) => {
    const user = props.users.find((v) => v.id == id);
    return <Name user={user} />;
  };

  const columns = [
    // { id: "id", label: "No", minWidth: 100 },
    {
      id: "filename",
      label: "ファイル名",
      minWidth: 150,
      format: (value, id, row) => {
        return getFileName(value, id, row);
      },
    },
    {
      id: "created_at",
      label: "投稿日",
      minWidth: 100,
      format: (value) => {
        const getData = (value) => {
          return <Datetime value={value} />;
        };
        return getData(value);
      },
    },
    {
      id: "created_by",
      label: "投稿者",
      minWidth: 100,
      format: (value) => {
        return getUserName(value);
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={{
          list: props.data,
          count: props.data.length,
        }}
        onClickRow={handleClickDwonload}
        onClickAll={handleClickAllDwonload}
        buttonLabel={<>ダウンロード</>}
        variant="text"
      />
      <CallAPI params={apiParams} />
    </>
  );
}

ReviewFileList.propTypes = {
  data: PropTypes.array,
  project_id: PropTypes.string,
  plan_id: PropTypes.number,
  users: PropTypes.array,
};
ReviewFileList.defaultProps = {
  data: [],
  users: [],
};
