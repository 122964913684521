import React, { useEffect } from "react";
import User from "../../commons/User";
import Layout from "../../templates/Layout";

export default function logout() {
  useEffect(() => {
    User.logout();
  }, []);

  return <Layout isAuth={false}>処理中・・・</Layout>;
}
