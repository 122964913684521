import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { heightDistrictList } from "../commons/Constants";
import Radio from "../atoms/Radio";

const useStyles = makeStyles((theme) => ({
  flex: {
    // display: "flex",
    // alignItems: "flex-end",
  },
  inputField: {
    // marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(0),
  },
  right: {
    marginLeft: "auto",
    textAlign: "right",
  },
}));

export default function InputHeightDistrict(props) {
  const classes = useStyles();

  const handleAttachedFile = (e, value) => {
    if (value === null) {
      return;
    }

    props.onChange({
      is_attached_file: value,
      value: value == 1 ? null : 1,
    });
  };

  const handleHeightDistrict = (e) => {
    props.onChange({
      is_attached_file: 0,
      value: parseInt(e.target.value),
    });
  };

  const selected = props.isAttachedFile;

  return (
    <div className={classes.flex}>
      <div className={classes.right}>
        <ToggleButtonGroup
          value={props.isAttachedFile}
          exclusive
          onChange={handleAttachedFile}
          aria-label="text alignment"
        >
          <ToggleButton value={0} aria-label="left aligned">
            直接入力
          </ToggleButton>
          <ToggleButton value={1} aria-label="centered">
            入力省略(添付参照)
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.inputField}>
        <Radio
          id="height_district"
          onChange={handleHeightDistrict}
          options={heightDistrictList}
          value={String(props.value)}
          disabled={selected === 1}
          error={props.error}
        />
      </div>
    </div>
  );
}

InputHeightDistrict.propTypes = {
  onChange: PropTypes.func,
  isAttachedFile: PropTypes.number,
  value: PropTypes.number,
  error: PropTypes.string,
};
InputHeightDistrict.defaultProps = {
  onChange: null,
  isAttachedFile: 1,
  value: null,
  error: null,
};
