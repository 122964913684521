import React, { useState } from "react";
import PropTypes from "prop-types";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import Table from "../atoms/Table";
import Button from "../atoms/Button";
import Modal from "../templates/Modal";
import Message from "../templates/Message";
import PostFileList from "./PostFileList";
import OkCancel from "./OkCancel";
import History from "../commons/History";
import { findDesigner, getDesignerId } from "../commons/Functions";
import LabelValue from "../templates/LabelValue";
import ListValue from "./ListValue";
import {
  planStatusList,
  PLAN_STATUS,
  requestTypeList,
  reviewStatusList,
} from "../commons/Constants";
import Datetime from "./Datetime";

export default function PlanRequestList(props) {
  const [openPostFile, setOpenPostFile] = useState(false);
  const [postFiles, setPostFiles] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();

  const handleClickDetail = (id) => {
    // console.log(id);
    const plan = props.plans.list.find((c) => c.id == id);
    // window.open(
    //   "/plan/" + encodeURI(plan.project_id) + "/" + encodeURI(id),
    //   "_self"
    // );
    History.push("/plan/" + encodeURI(plan.project_id) + "/" + encodeURI(id));
  };

  const handleOpenPostFile = (id) => {
    // console.log(id);
    const files = props.postFiles.filter((v) => v.plan_request_id == id);
    setPostFiles(files);

    const plan = props.plans.list.find((v) => v.id == id);
    setSelectedPlan(plan);

    setOpenPostFile(true);
  };
  const handleClosePostFile = () => {
    setOpenPostFile(false);
  };

  const getDesigner = (val, row) => {
    const designer_id = getDesignerId(row);
    const designer = findDesigner(props.designers, designer_id, false);
    // const user = props.users.find((v) => v.id == val);
    if (designer) {
      return <>{designer}</>;
    }
  };
  const getFile = (val, id) => {
    const plan = props.plans.list.find((v) => v.id == id);

    return plan != null && plan.status == PLAN_STATUS.COMPLETE ? (
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenPostFile(id)}
        style="file"
      >
        <DescriptionOutlinedIcon />
      </Button>
    ) : (
      <></>
    );
  };

  const columns = [
    {
      id: "status",
      label: "作業状況",
      minWidth: 100,
      format: (value) => {
        const getData = (value) => {
          return <ListValue list={planStatusList} value={value} />;
        };
        return getData(value);
      },
    },
    { id: "display_id", label: "依頼ID", minWidth: 100 },
    {
      id: "request_type",
      label: "種別",
      minWidth: 150,
      format: (value) => {
        const getData = (value) => {
          return <ListValue list={requestTypeList} value={value} />;
        };
        return getData(value);
      },
    },
    {
      id: "designer",
      label: "設計担当",
      minWidth: 150,
      format: (value, id, row) => {
        return getDesigner(value, row);
      },
    },
    {
      id: "desired_delivery_date",
      label: "納品希望日",
      minWidth: 100,
      format: (value) => {
        const getData = (value) => {
          return <Datetime value={value} isTime={false} />;
        };
        return getData(value);
      },
    },
    {
      id: "requested_at",
      label: "依頼日時",
      minWidth: 100,
      format: (value) => {
        const getData = (value) => {
          return <Datetime value={value} />;
        };
        return getData(value);
      },
    },
    {
      id: "reference_at",
      label: "担当者参照日時",
      minWidth: 100,
      format: (value) => {
        const getData = (value) => {
          return <Datetime value={value} />;
        };
        return getData(value);
      },
    },
    {
      id: "file",
      label: "",
      isSort: false,
      minWidth: 10,
      format: (value, id) => {
        return getFile(value, id);
      },
      align: "center",
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={props.plans}
        onClickRow={handleClickDetail}
        buttonLabel={<>詳細</>}
      />
      {selectedPlan && (
        <Modal open={openPostFile} onClose={handleClosePostFile} style="large">
          <Message
            label="設計者担当者からの伝達事項"
            // showType={2}
            isIcon={true}
          >
            {selectedPlan.post_note}
          </Message>
          <LabelValue label="レビュー状態">
            <ListValue
              list={reviewStatusList}
              value={selectedPlan.review_status}
            />
          </LabelValue>

          <PostFileList
            data={postFiles}
            project_id={selectedPlan.project_id}
            plan_id={selectedPlan.id}
          />

          <OkCancel
            onCancel={handleClosePostFile}
            onOk={null}
            cancel="閉じる"
          />
        </Modal>
      )}
    </>
  );
}

PlanRequestList.propTypes = {
  plans: PropTypes.object,
  users: PropTypes.array,
  postFiles: PropTypes.array,
  designers: PropTypes.array,
};
PlanRequestList.defaultProps = {
  plans: {},
  users: [],
  postFiles: [],
  designers: [],
};
