import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MButton from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  base: {
    minWidth: "120px",
    fontWeight: "600",
  },
  primary: {
    // background: "linear-gradient(90deg, #0679BF 0%, #56B4E9 100%);",
    borderRadius: "6px",
  },
  disabled: {
    // background: "linear-gradient(90deg, #cccccc 0%, #aaaaaa 100%);",
  },
  primaryText: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "6px",
    background: "#ffffff",
    // borderRadius: "12px",
    // padding: "1px 8px 0px 8px",
  },
  startIcon: {
    marginRight: theme.spacing(0),
  },
  startIconSmall: {
    margin: theme.spacing(0),
    "& > *:first-child": {
      fontSize: "inherit",
    },
  },
  startIconButton: {
    background: "rgba(255, 255, 255, 0.0)",
    color: "#333333",
    padding: theme.spacing(0),
    minWidth: theme.spacing(0),
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#cccccc",
      boxShadow: "none",
    },
  },
  fileIconButton: {
    background: "#ffffff",
    color: theme.palette.primary.main,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(0),
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#cccccc",
      boxShadow: "none",
    },
  },
}));

/**
 * button
 */
export default function Button(props) {
  const classes = useStyles();

  let button_class = clsx(props.className);
  if (props.color == "primary" && props.variant == "contained") {
    if (!props.disabled) {
      button_class = clsx(props.className, classes.primary, classes.base);
    } else {
      button_class = clsx(props.className, classes.disabled, classes.base);
    }
  } else if (props.color == "primary" && props.variant == "text") {
    if (!props.disabled) {
      button_class = clsx(props.className, classes.primaryText, classes.base);
    } else {
      button_class = clsx(props.className, classes.disabled, classes.base);
    }
  }

  let icon_class = classes.startIcon;
  if (props.style == "sort") {
    icon_class = classes.startIconSmall;
    button_class = classes.startIconButton;
  } else if (props.style == "star") {
    button_class = classes.startIconButton;
  } else if (props.style == "file") {
    button_class = classes.fileIconButton;
  }

  return (
    <MButton
      value={props.value}
      color={props.color}
      variant={props.variant}
      onClick={props.onClick}
      startIcon={props.startIcon}
      component={props.component}
      className={button_class}
      classes={{ startIcon: icon_class }}
      disabled={props.disabled}
    >
      {props.children}
    </MButton>
  );
}

Button.propTypes = {
  value: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  startIcon: PropTypes.node,
  style: PropTypes.string,
  component: PropTypes.elementType,
  className: PropTypes.any,
  disabled: PropTypes.bool,
};
Button.defaultProps = {
  value: null,
  color: "default",
  variant: "text",
  onClick: null,
  children: null,
  startIcon: null,
  style: "normal",
  component: "button",
  className: null,
  disabled: false,
};
