import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import MuSelect from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText, InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputField: {
    // width: "100%",
    marginTop: theme.spacing(1),
  },
  flex: {
    display: "flex",
    // margin: "auto 0px",
    alignItems: "center",
  },
  asterisk: {
    color: "#D83420",
  },
}));

/**
 * select group
 */
export default function SelectGroup(props) {
  const classes = useStyles();

  // if (!props.options || props.options.length == 0) {
  //   console.log("SelectGroup", props.options);
  //   return <></>;
  // }

  const groups = props.options.sort((a, b) => a.sort_order - b.sort_order);
  var select_group = [];

  if (props.isBlank) {
    select_group.push(
      <option value="" key={0}>
        {props.blankLabel}
      </option>
    );
  }

  for (var group of groups) {
    const options = group.options.sort((a, b) => a.sort_order - b.sort_order);

    var select = [];

    let i = 0;
    for (var v of options) {
      select.push(
        <option value={v.value} key={i++}>
          {v.name}
        </option>
      );
    }
    select_group.push(
      <optgroup label={group.name} key={group.value}>
        {select}
      </optgroup>
    );
  }
  // console.log("aaaa", props.value);

  return (
    <div className={classes.flex}>
      <FormControl variant="outlined" className={classes.inputField}>
        {props.placeholder && (
          <>
            <InputLabel>{props.placeholder}</InputLabel>
            <MuSelect
              native
              value={props.value}
              onChange={props.onChange}
              label={props.placeholder}
              error={props.error !== null}
            >
              {select_group}
            </MuSelect>
          </>
        )}
        {!props.placeholder && (
          <>
            <MuSelect
              native
              value={props.value}
              onChange={props.onChange}
              error={props.error !== null}
            >
              {select_group}
            </MuSelect>
          </>
        )}
        <FormHelperText error={props.error !== null}>
          {props.error}
        </FormHelperText>
      </FormControl>
      {props.endText && <div>{props.endText}</div>}
    </div>
  );
}

SelectGroup.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  endText: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  isBlank: PropTypes.bool,
  blankLabel: PropTypes.string,
};
SelectGroup.defaultProps = {
  id: "",
  value: "",
  options: [],
  endText: null,
  placeholder: null,
  error: null,
  isBlank: false,
  blankLabel: "すべて",
};
