import React from "react";
import PropTypes from "prop-types";

export default function ListValues(props) {
  // console.log("ListValues", props.value);
  const values = [];
  for (let v of props.value) {
    const vv = props.list.find((r) => r.value == v);
    if (vv) {
      values.push(<div key={vv.value}>{vv.name}</div>);
    }
  }

  return <>{values.length != 0 ? values : "なし"}</>;
}

ListValues.propTypes = {
  value: PropTypes.array,
  list: PropTypes.array,
};
ListValues.defaultProps = {
  value: [],
  list: [],
};
