import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";

import { fileDownload, getFileSize } from "../commons/Functions";
import { documentTypeList } from "../commons/Constants";
import Table from "../atoms/Table";
import LabelValue from "../templates/LabelValue";
import Index from "./Index";
import ListValue from "./ListValue";
import CallAPI from "./CallAPI";

export default function RequestFileList(props) {
  const project_id = props.project_id;
  const plan_id = props.plan_id;

  const [apiParams, setApiParams] = useState({});

  const handleClickDwonload = (file_id) => {
    const data = props.data.find((v) => v.id == file_id);

    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/request_file/`,
      method: "POST",
      params: { project_id: project_id, plan_id: plan_id, file_id: file_id },
      setData: setFileDwonload,
      retValue: {
        filename: data.filename,
        content_type: data.content_type,
      },
      isSubmit: true,
      responseType: "arraybuffer",
    };
    setApiParams(params);
  };
  const setFileDwonload = (data, retValue) => {
    // react -> ブラウザ
    fileDownload(data, retValue.content_type, retValue.filename);
  };

  const handleClickAllDwonload = () => {
    // console.log("bulk download");
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/request_file_all/`,
      method: "POST",
      params: { project_id: project_id, plan_id: plan_id },
      setData: setFileDwonload,
      retValue: {
        filename: "plan_" + plan_id + ".zip",
        content_type: "application/zip",
      },
      isSubmit: true,
      responseType: "arraybuffer",
    };
    setApiParams(params);
  };

  const handleClickPreview = (file_id) => {
    // console.log("file_id", file_id);
    window.open(
      "/api/plan/request_file_view/?" +
        "project_id=" +
        encodeURI(project_id) +
        "&plan_id=" +
        encodeURI(plan_id) +
        "&file_id=" +
        encodeURI(file_id),
      "_blank"
    );
  };

  const getFileName = (value, id) => {
    // console.log(row);
    return <Link onClick={() => handleClickPreview(id)}>{value}</Link>;
  };

  const getDocumentType = (val) => {
    return <ListValue list={documentTypeList} value={val} />;
  };

  const columns = [
    {
      id: "filename",
      label: "ファイル名",
      minWidth: 150,
      format: (value, id, row) => {
        return getFileName(value, id, row);
      },
    },
    {
      id: "document_type",
      label: "資料分類",
      minWidth: 100,
      format: (value) => {
        return getDocumentType(value);
      },
    },
    {
      id: "size",
      label: "サイズ",
      minWidth: 50,
      align: "right",
      format: (value) => {
        return getFileSize(value);
      },
    },
  ];

  return (
    <>
      <Index value="添付資料" />
      <LabelValue label="">
        <Table
          columns={columns}
          data={{
            list: props.data,
            count: props.data.length,
          }}
          onClickRow={handleClickDwonload}
          onClickAll={handleClickAllDwonload}
          buttonLabel={<>ダウンロード</>}
          variant="text"
        />
      </LabelValue>
      <CallAPI params={apiParams} />
    </>
  );
}

RequestFileList.propTypes = {
  data: PropTypes.array,
  project_id: PropTypes.string,
  plan_id: PropTypes.string,
};
RequestFileList.defaultProps = {
  data: [],
};
