import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "../molecules/Datetime";
import { Box, InputLabel } from "@material-ui/core";
import { planStatusList, requestTypeList } from "../commons/Constants";
import ListValue from "../molecules/ListValue";
import Select from "../atoms/Select";
import User from "../commons/User";
import { isAdmin, isDesigner } from "../commons/Functions";
import CallAPI from "../molecules/CallAPI";
import Modal from "../templates/Modal";
import OkCancel from "../molecules/OkCancel";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    backgroundColor: "#ffffff",
    padding: "3px",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  status: {
    width: "100px",
    fontSize: "15px",
    backgroundColor: "#cccccc",
    border: "1px solid #999999",
    textAlign: "center",
    // height: theme.spacing(4),
  },
  request_type: {
    width: "100px",
    marginLeft: theme.spacing(2),
    fontSize: "15px",
    // backgroundColor: "#cccccc",
    border: "1px solid #999999",
    textAlign: "center",
    borderRadius: "16px",
  },
  right: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    // width: "400px",
    marginLeft: "auto",
    textAlign: "right",
    // backgroundColor: "#ffffff",
    // padding: theme.spacing(1) / 2,
  },
  rightItem: {
    marginTop: theme.spacing(1),
    display: "flex",
    // alignItems: "flex-end",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

export default function PlanTitle(props) {
  const classes = useStyles();

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    // console.log(authInfo);
    setRequestType(props.plan.request_type);

    // 新規以外
    setIsEditable(
      props.isPrint === false &&
      props.plan.request_type != 1 &&
      isDesigner(authInfo) &&
      (isAdmin(authInfo) ||
        props.plan.designer == authInfo.user.id ||
        (props.plan.design_office_id != null &&
          props.plan.design_office_id == authInfo.user.kizuna_trader_id))
    );
  }, [props.plan]);

  const [requestType, setRequestType] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [apiParams, setApiParams] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [requestTypeTmp, setRequestTypeTmp] = useState();

  const handleSelect = (e) => {
    setRequestTypeTmp(e.target.value);
    setOpenModal(true);
  };

  const changeRequestType = () => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/change_request_type/`,
      method: "PUT",
      params: {
        project_id: props.plan.project_id,
        plan_id: props.plan.id,
        request_type: requestTypeTmp,
      },
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setData = (data) => {
    setRequestType(data.request_type);
    setOpenModal(false);
    props.onChangeRequestType(data.request_type);
  };

  const modifyRequestTypeList = requestTypeList.filter((v) => {
    return v.value !== "1";
  });

  const modifyRequestType = requestTypeList.find((v) => {
    return v.value == requestTypeTmp;
  });

  // console.log(modifyRequestType);

  return (
    <div className={classes.flex}>
      {props.plan.status && (
        <>
          <div className={classes.status}>
            <ListValue list={planStatusList} value={props.plan.status} />
          </div>
          {isEditable ? (
            <Select
              id="request_type"
              onChange={handleSelect}
              options={modifyRequestTypeList}
              value={String(requestType)}
            />
          ) : (
            <div className={classes.request_type}>
              <ListValue list={requestTypeList} value={requestType} />
            </div>
          )}
          {props.message && (
            <Box fontSize={20} color={"#4472C4"} mx={5}>
              {props.message}
            </Box>
          )}

          <div className={classes.right}>
            <div className={classes.rightItem}>
              <InputLabel>担当者参照日時: </InputLabel>
              <Datetime value={props.plan.reference_at} />
            </div>
            <div className={classes.rightItem}>
              <InputLabel>依頼日時: </InputLabel>
              <Datetime value={props.plan.requested_at} />
            </div>
          </div>
        </>
      )}
      {modifyRequestType != null && (
        <Modal
          title="依頼種別を変更します。"
          open={openModal}
          onClose={() => setOpenModal(false)}
          style="login"
        >
          依頼種別を変更します。よろしいですか？
          <OkCancel
            onCancel={() => setOpenModal(false)}
            onOk={changeRequestType}
            ok={modifyRequestType.name + "にする"}
          />
        </Modal>
      )}

      <CallAPI params={apiParams} />
    </div>
  );
}

PlanTitle.propTypes = {
  plan: PropTypes.object,
  onChangeRequestType: PropTypes.func,
  isPrint: PropTypes.bool,
  message: PropTypes.string,
};
PlanTitle.defaultProps = {
  plan: null,
  isPrint: false,
};
