import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { NavLink } from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
// import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
// import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import DashboardIcon from "@material-ui/icons/Dashboard";
// import DashboardIcon from "@material-ui/icons/DashboardTwoTone";
// import PersonIcon from "@material-ui/icons/Person";
import PersonIcon from "@material-ui/icons/PersonOutlineTwoTone";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import HomeWorkIcon from "@material-ui/icons/HomeWork";
import HomeWorkIcon from "@material-ui/icons/HomeWorkTwoTone";
import SettingsIcon from "@material-ui/icons/Settings";
import { Helmet } from "react-helmet";
import { Element } from "react-scroll";

import logo from "../assets/images/header_logo.svg";
import User from "../commons/User";
// import Button from "../atoms/Button";
// import AddIcon from "@material-ui/icons/Add";
import DescriptionTwoToneIcon from "@material-ui/icons/DescriptionTwoTone";

import Breadcrumb from "../molecules/Breadcrumb";
import Name from "../molecules/Name";
import { isAdmin, isDesigner, isSales } from "../commons/Functions";
import Branch from "../molecules/Branch";
import { drawerWidth } from "../commons/Constants";

const theme = createTheme({
  typography: {
    fontSize: 13,
    fontWeightLight: 400,
    fontWeightRegular: 600,
    fontWeightMedium: 800,
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#333",
    }, // テーマの色
    background: {
      default: "#f6f6f6",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontWeight: "600",
      },
      body1: {
        fontWeight: "600",
      },
      h6: {
        fontWeight: "600",
      },
    },
    MuiFormLabel: {
      root: {
        lineHeight: "0.5",
        color: "#333333",
        fontSize: "13px",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "11.5px 8px",
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"] $input': {
          padding: "2.5px 0px",
        },
      },
    },
    MuiSelect: {
      root: {
        paddingLeft: "8px",
        // height: "12px",
        // paddingTop: "9px",
        // paddingBottom: "7px",
      },
    },
    MuiTableRow: {
      root: {
        // borderBottom: "dashed 1px #999999",
        borderBottomWidth: "1px",
        borderBottomStyle: "dashed",
        borderBottomColor: "rgb(224, 224, 224)",
      },
      head: {
        borderBottomWidth: "0px",
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: "10px",
        paddingBottom: "8px",
        paddingLeft: "4px",
        paddingRight: "4px",
        borderBottomWidth: "0px",
        fontWeight: "600",
      },
      head: {
        color: "rgba(0, 0, 0, 0.87)",
        borderBottomWidth: "2px",
        fontWeight: "800",
      },
      body: {
        // borderBottom: "dotted 3px orange",
        // paddingTop: "6px",
        // paddingBottom: "4px",
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#111",
        top: "0px",
      },
      root: {
        minHeight: "32px",
      },
    },
    MuiTab: {
      root: {
        minHeight: "32px",
        "&$selected": {
          backgroundColor: "#fff",
          "&:hover": {
            backgroundColor: "#ccc",
            color: "#eee",
          },
        },
        "&:hover": {
          backgroundColor: "#ccc",
          color: "#eee",
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: "28px",
        padding: "6px 8px",
        boxShadow: "none",
      },
      contained: {
        boxShadow: "none",
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: "800",
        color: "#777",
      },
      outlined: {
        fontWeight: "300",
      },
    },
    MuiToggleButton: {
      root: {
        padding: "4px",
        "&$selected": {
          backgroundColor: "#333",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#111",
            // color: "#ffffff",
          },
        },
        "&:hover": {
          backgroundColor: "#111",
          color: "#ffffff",
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "40px",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: "600",
      },
    },
    MuiGrid: {
      root: {
        fontWeight: "600",
      },
      container: {
        minHeight: "16px",
      },
    },
    MuiLink: {
      root: {
        color: colors.blue[800],
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#cccccc",
      },
    },
    // MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated
    // border-radius
    // Mui: {
    //   selected: {
    //     backgroundColor: "#99cc99",
    //     color: "#99cc99",
    //     "&:hover": {
    //       backgroundColor: "#99cc99",
    //       color: "#99cc99",
    //     },
    //   },
    // },
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24,
      display: "flex",
      background: "#FFF",
    },
    menuDev: {
      background: "rgba(100, 166, 221, 0.2)",
    },
    menuStg: {
      background: "rgba(221, 100, 166, 0.2)",
    },
    menuProd: {
      background: "#FFF",
    },
    toolbarIcon: {
      background: "#FFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      height: "48px",

      // ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
      color: "rgba(0, 0, 0, 0.54)",
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
      color: "#333333",
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(5),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    },
    appBarSpacer: {
      minHeight: "48px",
    },
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
      // maxWidth: "1920px",
      minWidth: "920px",
    },
    container: {
      maxWidth: "100%",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "#111",
        // backgroundColor: colors.blue[900],
        color: "#ffffff",
      },
    },
    activeLink: {
      backgroundColor: "#333",
      // backgroundColor: colors.blue[800],
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#111",
        // backgroundColor: colors.blue[900],
      },
    },

    // active: {
    //   textDecoration: "none",
    //   color: theme.palette.text.secondary,
    //   backgroundColor: colors.blue[800],
    //   "&:hover": {
    //     // opacity: "0.01",
    //     // backgroundColor: "#ccc",
    //     backgroundColor: colors.blue[900],
    //   },
    // },
    listItem: {
      backgroundColor: "inherit",
      color: "inherit",
    },
    systemName: {
      fontWeight: "800",
      fontSize: "20px",
    },
    logo: {
      position: "static",
      height: "36px",
      marginRight: "auto",
    },
    env: {
      padding: theme.spacing(0),
      width: "80px",
      fontSize: "12px",
      color: "#333333",
    },
    button: {
      backgroundColor: "#1565c0",
      borderRadius: "4px",
      minWidth: "28px",
      padding: "4px 8px",
      color: "#ffffff",
    },
    copyright: {
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(1),
      borderTop: "solid 1px #cccccc",
    },
  })
);

const Copyright = () => {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="right"
      className={classes.copyright}
    >
      &copy;{"" + new Date().getFullYear() + " Open House Architect Co.,Ltd."}
    </Typography>
  );
};

export default function Layout(props) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(null);
  const [authInfo, setAuthInfo] = useState(null);

  const handleDrawerOpen = () => {
    setMenuOpen(true);
    User.setMenuOpen(true);
    if (props.onChangeDrawer) {
      props.onChangeDrawer(true);
    }
  };
  const handleDrawerClose = () => {
    setMenuOpen(false);
    User.setMenuOpen(false);
    if (props.onChangeDrawer) {
      props.onChangeDrawer(false);
    }
  };

  useEffect(() => {
    if (props.isError) {
      return;
    }

    if (props.isAuth) {
      const authInfo = User.getAuthInfo();
      if (authInfo == null) {
        return;
      }
      setAuthInfo(authInfo);
    }

    setMenuOpen(User.isMenuOpen());

    const { pathname } = location;

    if (!pathname.startsWith("/manage/profile/")) {
      // if (isOnlyHachiouji(authInfo.roles)){
      //   if (!pathname.startsWith("/")){
      //     setDialog(true);
      //     window.open("/", "_self");
      //     return;
      //   }
      // }
      // if (isOnlyBossUser(authInfo.roles)){
      //   if (pathname.startsWith("/plan/")){
      //     setDialog(true);
      //     window.open("/plan/", "_self");
      //     return;
      //   }
      // }
    }

    // setIsTaskNotice(pathname.startsWith("/manage/task_notice/"));
  }, []);

  const checkActive = (location, check_location) => {
    if (!location) return false;

    return location.pathname.startsWith(check_location);
  };

  const checkActiveProfile = (match, location) => {
    return checkActive(location, "/profile/");
  };
  // const checkActiveDashboard = (match, location) => {
  //   return checkActive(location, "/dashboard/");
  // };
  const checkActiveProject = (match, location) => {
    // if (checkActive(location, "/project/regist/")) {
    //   return false;
    // }
    return checkActive(location, "/project/");
  };
  // const checkActiveProjectRegist = (match, location) => {
  //   return checkActive(location, "/project/regist/");
  // };
  const checkActivePlan = (match, location) => {
    return checkActive(location, "/plan/");
  };
  const checkActiveSettings = (match, location) => {
    return checkActive(location, "/setting/");
  };

  const env = `${process.env.REACT_APP_ENV}`;

  let menu_class = classes.menuProd;
  if (env === "stg") {
    menu_class = classes.menuStg;
  } else if (env === "dev") {
    menu_class = classes.menuDev;
  }

  let title_env = "";
  if (env != "prod") {
    title_env = "(" + env + ")";
  }
  const title = "Dash" + title_env + (props.title ? " - " + props.title : "");

  if (menuOpen === null) {
    return <></>;
  }
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, menuOpen && classes.appBarShift)}
          elevation={0}
        >
          <Toolbar className={classes.toolbar} variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                menuOpen && classes.menuButtonHidden
              )}
            >
              <ChevronRightIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {props.breadcrumb && <Breadcrumb value={props.breadcrumb} />}
              {!props.breadcrumb && props.title && <>{props.title}</>}
            </Typography>
          </Toolbar>
          <Divider />
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(
              classes.drawerPaper,
              menu_class,
              !menuOpen && classes.drawerPaperClose
            ),
          }}
          open={menuOpen}
        >
          <div className={classes.toolbarIcon}>
            <img src={logo} className={classes.logo} alt="logo" />
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem className={classes.systemName}>
              Dash案件管理
              {env != "prod" && <div className={classes.env}>/{env}</div>}
            </ListItem>

            {props.isAuth && (
              <>
                <NavLink
                  exact
                  to="/profile/"
                  className={classes.link}
                  activeClassName={classes.activeLink}
                  isActive={checkActiveProfile}
                >
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItem}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Name user={authInfo.user} />} />
                  </ListItem>
                </NavLink>

                {authInfo.branch && (
                  <ListItem>
                    <ListItemIcon className={classes.listItem} />
                    <Branch branch={authInfo.branch} isRaw={true} />
                  </ListItem>
                )}

                <Divider />

                {/* <NavLink
                  exact
                  to="/dashboard/"
                  className={classes.link}
                  activeClassName={classes.activeLink}
                  isActive={checkActiveDashboard}
                >
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItem}>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="DashBoard" />
                  </ListItem>
                </NavLink> */}

                {isSales(authInfo) && (
                  <>
                    <NavLink
                      exact
                      to="/project/"
                      className={classes.link}
                      activeClassName={classes.activeLink}
                      isActive={checkActiveProject}
                    >
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItem}>
                          <HomeWorkIcon />
                        </ListItemIcon>
                        <ListItemText primary="案件一覧" />
                      </ListItem>
                    </NavLink>
                    {/* <NavLink
                      exact
                      to="/project/regist/"
                      className={classes.link}
                      activeClassName={classes.activeLink}
                      isActive={checkActiveProjectRegist}
                    >
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItem}>
                          <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary="新規案件入力" />
                      </ListItem>
                    </NavLink> */}
                  </>
                )}

                {isDesigner(authInfo) && (
                  <NavLink
                    exact
                    to="/plan/"
                    className={classes.link}
                    activeClassName={classes.activeLink}
                    isActive={checkActivePlan}
                  >
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItem}>
                        <DescriptionTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="プラン依頼一覧" />
                    </ListItem>
                  </NavLink>
                )}

                {(isAdmin(authInfo) || env != "prod") && (
                  <NavLink
                    exact
                    to="/setting/"
                    className={classes.link}
                    activeClassName={classes.activeLink}
                    isActive={checkActiveSettings}
                  >
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItem}>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="システム設定" />
                    </ListItem>
                  </NavLink>
                )}
                <Divider />
                <NavLink
                  exact
                  to="/logout/"
                  className={classes.link}
                  activeClassName={classes.activeLink}
                >
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItem}>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="ログアウト" />
                  </ListItem>
                </NavLink>
              </>
            )}
          </List>
        </Drawer>
        <main className={classes.content} id="content">
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              noWrap
              className={classes.pageTitle}
            />
            <Element id="top" />

            {props.children}
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isError: PropTypes.bool,
  breadcrumb: PropTypes.array,
  isAuth: PropTypes.bool,
  onChangeDrawer: PropTypes.func
};

Layout.defaultProps = {
  title: "",
  subTitle: null,
  isError: false,
  breadcrumb: null,
  isAuth: true,
};
