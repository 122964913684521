import {
  constructTypeList,
  contractTypeList,
  customerTypeList,
  prefectureList,
  siteNumList,
} from "../../commons/Constants";
import {
  validateExistKey,
  validateNumber,
  validateString,
} from "../../commons/Functions";

var validateCustomerInfo = function (customerInfo) {
  const customerError = {
    customer_type: validateExistKey(
      "契約形態",
      customerInfo.customer_type,
      customerTypeList
    ),
    contract_type: validateExistKey(
      "販売形態",
      customerInfo.contract_type,
      contractTypeList
    ),
    supervisor: validateString("営業担当者", customerInfo.supervisor, 6, false),
  };
  if (customerInfo.existing_customer == 1) {
    customerError["customer_name"] = validateString(
      "既存顧客",
      customerInfo.customer_no == 0 ? null : customerInfo.customer_no,
      6,
      true
    );
  } else {
    customerError["customer_name"] = validateString(
      "顧客名",
      customerInfo.input_customer_name,
      50,
      true
    );
  }
  return customerError;
};

var validateLocationInfo = function (locationInfo, type = "regist") {
  const locationError = {
    project_title: validateString(
      "現場名",
      locationInfo.project_title,
      256,
      true
    ),
    prefecture: validateExistKey(
      "都道府県",
      locationInfo.prefecture,
      prefectureList,
      false
    ),
    city: validateString("市区", locationInfo.city, 50, false),
    address_additional: validateString(
      "それ以降の住所",
      locationInfo.address_additional,
      50,
      false
    ),
    construct_type: validateExistKey(
      "建物種別",
      locationInfo.construct_type,
      constructTypeList
    ),
  };

  if (type == "regist") {
    locationError["site_num"] = validateNumber(
      "棟数",
      locationInfo.site_num,
      siteNumList,
      true
    );
    let error_site_names = [];
    for (let key in locationInfo.site_names) {
      // console.log(key);
      // console.log(locationInfo.site_names[key]);
      const err = validateString(
        "号棟名" + (parseInt(key) + 1),
        locationInfo.site_names[key],
        50,
        false
      );
      if (err) {
        error_site_names[key] = err;
      }
    }

    locationError["site_names"] = error_site_names.length
      ? error_site_names
      : null;
  }

  if (type == "update") {
    let error_sites = [];
    for (let key in locationInfo.sites) {
      // console.log(key);
      // console.log(locationInfo.site_names[key]);
      const err = validateString(
        "号棟名" + (parseInt(key) + 1),
        locationInfo.sites[key].site_name,
        50,
        false
      );
      // console.log(err);
      if (err) {
        error_sites[key] = err;
      }
    }

    locationError["sites"] = error_sites.length ? error_sites : null;
  }

  return locationError;
};
export { validateCustomerInfo, validateLocationInfo };
