import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { planStatusList, PLAN_STATUS } from "../commons/Constants";
import LabelValue from "../templates/LabelValue";
import { Tooltip } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import { dateFormat } from "../commons/Functions";

const useStyles = makeStyles((theme) => ({
  datetime: {
    margin: theme.spacing(0),
  },
  remand: {
    color: "#D83420",
    // verticalAlign: "middle",
  },
  icon: {
    fontSize: "15px",
    // paddingBottom: theme.spacing(-1),
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    color: "#D83420",
    backgroundColor: "#ffffff",
    border: "solid 1px #D83420",
  },
  customArrow: {
    // color: "#D83420",
    "&::before": {
      backgroundColor: "#ffffff",
      border: "1px solid #D83420",
    },
  },
}));

export default function PlanStatus(props) {
  const classes = useStyles();

  const label = props.isLabel ? "プラン作成状況" : null;

  const sum = {};
  // console.log(props.sites);

  let tooltip = [];
  for (var plan of props.plans) {
    if (sum[plan.status]) {
      sum[plan.status] += 1;
    } else {
      sum[plan.status] = 1;
    }
    if (plan.status == PLAN_STATUS.REMAND) {
      tooltip.push(dateFormat(plan.posted_at, false));
    }
  }

  const view = [];

  const options = planStatusList.sort((a, b) => a.sort_order - b.sort_order);

  for (var v of options) {
    // console.log(v);
    for (var k in sum) {
      if (v.value == k) {
        view.push(
          <div key={k} className={v.value == 5 ? classes.remand : null}>
            {v.value == 5 && (
              <Tooltip
                title={tooltip}
                arrow
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <UpdateIcon className={classes.icon} />
              </Tooltip>
            )}
            <span>
              {v.name}:{sum[k]}
            </span>
          </div>
        );
        break;
      }
    }
  }

  return (
    <LabelValue label={label} showType={props.showType}>
      {view}
    </LabelValue>
  );
}

PlanStatus.propTypes = {
  plans: PropTypes.array,
  isLabel: PropTypes.bool,
  showType: PropTypes.number,
};
PlanStatus.defaultProps = {
  plans: [],
  isLabel: false,
  showType: 1,
};
