import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "../../atoms/Button";
// import User from "../../commons/User";
import Layout from "../../templates/Layout";
import Modal from "../../templates/Modal";
import User from "../../commons/User";
import LabelValue from "../../templates/LabelValue";
import Input from "../../atoms/Input";
import CallAPI from "../../molecules/CallAPI";
import { isValid, validateString } from "../../commons/Functions";
import { InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  body: {
    // marginRight: "auto",
    minHeight: "480px",
  },
  center: {
    marginRight: "auto",
    marginLeft: "auto",
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  right: {
    textAlign: "right",
  },
  link: {
    color: theme.palette.text.secondary,
    "&:hover": {
      // opacity: "0.01",
      color: "#333",
    },
  },
  outline: {
    marginTop: theme.spacing(2),
    width: "100%",
    backgroundColor: "#ffffff",
    border: "1px solid #999999",
    borderRadius: "8px",
    padding: "8px 16px",
  },
  label: {
    color: "#333333",
    position: "absolute",
    top: "40%",
    backgroundColor: "#ffffff",
    padding: "4px 8px",
  },
  button: {
    height: "42px",
    width: "220px",
    marginBottom: theme.spacing(2),
    fontSize: "20px",
  },
}));

export default function Login() {
  const classes = useStyles();

  useEffect(() => {
    User.logout(false);
  }, []);

  const [apiParams, setApiParams] = useState({});
  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({});

  const handleEmail = (e) => {
    setInput({ ...input, email: e.target.value });
  };

  const handlePassword = (e) => {
    setInput({ ...input, password: e.target.value });
  };

  const handleLogin = () => {
    const error = {
      email: validateString("メールアドレス", input.email, 256, true),
      password: validateString("パスワード", input.password, 256, true),
    };
    setError(error);

    if (!isValid(error)) {
      return;
    }

    login();
  };

  const login = () => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/rest-auth/login/`,
      method: "POST",
      params: input,
      setData: setData,
      isLogin: true,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = () => {
    // console.log(data);
    window.open("/", "_self");
  };

  const handleSsoLogin = () => {
    window.open("/auth2/login", "_self");
  };

  return (
    <Layout title="ログイン" isAuth={false}>
      <div className={classes.body}></div>
      <Modal open={true} title="Dash案件管理にログイン" style="login">
        <div className={classes.center}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSsoLogin}
            className={classes.button}
          >
            自動ログイン
          </Button>
        </div>

        <div className={classes.outline}>
          <InputLabel className={classes.label}>社外の方ログイン</InputLabel>
          <LabelValue label="メールアドレス" underline={false} grid={3}>
            <Input
              id="email"
              value={input.email}
              onChange={handleEmail}
              // placeholder={"例)営業担当名"}
              error={error.email}
            />
          </LabelValue>
          <LabelValue label="パスワード" underline={false} grid={3}>
            <Input
              id="password"
              value={input.password}
              onChange={handlePassword}
              type="password"
              // placeholder={"例)営業担当名"}
              error={error.password}
              onKeyEnter={handleLogin}
            />
          </LabelValue>

          <LabelValue label="" underline={false} grid={3}>
            <Button variant="contained" color="primary" onClick={handleLogin}>
              ログイン
            </Button>
          </LabelValue>
        </div>
      </Modal>
      <CallAPI params={apiParams} />
    </Layout>
  );
}
