import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    marginRight: theme.spacing(1),
    fontSize: "13px",
  },
}));

export default function Breadcrumb(props) {
  const classes = useStyles();

  return (
    <>
      {props.value.map((item) => (
        <span key={item.url} className={classes.text}>
          {item.url === "self" && <>{item.text}</>}
          {item.url !== "self" && <Link href={item.url}>{item.text} &gt;</Link>}
        </span>
      ))}
    </>
  );
}

Breadcrumb.propTypes = {
  value: PropTypes.array,
};
Breadcrumb.defaultProps = {
  value: [],
};
