import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import PlanSearch from "../../organisms/PlanSearch";
import PlanList from "../../organisms/PlanList";
// import User from "../User";

import Layout from "../../templates/Layout";
import PlanTabs from "../../organisms/PlanTabs";
import TabPanel from "../../templates/TabPanel";
// import LabelValue from "../../templates/LabelValue";
// import Checkbox from "../../atoms/Checkbox";
import {
  isAdmin,
  isDesigner,
  isDesignerAdmin,
  isDesignerExternal,
} from "../../commons/Functions";
import User from "../../commons/User";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(1),
  },
}));

export default function List(props) {
  const classes = useStyles();

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    if (!isDesigner(authInfo)) {
      window.open("/", "_self");
      return;
    }
    setAuthInfo(authInfo);
    // const start_date = new Date();
    // const end_date = new Date();
    // start_date.setMonth(start_date.getMonth() - 1);

    setPage(getPageState() ?? null);

    const params = getSearchState();

    if (params) {
      setTabNo(params.tab_no ?? 0);
      // setChangeDesignerFlag(params.change_designer_flag ?? [0]);
    }

    setSearch(
      params ?? {
        start_date: null,
        end_date: null,
        branch_id: authInfo.branch ? authInfo.branch.id : null,
        designer: isDesignerAdmin(authInfo) ? null : authInfo.user.id,
        plan_status:
          isDesignerAdmin(authInfo) && !isAdmin(authInfo)
            ? ["7"]
            : ["2", "3", "7", "5"],
        change_designer_flag: [0],
        tab_no: 0,
      }
    );
  }, []);

  const [authInfo, setAuthInfo] = useState({});
  const [search, setSearch] = useState({});
  const [page, setPage] = useState(null);
  const [tabNo, setTabNo] = useState(0);
  // const [changeDesignerFlag, setChangeDesignerFlag] = useState([0]);

  const handleSearch = (params) => {
    setPage(null);
    setSearch({
      ...search,
      ...params,
      tab_no: tabNo,
      change_designer_flag: [0],
      timestamp: new Date(),
    });
  };

  const getSearchState = () => {
    const params = props.location.state ? props.location.state.search : {};
    return params && Object.keys(params).length != 0 ? params : null;
  };
  const getPageState = () => {
    const params = props.location.state ? props.location.state.page : null;
    return params && Object.keys(params).length != 0 ? params : null;
  };

  const handleChangeTabNo = (no) => {
    setTabNo(no);
    setSearch({ ...search, tab_no: no });
  };

  // const handleChangeDesignerFlag = (e) => {
  //   setChangeDesignerFlag(e);
  //   setSearch({ ...search, change_designer_flag: e });
  // };

  let tab_labels = ["個人", "他担当者の依頼"];
  if (isDesignerExternal(authInfo)) {
    tab_labels = ["受け取った依頼", "その他の依頼"];
  }

  return (
    <Layout
      title="プラン依頼一覧"
      breadcrumb={[{ url: "self", text: "プラン依頼一覧" }]}
    >
      <div className={classes.page}>
        <PlanSearch
          searchParams={search}
          onSearch={handleSearch}
          authInfo={authInfo}
          tabNo={tabNo}
        />

        {!isDesignerAdmin(authInfo) && (
          <PlanTabs
            onChange={handleChangeTabNo}
            labels={tab_labels}
            tabNo={tabNo}
          />
        )}

        <TabPanel value={tabNo} index={0}>
          {/* {!isDesignerExternal(authInfo) && (
            <LabelValue>
              <Checkbox
                id="change_designer_flag"
                checked={changeDesignerFlag}
                onChange={handleChangeDesignerFlag}
                options={[{ value: 1, name: "担当変更可能依頼のみ表示" }]}
              />
            </LabelValue>
          )} */}

          <PlanList searchParams={search} authInfo={authInfo} page={page} />
        </TabPanel>

        <TabPanel value={tabNo} index={1}>
          <PlanList searchParams={search} authInfo={authInfo} page={page} />
        </TabPanel>
      </div>
    </Layout>
  );
}

List.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
