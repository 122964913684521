import React from "react";
import PropTypes from "prop-types";
import { contractStatusList, siteProgressList } from "../commons/Constants";
import LabelValue from "../templates/LabelValue";

export default function ContractStatus(props) {
  const label = props.isLabel ? "契約ステータス" : null;

  const sum = {};
  // console.log(props.sites);

  for (var site of props.sites) {
    const k = siteProgressList[site.progress];
    if (sum[k]) {
      sum[k] += 1;
    } else {
      sum[k] = 1;
    }
  }

  const view = [];

  const options = contractStatusList.sort(
    (a, b) => a.sort_order - b.sort_order
  );

  for (var v of options) {
    // console.log(v);
    for (var k in sum) {
      if (v.name == k) {
        view.push(
          <div key={k}>
            {k}:{sum[k]}
          </div>
        );
        break;
      }
    }
  }

  return (
    <LabelValue label={label} showType={props.showType}>
      {view}
    </LabelValue>
  );
}

ContractStatus.propTypes = {
  sites: PropTypes.array,
  isLabel: PropTypes.bool,
  showType: PropTypes.number,
};
ContractStatus.defaultProps = {
  sites: [],
  isLabel: false,
  showType: 1,
};
