import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";

// import Button from "../../atoms/Button";
import User from "../../commons/User";
import OkCancel from "../../molecules/OkCancel";
import {
  gotoTopUrl,
  isSales,
  isValid,
  scrollTo,
} from "../../commons/Functions";
import Layout from "../../templates/Layout";
import InputContractInfo from "../../organisms/InputContractInfo";
import InputLocationInfo from "../../organisms/InputLocationInfo";
import CallAPI from "../../molecules/CallAPI";
import History from "../../commons/History";
import { validateCustomerInfo, validateLocationInfo } from "./Validate";

export default function Regist() {
  useEffect(() => {
    const authInfo = User.getAuthInfo();
    if (!isSales(authInfo)) {
      gotoTopUrl(authInfo);
      return;
    }
    // setAuthInfo(authInfo);
    setCustomerInfo({
      customer_type: 1,
      contract_type: 0,
      customer_no: 0,
      existing_customer: 1,
      supervisor: authInfo.user.id,
      user: authInfo.user,
      branch: authInfo.branch,
    });
    // console.log("authInfo.user.id", authInfo.user.id);
  }, []);

  const [customerInfo, setCustomerInfo] = useState({});
  const [locationInfo, setLocationInfo] = useState({
    construct_type: 1,
    site_num: 1,
    site_names: [null],
  });
  const [customerInfoError, setCustomerInfoError] = useState({});
  const [locationInfoError, setLocationInfoError] = useState({});
  const [apiParams, setApiParams] = useState({});

  const handleCustomer = (e) => {
    // console.log(e);
    setCustomerInfo(e);
  };

  const handleLocation = (e) => {
    // console.log(e);
    setLocationInfo(e);
  };

  const handleRegist = () => {
    if (!validateProject()) {
      return;
    }
    createProject(customerInfo, locationInfo, "detail");
  };

  const handleRegistAndPlan = () => {
    if (!validateProject()) {
      return;
    }
    createProject(customerInfo, locationInfo, "plan");
  };

  const validateProject = () => {
    // バリデーション
    const customerError = validateCustomerInfo(customerInfo);
    setCustomerInfoError(customerError);

    const locationError = validateLocationInfo(locationInfo);
    setLocationInfoError(locationError);

    if (!isValid(customerError)) {
      scrollTo("InputContractInfo");
      return false;
    }
    if (!isValid(locationError)) {
      scrollTo("InputLocationInfo");
      return false;
    }
    return true;
  };

  const createProject = (customerInfo, locationInfo, type) => {
    const create_params = {
      customer_type: customerInfo.customer_type,
      contract_type: customerInfo.contract_type,
      customer_no:
        customerInfo.existing_customer == 1 ? customerInfo.customer_no : 0,
      customer_name:
        customerInfo.existing_customer == 1
          ? customerInfo.customer_name
          : customerInfo.input_customer_name,
      supervisor: customerInfo.supervisor,
      project_title: locationInfo.project_title,
      prefecture: locationInfo.prefecture,
      city: locationInfo.city,
      address_additional: locationInfo.address_additional,
      construct_type: locationInfo.construct_type,
      note: locationInfo.note,
      site_num: locationInfo.site_num,
      site_names: locationInfo.site_names,
      timestamp: new Date(),
    };
    // console.log(create_params, type);

    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/project/`,
      method: "POST",
      params: create_params,
      setData: setData,
      retValue: type,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = (data, type) => {
    // if (type == "detail") {
    //   History.push("/project/" + encodeURI(data.project_id));
    //   return;
    // }
    if (type == "plan") {
      History.push("/plan/regist/" + encodeURI(data.project_id));
      return;
    }
    History.push("/project/");
  };

  const handleCancel = () => {
    // window.open("/project/", "_self");
    History.push("/project/");
  };

  return (
    <Layout
      title="新規案件入力"
      breadcrumb={[
        { url: "/project/", text: "案件一覧" },
        { url: "self", text: "新規案件入力" },
      ]}
    >
      <div id="scroll-area">
        <Element id="InputContractInfo" />
        <InputContractInfo
          value={customerInfo}
          onChange={handleCustomer}
          error={customerInfoError}
        />
        <Element id="InputLocationInfo" />
        <InputLocationInfo
          value={locationInfo}
          onChange={handleLocation}
          error={locationInfoError}
          type="regist"
        />
        {/* <ProjectSearch onSearch={(e) => handleSearch(e)} userList={userList} />
        <div className={classes.right}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegistProject}
            startIcon={<AddIcon />}
          >
            新規案件入力
          </Button>
        </div>
        <ProjectList searchParams={search} onClickRow={handleClickRow} /> */}
        <OkCancel
          ok="登録する"
          onOk={handleRegist}
          ok2="登録してプラン作成依頼へ"
          onOk2={handleRegistAndPlan}
          onCancel={handleCancel}
        />
      </div>
      <CallAPI params={apiParams} />
    </Layout>
  );
}
