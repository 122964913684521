import React, { useEffect, useState } from "react";

import User from "../../commons/User";
import Layout from "../../templates/Layout";
import Name from "../../molecules/Name";
import LabelValue from "../../templates/LabelValue";
import Branch from "../../molecules/Branch";
import CallAPI from "../../molecules/CallAPI";
import Index from "../../molecules/Index";
import { gotoTopUrl } from "../../commons/Functions";
import { roleList } from "../../commons/Constants";
import ListValue from "../../molecules/ListValue";
import Button from "../../atoms/Button";

export default function Debug() {
  const env = `${process.env.REACT_APP_ENV}`;

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    console.log(authInfo);
    if (env == "prod") {
      gotoTopUrl(authInfo);
      return;
    }
    setAuthInfo(authInfo);
  }, []);

  const [authInfo, setAuthInfo] = useState();
  const [apiParams, setApiParams] = useState({});

  const handleReload = (is_change_role = false) => {
    console.log("reload");
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/auth/is_login/`,
      method: "GET",
      setData: reloadData,
      successMessage: is_change_role ? null : "読み込みました。",
      isSubmit: true,
    };
    setApiParams(params);
  };

  const reloadData = (data) => {
    console.log("reloaded", data);
    const authInfo = { ...data };
    setAuthInfo(authInfo);
    User.resetAuthInfo(authInfo);
  };

  return (
    <Layout
      title="テスト用機能"
      breadcrumb={[
        { url: "/setting/", text: "システム設定" },
        { url: "self", text: "テスト用機能" },
      ]}
    >
      {authInfo && (
        <>
          <Index value="ユーザー情報" />

          <LabelValue label="ID">{authInfo.user.id}</LabelValue>
          <LabelValue label="名前">
            <Name user={authInfo.user} isHonorific={true} />
          </LabelValue>
          <LabelValue label="メールアドレス">{authInfo.user.email}</LabelValue>
          {authInfo.branch && (
            <LabelValue label="所属">
              <Branch branch={authInfo.branch} />
            </LabelValue>
          )}
          <LabelValue label="権限">
            <ListValue list={roleList} value={authInfo.user.role.code} />
          </LabelValue>

          <Index value="デバッグ" />
          <LabelValue label="環境">{env}環境</LabelValue>
          <LabelValue label="システム設定値">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleReload()}
            >
              再読み込み
            </Button>
          </LabelValue>
          {/* <LabelValue label="権限">
            <Select
              id="role"
              onChange={handleRole}
              options={roleList}
              value={roleCode}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleChangeRole()}
            >
              変更
            </Button>
          </LabelValue> */}
        </>
      )}
      <CallAPI params={apiParams} />
    </Layout>
  );
}
