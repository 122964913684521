import React from "react";
import PropTypes from "prop-types";
import { customerTypeList, contractTypeList } from "../commons/Constants";
import LabelValue from "../templates/LabelValue";

export default function ContractType(props) {

  const customerType = customerTypeList.find(
    (v) => v.value == props.customerType
  );
  const contractType = contractTypeList.find(
    (v) => v.value == props.contractType
  );

  const label = props.isLabel ? "契約形態" : null;

  return (
    <LabelValue label={label}>
      {!customerType && !contractType ? <>&nbsp;</> : <></>}
      {customerType && <>{customerType.name}</>}
      {contractType && <>({contractType.name})</>}
    </LabelValue>
  );
}

ContractType.propTypes = {
  customerType: PropTypes.number,
  contractType: PropTypes.number,
  isLabel: PropTypes.bool,
};
ContractType.defaultProps = {
  customerType: null,
  contractType: null,
  isLabel: false,
};
