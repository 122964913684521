import React, { useEffect, useState } from "react";
// import User from "../User";
import EmailTwoToneIcon from "@material-ui/icons/EmailTwoTone";
import { makeStyles } from "@material-ui/core/styles";

import User from "../../commons/User";
import Layout from "../../templates/Layout";
import Name from "../../molecules/Name";
import LabelValue from "../../templates/LabelValue";
import Branch from "../../molecules/Branch";
import CallAPI from "../../molecules/CallAPI";
import Message from "../../templates/Message";
import Textarea from "../../atoms/Textarea";
import OkCancel from "../../molecules/OkCancel";
import Index from "../../molecules/Index";
import { isDesigner, isDesignerAdmin } from "../../commons/Functions";
import { roleList } from "../../commons/Constants";
import ListValue from "../../molecules/ListValue";

const useStyles = makeStyles((theme) => ({
  circle: {
    borderRadius: "16px",
    // minWidth: "28px",
    padding: "4px 0px",
    marginBottom: "-4px",
    marginRight: theme.spacing(1),
    backgroundColor: "#333333",
    color: "#FFFFFF",
  },
  outline: {
    width: "100%",
    backgroundColor: "#ffffff",
    border: "1px solid #999999",
    borderRadius: "8px",
    padding: "8px 16px",
    // textAlign: "center",
  },
}));

export default function Profile() {
  const classes = useStyles();

  useEffect(() => {
    const authInfo = User.getAuthInfo();
    setAuthInfo(authInfo);
    if (isDesigner(authInfo)) {
      loadData(authInfo.user.id);
    }
  }, []);

  const [authInfo, setAuthInfo] = useState();
  const [apiParams, setApiParams] = useState({});
  const [designerMessage, setDesignerMessage] = useState({});
  const [openInput, setOpenInput] = useState(false);
  const [message, setMessage] = useState();

  const loadData = (designer) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/user/${designer}/designer_message/`,
      method: "GET",
      params: {},
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = (data) => {
    setDesignerMessage(data.designer_message);
  };

  const handleEdit = () => {
    setMessage(designerMessage ? designerMessage.message : "");
    setOpenInput(true);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleSave = () => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/user/${authInfo.user.id}/update_designer_message/`,
      method: "PUT",
      params: {
        message: message,
      },
      setData: setDataSave,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setDataSave = (data) => {
    setDesignerMessage(data.designer_message);
    setOpenInput(false);
  };

  return (
    <Layout
      title="プロフィール"
      breadcrumb={[{ url: "self", text: "プロフィール" }]}
    >
      {authInfo && (
        <>
          <Index value="ユーザー情報" />

          <LabelValue label="ID">{authInfo.user.id}</LabelValue>
          <LabelValue label="名前">
            <Name user={authInfo.user} isHonorific={true} />
          </LabelValue>
          <LabelValue label="メールアドレス">{authInfo.user.email}</LabelValue>
          {authInfo.branch && (
            <LabelValue label="所属">
              <Branch branch={authInfo.branch} />
            </LabelValue>
          )}
          <LabelValue label="権限">
            <ListValue list={roleList} value={authInfo.user.role.code} />
          </LabelValue>

          {isDesigner(authInfo) && !isDesignerAdmin(authInfo) && (
            <LabelValue label="">
              {openInput ? (
                <>
                  <Index
                    value="営業への伝言（プラン作成依頼時の画面に、ここに書いたメッセージが表示されます）"
                    startIcon={<EmailTwoToneIcon className={classes.circle} />}
                    isUnderline={false}
                  />

                  <Textarea
                    id="message"
                    value={message}
                    onChange={handleMessage}
                  />
                  <OkCancel
                    onCancel={() => setOpenInput(false)}
                    onOk={handleSave}
                  />
                </>
              ) : (
                <>
                  <Message
                    label="営業への伝言（プラン作成依頼時の画面に、ここに書いたメッセージが表示されます）"
                    showType={2}
                    isIcon={true}
                  >
                    {designerMessage ? designerMessage.message : null}
                    <OkCancel onOk={handleEdit} ok="編集" />
                  </Message>
                </>
              )}
            </LabelValue>
          )}
        </>
      )}
      <CallAPI params={apiParams} />
    </Layout>
  );
}
