import React, { useState } from "react";
import PropTypes from "prop-types";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";
import { mailWordList } from "../commons/Constants";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "150px",
    margin: theme.spacing(0),
  },
}));

/**
 * 差し込み選択
 */
export default function SelectMailWord(props) {
  const classes = useStyles();

  const [openSelect, setOpenSelect] = useState(false);

  const handleClose = () => {
    setOpenSelect(false);
  };

  const handleOpen = () => {
    setOpenSelect(true);
  };

  let value = "";

  return (
    <FormGroup>
      <FormControl variant="outlined">
        <Select
          className={classes.inputField}
          // native
          // value={props.value != null ? props.value + "" : ""}
          value={value}
          // defaultValue=""
          displayEmpty
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={props.onClick}
          // onClick={props.onClick}
          disabled={props.disabled}
          open={openSelect}
        >
          <MenuItem value="">
            {/* <AddCircleOutlineIcon /> */}
            ＋差込文字
          </MenuItem>

          {Object.keys(mailWordList).map((key) => {
            return (
              <MenuItem value={key} key={key}>
                {mailWordList[key]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </FormGroup>
  );
}

SelectMailWord.propTypes = {
  value: PropTypes.any,
  // onInsert: PropTypes.func,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

SelectMailWord.defaultProps = {
  value: null,
  disabled: false,
};
