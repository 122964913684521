import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import CallMadeIcon from "@material-ui/icons/CallMade";
import { Link } from "@material-ui/core";

import { addPageParams } from "../commons/Functions";
import {
  PLAN_STATUS,
  reviewStatusList,
  rowsPerPage,
} from "../commons/Constants";
import Table from "../atoms/Table";
import Modal from "../templates/Modal";
import Message from "../templates/Message";
import PostFileList from "../molecules/PostFileList";
import OkCancel from "../molecules/OkCancel";
import CallAPI from "../molecules/CallAPI";
import Button from "../atoms/Button";
import ContractStatus from "../molecules/ContractStatus";
// import DesiredDeliveryDate from "../molecules/DesiredDeliveryDate";
import Name from "../molecules/Name";
import History from "../commons/History";
import PlanStatus from "../molecules/PlanStatus";
import PublicFlag from "../molecules/PublicFlag";
import LabelValue from "../templates/LabelValue";
import ListValue from "../molecules/ListValue";
import Datetime from "../molecules/Datetime";

// format: (value) => value.toLocaleString("jp"),
// format: (value) => value.toFixed(2),

const useStyles = makeStyles(() => ({
  on: {
    color: "#eeee00",
  },
  file: {},
}));

/**
 * 案件一覧
 */
const ProjectList = (props) => {
  const classes = useStyles();

  useEffect(() => {
    const params = props.page ?? {
      ...page,
      offset: 0,
    };
    setPage(params);
    loadData(params);
  }, [props.searchParams]);

  const [projects, setProjects] = useState({
    list: [],
    count: 0,
  });

  const [apiParams, setApiParams] = useState({});
  const [bookmarks, setBookmarks] = useState([]);
  const [users, setUsers] = useState([]);
  const [openPostFile, setOpenPostFile] = useState(false);
  const [allPostFiles, setAllPostFiles] = useState([]);
  const [postFiles, setPostFiles] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();
  const [kizunaCustomers, setKizunaCustomers] = useState();

  // ページ管理
  const [page, setPage] = useState({
    offset: 0,
    limit: rowsPerPage,
    sort: "created_at",
    order: "desc",
  });

  // 改ページ
  const handleChangePageNo = (e, offset) => {
    // console.log(offset);
    const params = {
      ...page,
      offset: offset,
    };
    setPage(params);
    loadData(params);
  };

  const handleSort = (sort, order) => {
    // console.log(sort, order);
    const params = {
      ...page,
      offset: 0,
      sort: sort,
      order: order,
    };
    setPage(params);
    loadData(params);
  };

  const handleChengeBookmark = (id) => {
    changeBookmark(id);
  };

  // データ取得
  const loadData = (page) => {
    setSearchState(props.searchParams, page);

    const search_params = {
      ...props.searchParams,
      timestamp: new Date(),
    };

    delete search_params.user;
    delete search_params.branch_name;

    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/project/search_project/`,
      method: "GET",
      params: addPageParams(search_params, page),
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = (data) => {
    // console.log(data);

    let projects1 = [];
    let projects2 = [];

    for (let p of data.projects.list) {
      let n = { ...p };
      n.plan_requests = data.plan_requests.filter((v) => v.project_id == p.id);
      projects1.push(n);
    }

    for (let p of projects1) {
      let n = { ...p };
      n.sites = data.sites.filter((v) => v.project == p.id);
      projects2.push(n);
    }

    setProjects({
      list: projects2,
      count: data.projects.count,
    });
    setBookmarks(data.project_bookmarks);
    setUsers(data.users);
    setAllPostFiles(data.post_files);
    setKizunaCustomers(data.kizuna_customers);
  };

  const setSearchState = (params, page) => {
    props.history.push({
      pathname: props.history.location.pathname,
      state: { search: params, page: page },
    });
  };

  const changeBookmark = (project_id) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/project/change_bookmark/`,
      method: "PUT",
      params: { project_id: project_id },
      setData: setDataBookmark,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setDataBookmark = (data) => {
    setBookmarks(data.project_bookmarks);
  };

  // const handleClickDetail = (id) => {
  //   // window.open("/project/" + encodeURI(id), "_self");
  //   History.push("/project/" + encodeURI(id));
  // };
  const handleOpenPostFile = (plan) => {
    // console.log(plan.id);
    const files = allPostFiles.filter((v) => v.plan_request_id == plan.id);
    setPostFiles(files);

    setSelectedPlan(plan);

    setOpenPostFile(true);
  };
  const handleClosePostFile = () => {
    setOpenPostFile(false);
  };

  const handleReuestPlan = (project_id) => {
    // console.log(project_id);
    History.push("/plan/regist/" + encodeURI(project_id));
    return;
  };

  const getBookmark = (val, id) => {
    const is_checked = bookmarks.find((e) => e.project == id);

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleChengeBookmark(id)}
          style="star"
        >
          {is_checked ? (
            <StarRoundedIcon className={classes.on} />
          ) : (
            <StarBorderRoundedIcon />
          )}
        </Button>
      </div>
    );
  };

  const getProjectTitle = (val, id) => {
    // console.log("title", id);
    const url = "/project/" + encodeURI(id);
    return <Link href={url}>{val}</Link>;
  };

  const getContractStatus = (val, id, row) => {
    // console.log("ContractStatus", row);
    return <ContractStatus sites={row.sites} />;
  };

  const getSiteNum = (val, id, row) => {
    // console.log("ContractStatus", row);
    return <span>{row.sites.length}</span>;
  };

  const getPlanStatus = (val, id, row) => {
    if (row.plan_requests.length) {
      const url = "/project/" + encodeURI(id) + "?tab=plan";
      return (
        <Link href={url}>
          <PlanStatus plans={row.plan_requests} />
        </Link>
      );
    }

    return (
      <Button
        variant="text"
        color="primary"
        onClick={() => handleReuestPlan(id)}
        startIcon={<CallMadeIcon />}
      >
        依頼
      </Button>
    );
  };

  const getPublicFlag = (val, id, row) => {
    const customer =
      kizunaCustomers && kizunaCustomers.find((v) => v.id == row.customer_no);
    const is_dash =
      customer && customer.is_invitation_dash == true ? true : false;
    return <>{is_dash ? <PublicFlag value={val} /> : <>未加入</>}</>;
  };
  const getUser = (val) => {
    const user = users.find((e) => e.id == val);

    return <Name user={user} />;
  };

  const getPostFile = (val, id, row) => {
    const post_file = Object.keys(row.plan_requests).map((key) => {
      return (
        <div key={key}>
          {row.plan_requests[key].status == PLAN_STATUS.COMPLETE && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenPostFile(row.plan_requests[key])}
              className={classes.file}
              style="file"
            >
              <DescriptionOutlinedIcon />
            </Button>
          )}
        </div>
      );
    });
    return post_file;
  };

  const columns = [
    {
      id: "bookmark",
      label: "",
      isSort: false,
      minWidth: 10,
      format: (value, id) => {
        return getBookmark(value, id);
      },
      align: "center",
    },
    // { id: "id", label: "案件ID", isSort: true, minWidth: 100 },
    {
      id: "created_at",
      label: "登録日",
      isSort: true,
      minWidth: 50,
      format: (value) => {
        const getData = (value) => {
          return <Datetime value={value} isTime={false} />;
        };
        return getData(value);
      },
    },
    {
      id: "project_title",
      label: "現場名",
      isSort: true,
      minWidth: 100,
      format: (value, id) => {
        return getProjectTitle(value, id);
      },
    },
    {
      id: "site_num",
      label: "棟数",
      isSort: false,
      minWidth: 30,
      align: "center",
      format: (value, id, row) => {
        return getSiteNum(value, id, row);
      },
    },
    { id: "customer_name", label: "顧客名", isSort: true, minWidth: 50 },
    {
      id: "public_flag",
      label: "顧客公開",
      isSort: true,
      minWidth: 50,
      format: (value, id, row) => {
        return getPublicFlag(value, id, row);
      },
    },
    {
      id: "supervisor",
      label: "営業担当",
      isSort: true,
      minWidth: 100,
      format: (value) => {
        return getUser(value);
      },
    },
    {
      id: "plan_status",
      label: "プラン",
      isSort: false,
      minWidth: 50,
      format: (value, id, row) => {
        return getPlanStatus(value, id, row);
      },
    },
    {
      id: "contract_status",
      label: "契約",
      isSort: false,
      minWidth: 50,
      format: (value, id, row) => {
        return getContractStatus(value, id, row);
      },
    },
    // {
    //   id: "desired_delivery_date",
    //   label: "納品希望日",
    //   isSort: true,
    //   minWidth: 100,
    //   format: (value, id, row) => {
    //     return getDesiredDeliveryDate(value, id, row);
    //   },
    // },
    {
      id: "file",
      label: "",
      isSort: false,
      minWidth: 10,
      format: (value, id, row) => {
        return getPostFile(value, id, row);
      },
      align: "center",
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={projects}
        page={page}
        onChangePageNo={handleChangePageNo}
        // onClickRow={handleClickDetail}
        onClickSort={handleSort}
      />
      {selectedPlan && (
        <Modal open={openPostFile} onClose={handleClosePostFile} style="large">
          <Message label="設計者担当者からの伝達事項" isIcon={true}>
            {selectedPlan.post_note}
          </Message>
          <LabelValue label="レビュー状態">
            <ListValue
              list={reviewStatusList}
              value={selectedPlan.review_status}
            />
          </LabelValue>

          <PostFileList
            data={postFiles}
            project_id={selectedPlan.project_id}
            plan_id={selectedPlan.id}
          />

          <OkCancel
            onCancel={handleClosePostFile}
            onOk={null}
            cancel="閉じる"
          />
        </Modal>
      )}

      <CallAPI params={apiParams} />
    </>
  );
};

ProjectList.propTypes = {
  history: PropTypes.object.isRequired,
  searchParams: PropTypes.object,
  page: PropTypes.object,
  onCallback: PropTypes.func,
};

ProjectList.defaultProps = {
  searchParams: {},
};

export default withRouter(ProjectList);
