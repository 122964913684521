import axios from "axios";
import {
  constructTypeList,
  desiredProductGradeList,
  existenceList,
  fireProtectionList,
  floorNumList,
  heightDifferenceList,
  heightDistrictList,
  planNumList,
  prefectureList,
  useDistrictList,
  yesNoList,
} from "../../commons/Constants";
import {
  isset,
  isValid,
  validateDate,
  validateExistKey,
  validateFileLength,
  validateFileSize,
  validateNumber,
  validateString,
} from "../../commons/Functions";

var validateRequestInfo = function (requestInfo, is_temp = false) {
  const designer = requestInfo.designer
    ? String(requestInfo.designer).replace("trader_id:", "")
    : null;

  if (is_temp) {
    return {
      // fix_note: validateString("内容", requestInfo.fix_note, 1000, false),
      desired_delivery_date: validateDate(
        "納品希望日",
        requestInfo.desired_delivery_date,
        requestInfo.standard_delivery_date_flag == 0
      ),
      // note: validateString("備考", requestInfo.note, 1000, false),
    };
  }

  const requestInfoError = {
    fix_note: validateString("内容", requestInfo.fix_note, 1000, false),
    // designer: validateString("設計担当", requestInfo.designer, 10, false),
    designer: validateNumber("設計者", designer, true),
    change_designer_flag: validateExistKey(
      "設計担当変更可否",
      requestInfo.change_designer_flag,
      yesNoList
    ),
    plan_num: validateExistKey(
      "プラン作成件数",
      requestInfo.plan_num,
      planNumList
    ),
    desired_delivery_date: validateDate(
      "納品希望日",
      requestInfo.desired_delivery_date,
      requestInfo.standard_delivery_date_flag == 0
    ),
    note: validateString("備考", requestInfo.note, 1000, false),
    request_plan:
      requestInfo.request_plan.length == 0
        ? "依頼図面は1つ以上選択してください。"
        : null,
  };
  return requestInfoError;
};

var validateSiteInfo = function (siteInfo, is_temp = false) {
  if (is_temp) {
    return {
      city: validateString("市区", siteInfo.city, 50, siteInfo.is_address == 0),
      address_additional: validateString(
        "それ以降の住所",
        siteInfo.address_additional,
        50,
        false
      ),
      // height_difference_note: validateString(
      //   "高低差あり(自由指定)",
      //   siteInfo.height_difference_note,
      //   500,
      //   false
      // ),
    };
  }

  const siteInfoError = {
    prefecture: validateExistKey(
      "都道府県",
      siteInfo.prefecture,
      prefectureList,
      siteInfo.is_address == 0
    ),
    city: validateString("市区", siteInfo.city, 50, siteInfo.is_address == 0),
    address_additional: validateString(
      "それ以降の住所",
      siteInfo.address_additional,
      50,
      false
    ),
    site_area: validateNumber(
      "敷地面積",
      siteInfo.site_area,
      siteInfo.is_site_area == 0
    ),
    use_district: validateExistKey(
      "用途地域",
      siteInfo.use_district,
      useDistrictList,
      siteInfo.is_use_district == 0
    ),
    height_district: validateExistKey(
      "高度地区",
      siteInfo.height_district,
      heightDistrictList,
      siteInfo.is_height_district == 0
    ),
    fire_protection: validateExistKey(
      "防火指定",
      siteInfo.fire_protection,
      fireProtectionList,
      siteInfo.is_fire_protection == 0
    ),
    construction_rate: validateNumber(
      "建蔽率",
      siteInfo.construction_rate,
      siteInfo.is_construction_volume_rate == 0
    ),
    volume_rate: validateNumber(
      "建蔽率",
      siteInfo.volume_rate,
      siteInfo.is_construction_volume_rate == 0
    ),
    height_difference: validateExistKey(
      "高低差",
      siteInfo.height_difference,
      heightDifferenceList,
      siteInfo.height_difference != 6
    ),
    height_difference_note: validateString(
      "高低差あり(自由指定)",
      siteInfo.height_difference_note,
      500,
      siteInfo.height_difference == 6
    ),
    road_width_north: validateNumber("前面道路幅員", siteInfo.road_width_north),
    road_width_south: validateNumber("前面道路幅員", siteInfo.road_width_south),
    road_width_east: validateNumber("前面道路幅員", siteInfo.road_width_east),
    road_width_west: validateNumber("前面道路幅員", siteInfo.road_width_west),
  };
  if (siteInfo.is_road_width == 0) {
    if (
      !isset(siteInfo.road_width_north) &&
      !isset(siteInfo.road_width_south) &&
      !isset(siteInfo.road_width_east) &&
      !isset(siteInfo.road_width_west)
    ) {
      siteInfoError.road_width_north = "前面道路幅員は必須入力項目です。";
      siteInfoError.road_width_south = "";
      siteInfoError.road_width_east = "";
      siteInfoError.road_width_west = "";
    }
  }
  // if (siteInfo.is_road_obstacle == 0) {
  //   if (!siteInfo.road_obstacle || siteInfo.road_obstacle.length == 0) {
  //     siteInfoError.road_obstacle = "前面道路障害物を選択してください。";
  //   }
  // }

  return siteInfoError;
};

var validateDesiredBuildingInfo = function (
  desiredBuildingInfo,
  is_temp = false
) {
  if (is_temp) {
    return {
      desired_building_area: validateString(
        "希望建物面積",
        desiredBuildingInfo.desired_building_area,
        50
      ),
      desired_floor_plan: validateString(
        "希望間取り",
        desiredBuildingInfo.desired_floor_plan,
        50
      ),
      desired_garage_num: validateString(
        "車庫数",
        desiredBuildingInfo.desired_garage_num,
        50
      ),
      // desired_note: validateString(
      //   "その他要望/補足",
      //   desiredBuildingInfo.desired_note,
      //   1000
      // ),
    };
  }

  const desiredBuildingInfoError = {
    desired_attached_file_flag: validateExistKey(
      "資料の有無",
      desiredBuildingInfo.desired_attached_file_flag,
      existenceList,
      true
    ),
    desired_construct_type: validateExistKey(
      "建物種別",
      desiredBuildingInfo.desired_construct_type,
      constructTypeList,
      true
    ),
    desired_product_grade: validateExistKey(
      "商品グレード",
      desiredBuildingInfo.desired_product_grade,
      desiredProductGradeList,
      true
    ),
    desired_floor_num: validateExistKey(
      "階数",
      desiredBuildingInfo.desired_floor_num,
      floorNumList,
      true
    ),
    desired_building_area: validateString(
      "希望建物面積",
      desiredBuildingInfo.desired_building_area,
      50
    ),
    desired_floor_plan: validateString(
      "希望間取り",
      desiredBuildingInfo.desired_floor_plan,
      50
    ),
    desired_garage_num: validateString(
      "車庫数",
      desiredBuildingInfo.desired_garage_num,
      50
    ),
    desired_note: validateString(
      "その他要望/補足",
      desiredBuildingInfo.desired_note,
      1000
    ),
  };

  return desiredBuildingInfoError;
};

var validateRequestFile = function (
  requestFiles,
  ini_length = 0,
  ini_size = 0
) {
  const error = {
    length: validateFileLength(requestFiles, ini_length, 100),
    size: validateFileSize(requestFiles, ini_size, 100),
  };
  if (isValid(error)) {
    return null;
  }
  // return null;
  return error;
};

var validatePostFile = function (requestFiles, ini_length = 0, ini_size = 0) {
  const error = {
    length: validateFileLength(requestFiles, ini_length, 100),
    size: validateFileSize(requestFiles, ini_size, 100),
  };
  if (isValid(error)) {
    return null;
  }
  // return null;
  return error;
};

var validateReviewFile = function (requestFiles, ini_length = 0, ini_size = 0) {
  const error = {
    length: validateFileLength(requestFiles, ini_length, 100, false),
    size: validateFileSize(requestFiles, ini_size, 100),
  };
  if (isValid(error)) {
    return null;
  }
  // return null;
  return error;
};

var getFileError = function (file_error) {
  // console.log("file_error", file_error);
  const error = [];

  if (!file_error) {
    return error;
  }

  for (let key of Object.keys(file_error)) {
    error.push(
      <span key={key}>
        {file_error[key]}
        <br />
      </span>
    );
  }
  return error;
};

var getDesignerMessage = function (designer, setData) {
  if (!designer) {
    setData(designer, null);
    return;
  }
  const url = `${process.env.REACT_APP_API_ENDPOINT}/user/${designer}/designer_message/`;
  console.log(url);
  axios.get(url).then((response) => {
    setData(designer, response.data.designer_message);
  });
};

export {
  validateRequestInfo,
  validateSiteInfo,
  validateDesiredBuildingInfo,
  validateRequestFile,
  validatePostFile,
  validateReviewFile,
  getFileError,
  getDesignerMessage,
};
