import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import user from "../../commons/User";
import CallAPI from "../../molecules/CallAPI";
import Layout from "../../templates/Layout";
import { isAdmin, gotoTopUrl } from "../../commons/Functions";
import Datetime from "../../molecules/Datetime";
import Index from "../../molecules/Index";
import LabelValue from "../../templates/LabelValue";
import { emailDeliveryStatusList } from "../../commons/Constants";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    alignItems: "flex-end",
  },
  inputField: {
    width: "200px",
  },
  right: {
    marginLeft: "auto",
    textAlign: "right",
  },
}));

export default function MailHistoryDetail(props) {
  const classes = useStyles();

  const email_delivery_id = props.match.params.id;

  useEffect(() => {
    const authInfo = user.getAuthInfo();
    console.log(authInfo);
    if (!isAdmin(authInfo)) {
      gotoTopUrl(authInfo);
      return;
    }

    loadData();
  }, []);

  const [emailDelivery, setEmailDelivery] = useState({});
  // const [emailDeliveryUsers, setEmailDeliveryUsers] = useState([]);
  const [apiParams, setApiParams] = useState({});

  const loadData = () => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/mail/${email_delivery_id}/history/`,
      method: "GET",
      params: {},
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setData = (data) => {
    setEmailDelivery(data.email_delivery);
    // setEmailDeliveryUsers(data.email_delivery_users);
  };

  return (
    <Layout
      title="メール送信履歴 詳細"
      breadcrumb={[
        { url: "/setting/", text: "システム設定" },
        { url: "/setting/mail_history", text: "メール送信履歴" },
        { url: "self", text: "メール送信履歴 詳細" },
      ]}
    >
      <div className={classes.a}></div>
      <Index value="詳細" />
      <LabelValue label="ID">{emailDelivery.id}</LabelValue>
      <LabelValue label="状態">
        {emailDeliveryStatusList[emailDelivery.status]}
      </LabelValue>
      <LabelValue label="送信日時">
        <Datetime value={emailDelivery.delivery_date} />
      </LabelValue>
      <LabelValue label="件名">{emailDelivery.subject}</LabelValue>
      <LabelValue label="送信者名">{emailDelivery.from_name}</LabelValue>
      <LabelValue label="送信者メールアドレス">
        {emailDelivery.from_email}
      </LabelValue>
      <LabelValue label="Bcc">{emailDelivery.bcc_email}</LabelValue>
      <LabelValue label="本文" isWrap={true}>
        {emailDelivery.body}
      </LabelValue>
      <LabelValue label="署名" isWrap={true}>
        {emailDelivery.signature}
      </LabelValue>
      <LabelValue label="送信先">
        {emailDelivery.email_delivery_users &&
          emailDelivery.email_delivery_users.map((row) => {
            return <div key={row.id}>{row.email}</div>;
          })}
      </LabelValue>
      <CallAPI params={apiParams} />
    </Layout>
  );
}

MailHistoryDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
