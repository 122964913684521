import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  outline: {
    borderBottom: "solid 1px #eee",
  },
  labelGrid: {
    marginTop: theme.spacing(1),
    textAlign: "right",
    paddingRight: theme.spacing(1),
  },
  labelField: {
    lineHeight: "14px",
  },
  inputField: {
    width: "100%",
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  asterisk: {
    color: "#D83420",
  },
  nonLabel: {
    margin: "auto 0px",
    marginRight: theme.spacing(2),
    // alignItems: "center",
  },
  wrap: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  required: {
    marginLeft: theme.spacing(1),
    background: "#D83420",
    borderRadius: "2px",
    color: "#ffffff",
    fontWeight: "600",
    // width: "45px",
    textAlign: "center",
    padding: "2px 8px",
    fontSize: "10px",
    lineHeight: "1",
  },
}));

export default function LabelValue(props) {
  const classes = useStyles();

  if (props.showType == 2) {
    return (
      <>
        <InputLabel>{props.label}</InputLabel>
        <span className={props.isWrap ? classes.wrap : null}>
          {props.children}
        </span>
      </>
    );
  }
  if (props.label === null && props.children !== null) {
    return (
      <div
        className={clsx(classes.nonLabel, props.isWrap ? classes.wrap : null)}
      >
        {props.children}
      </div>
    );
  }

  const outline = props.underline === true ? classes.outline : null;

  const label = props.label ? props.label.replaceAll(/\\n/g, "\n") : null;

  return (
    <Grid container alignItems="baseline" className={outline}>
      {props.label !== null && (
        <Grid item xs={props.grid} className={classes.labelGrid}>
          <InputLabel className={classes.labelField}>
            <span className={classes.wrap}>{label}</span>
            {props.required && <span className={classes.required}>必須</span>}
          </InputLabel>
        </Grid>
      )}
      {/* {props.children && ( */}
      <Grid
        item
        xs={12 - props.grid}
        className={clsx(classes.inputField, props.isWrap ? classes.wrap : null)}
      >
        {props.children}
      </Grid>
      {/* )} */}
    </Grid>
  );
}

LabelValue.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  isWrap: PropTypes.bool,
  required: PropTypes.bool,
  showType: PropTypes.number,
  underline: PropTypes.bool,
  grid: PropTypes.number,
};
LabelValue.defaultProps = {
  label: null,
  children: null,
  isWrap: false,
  required: false,
  showType: 1,
  underline: true,
  grid: 2,
};
