import React from "react";
import PropTypes from "prop-types";

import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
import {
  constructTypeList,
  desiredProductGradeList,
  existenceList,
  floorNumList,
} from "../commons/Constants";

export default function DesiredBuildingInfo(props) {
  const desired_attached_file_flag = existenceList.find(
    (v) => v.value == props.value.desired_attached_file_flag
  );

  const desired_construct_type = constructTypeList.find(
    (v) => v.value == props.value.desired_construct_type
  );

  const desired_floor_num = floorNumList.find(
    (v) => v.value == props.value.desired_floor_num
  );

  const desired_product_grade = desiredProductGradeList.find(
    (v) => v.value == props.value.desired_product_grade
  );

  return (
    <>
      <Index value="建物の希望" />
      <LabelValue label="資料の有無">
        {desired_attached_file_flag ? desired_attached_file_flag.name : ""}
      </LabelValue>
      <LabelValue label="建物種別">
        {desired_construct_type ? desired_construct_type.name : ""}
      </LabelValue>
      <LabelValue label="商品グレード">
        {desired_product_grade ? desired_product_grade.name : ""}
      </LabelValue>
      <LabelValue label="階数">
        {desired_floor_num ? desired_floor_num.name : ""}
      </LabelValue>
      <LabelValue label="希望建物面積">
        {props.value.desired_building_area}
      </LabelValue>
      <LabelValue label="希望間取り">
        {props.value.desired_floor_plan}
      </LabelValue>

      <LabelValue label="車庫数">{props.value.desired_garage_num}</LabelValue>
      <LabelValue label="その他要望/補足" isWrap={true}>
        {props.value.desired_note}
      </LabelValue>
    </>
  );
}

DesiredBuildingInfo.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
};
DesiredBuildingInfo.defaultProps = {
  onChange: null,
  value: {},
  error: {},
};
