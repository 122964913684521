import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import FormGroup from "@material-ui/core/FormGroup";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  labelField: {
    margin: theme.spacing(0),
    width: "100px",
    display: "flex",
    alignItems: "center",
  },
  inputField: {
    width: "300px",
    //  margin: theme.spacing(1),
  },
}));

export default function SelectBranch(props) {
  const classes = useStyles();

  // const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  // const [inputValue, setInputValue] = useState("");
  const [selectedBranch, setSelectedBranch] = useState(null);
  // const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = useState("");
  // const loading = open && options.length === 0;

  useEffect(() => {
    //  console.log("branch_id", props.value, props.options);
    setOptions(props.options);

    // let input_value = "";
    const branch = props.options.find((v) => v.id == props.branchId);
    // console.log("SelectBranch", props.branchId, branch);
    setSelectedBranch(branch ?? null);
    setInputValue(branch ? branch.name : "");
  }, [props.branchId]);

  const handleSelect = (value) => {
    // console.log("handleSelect", value);
    setSelectedBranch(value);
    props.onChange(value ? value.id : null);
  };
  const handleInput = (value) => {
    // console.log("handleInput", value);
    setInputValue(value);
  };

  return (
    <Autocomplete
      id={props.id}
      // style={{ width: 300 }}
      className={classes.inputField}
      value={selectedBranch}
      onChange={(event, newValue) => {
        handleSelect(newValue);
      }}
      inputValue={inputValue ?? ""}
      onInputChange={(event, newInputValue) => {
        handleInput(newInputValue);
      }}
      options={options}
      getOptionLabel={(option) => {
        let name = option.name ?? "";
        return name;
      }}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
}

SelectBranch.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  branchId: PropTypes.string,
  options: PropTypes.array,
};
SelectBranch.defaultProps = {
  id: null,
  options: [],
  error: null,
};
