import React, { useRef } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import MuSelect from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText, InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputField: {
    // width: "100%",
    marginTop: theme.spacing(1),
  },
  flex: {
    display: "flex",
    alignItems: "flex-end",
    // margin: "auto 0px",
  },
  asterisk: {
    color: "#D83420",
  },
}));

/**
 * select
 */
export default function Select(props) {
  const classes = useStyles();

  const labelRef = useRef();
  const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0;

  const options = props.options.sort((a, b) => a.sort_order - b.sort_order);
  var select = [];

  if (props.isBlank) {
    select.push(
      <option value="" key={0}>
        {props.blankLabel}
      </option>
    );
  }

  for (var v of options) {
    const value = v.value ?? v.id;
    select.push(
      <option value={value} key={value ?? v.id}>
        {v.name}
      </option>
    );
  }

  return (
    <div className={classes.flex}>
      <FormControl variant="outlined" className={classes.inputField}>
        {props.placeholder && (
          <InputLabel id={props.id} ref={labelRef}>
            {props.placeholder}
          </InputLabel>
        )}
        <MuSelect
          native
          value={props.value}
          onChange={props.onChange}
          onClick={props.onClick}
          // label={props.placeholder ?? null}
          placeholder={null}
          labelWidth={labelWidth}
          error={props.error !== null}
          disabled={props.disabled}
        >
          {select}
        </MuSelect>
        <FormHelperText error={props.error !== null}>
          {props.error}
        </FormHelperText>
      </FormControl>
      {props.endText && <div>&nbsp;{props.endText}</div>}
    </div>
  );
}

Select.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  options: PropTypes.array,
  endText: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  isBlank: PropTypes.bool,
  blankLabel: PropTypes.string,
};
Select.defaultProps = {
  id: "",
  value: "",
  options: {},
  endText: null,
  placeholder: null,
  error: null,
  disabled: false,
  isBlank: false,
  blankLabel: "すべて",
};
