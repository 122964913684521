import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { heightDifferenceList } from "../commons/Constants";
import Radio from "../atoms/Radio";
import Textarea from "../atoms/Textarea";

const useStyles = makeStyles(() => ({
  inputField: {
    // width: "200px",
  },
}));

export default function InputHeightDifference(props) {
  const classes = useStyles();

  const handleHeightDifference = (e) => {
    if (e.target.value != 6) {
      props.onChange({
        height_difference: parseInt(e.target.value),
        value: null,
      });
    }
    if (e.target.value == 6) {
      props.onChange({
        height_difference: 6,
        value: props.value,
      });
    }
  };

  const handleNote = (e) => {
    props.onChange({
      height_difference: 6,
      value: e.target.value,
    });
  };
  const handleOnClick = () => {
    props.onChange({
      height_difference: 6,
      value: props.value,
    });
  };

  // const selected = props.height_difference == "0" ? "2" : "1";

  return (
    <div>
      <Radio
        id="height_difference"
        onChange={handleHeightDifference}
        options={heightDifferenceList}
        value={String(props.heightDifference)}
        error={props.error}
      />
      <div className={classes.inputField}>
        <Textarea
          id="height_difference_note"
          value={props.value}
          onChange={handleNote}
          onClick={handleOnClick}
          error={props.error_note}
          label="あり(自由指定)"
          disabled={props.heightDifference !== 6}
          inputProps={{ maxLength: 500 }}
        />
      </div>
    </div>
  );
}

InputHeightDifference.propTypes = {
  onChange: PropTypes.func,
  heightDifference: PropTypes.number,
  value: PropTypes.string,
  error: PropTypes.string,
  error_note: PropTypes.string,
};
InputHeightDifference.defaultProps = {
  onChange: null,
  heightDifference: null,
  value: null,
  error: null,
  error_note: null,
};
