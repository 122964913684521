import React from "react";
import PropTypes from "prop-types";

import LabelValue from "./LabelValue";
import Index from "../molecules/Index";
import EmailTwoToneIcon from "@material-ui/icons/EmailTwoTone";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  circle: {
    borderRadius: "16px",
    // minWidth: "28px",
    padding: "4px 0px",
    marginBottom: "-4px",
    marginRight: theme.spacing(1),
    backgroundColor: "#333333",
    color: "#FFFFFF",
  },
  outline: {
    width: "100%",
    backgroundColor: "#ffffff",
    border: "1px solid #999999",
    borderRadius: "8px",
    padding: "8px 16px",
    // textAlign: "center",
  },
}));

export default function Message(props) {
  const classes = useStyles();

  if (!props.isDisplay) {
    return <></>;
  }
  return (
    <div className={props.showType == 2 ? classes.outline : null}>
      {props.isLabel && (
        <Index
          value={props.label}
          startIcon={
            props.isIcon ? (
              <EmailTwoToneIcon className={classes.circle} />
            ) : (
              <></>
            )
          }
          isUnderline={props.showType == 1}
        />
      )}

      <LabelValue isWrap={true} underline={false}>
        {props.children}
      </LabelValue>
    </div>
  );
}

Message.propTypes = {
  label: PropTypes.string,
  showType: PropTypes.number,
  isIcon: PropTypes.bool,
  isLabel: PropTypes.bool,
  children: PropTypes.node,
  isDisplay: PropTypes.bool,
};
Message.defaultProps = {
  isIcon: false,
  isLabel: true,
  showType: 1,
  isDisplay: true,
};
