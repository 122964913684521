import React from "react";
import PropTypes from "prop-types";

import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
import InputContractType from "../molecules/InputContractType";
import InputCustomerName from "../molecules/InputCustomerName";
import InputSalesUser from "../molecules/InputSalesUser";

export default function InputContractInfo(props) {
  const handleContractType = (e) => {
    let ret = { ...props.value, ...e };
    props.onChange(ret);
  };

  const handleCustomerName = (e) => {
    // console.log(e);
    let ret = { ...props.value, ...e };
    props.onChange(ret);
  };

  const handleSalesUser = (e) => {
    // console.log(e);
    props.onChange({
      ...props.value,
      supervisor: e.supervisor,
    });
  };

  return (
    <>
      <Index value="顧客情報" />
      <LabelValue label="契約形態">
        <InputContractType
          onChange={handleContractType}
          customerType={props.value.customer_type}
          contractType={props.value.contract_type}
          error={props.error}
        />
      </LabelValue>
      <LabelValue label="顧客名" required>
        <InputCustomerName
          id="customer_name"
          existingCustomer={props.value.existing_customer}
          customerName={props.value.customer_name}
          customerDash={props.value.customer_dash}
          inputCustomerName={props.value.input_customer_name}
          onChange={handleCustomerName}
          required
          error={props.error.customer_name}
        />
      </LabelValue>
      <LabelValue label="営業担当">
        <InputSalesUser
          id="user"
          branch_name={props.value.branch ? props.value.branch.name : null}
          user={props.value.user}
          onChange={handleSalesUser}
          // error={props.error.customer_name}
        />
      </LabelValue>
    </>
  );
}

InputContractInfo.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
};
InputContractInfo.defaultProps = {
  onChange: null,
  value: {},
  error: {},
};
