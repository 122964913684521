import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { prefectureList } from "../commons/Constants";
import Input from "../atoms/Input";
import Select from "../atoms/Select";

const useStyles = makeStyles((theme) => ({
  flex: {
    // display: "flex",
    // alignItems: "flex-end",
  },
  inputField: {
    // marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(0),
  },
  right: {
    marginLeft: "auto",
    textAlign: "right",
  },
}));

export default function InputAddress(props) {
  const classes = useStyles();

  const handleAttachedFile = (e, value) => {
    if (value === null) {
      return;
    }

    if (value == 1) {
      props.onChange({
        is_attached_file: value,
        value: {
          prefecture: "東京都",
          city: null,
          address_additional: null,
        },
      });
    }
    if (value == 0) {
      props.onChange({
        is_attached_file: value,
        value: props.value,
      });
    }
  };

  const handlePrefecture = (e) => {
    // console.log(e);
    props.onChange({
      is_attached_file: 0,
      value: { ...props.value, prefecture: e.target.value },
    });
  };

  const handleCity = (e) => {
    // console.log(e);
    props.onChange({
      is_attached_file: 0,
      value: { ...props.value, city: e.target.value },
    });
  };

  const handleAddress = (e) => {
    // console.log(e);
    props.onChange({
      is_attached_file: 0,
      value: { ...props.value, address_additional: e.target.value },
    });
  };

  const handleOnClick = () => {
    props.onChange({
      is_attached_file: 0,
      value: props.value,
    });
  };

  const selected = props.isAttachedFile;

  return (
    <div className={classes.flex}>
      <div className={classes.right}>
        <ToggleButtonGroup
          value={props.isAttachedFile}
          exclusive
          onChange={handleAttachedFile}
          aria-label="text alignment"
        >
          <ToggleButton value={0} aria-label="left aligned">
            直接入力
          </ToggleButton>
          <ToggleButton value={1} aria-label="centered">
            入力省略(添付参照)
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.inputField}>
        <Select
          id="prefecture"
          value={String(props.value.prefecture)}
          onChange={handlePrefecture}
          onClick={handleOnClick}
          options={prefectureList}
          placeholder="都道府県"
          error={props.error.prefecture}
          disabled={selected === 1}
        />
        <Input
          id="city"
          value={props.value.city}
          onChange={handleCity}
          onClick={handleOnClick}
          placeholder="市区(資料では「市区町村」)"
          error={props.error.city}
          disabled={selected === 1}
          inputProps={{ maxLength: 50 }}
        />
        <Input
          id="address_additional"
          value={props.value.address_additional}
          onChange={handleAddress}
          onClick={handleOnClick}
          placeholder="それ以降"
          error={props.error.address_additional}
          disabled={selected === 1}
          inputProps={{ maxLength: 50 }}
        />
      </div>
    </div>
  );
}

InputAddress.propTypes = {
  onChange: PropTypes.func,
  isAttachedFile: PropTypes.number,
  value: PropTypes.object,
  error: PropTypes.object,
};
InputAddress.defaultProps = {
  onChange: null,
  isAttachedFile: 1,
  value: null,
  error: null,
};
