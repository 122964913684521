import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import Divider from "@material-ui/core/Divider";

const tabStyles = {
  defaultTab: {
    fontSize: "14px",
    fontWeight: "600",
    borderRight: "solid 2px #cccccc",
    borderTop: "solid 2px #cccccc",
  },
  activeTab: {
    fontSize: "14px",
    color: "#333",
    fontWeight: "600",
    borderRight: "solid 2px #111111",
    borderTop: "solid 2px #cccccc",
  },
};

const useStyles = makeStyles((theme) => ({
  outline: {
    // borderBottom: "solid 2px #cccccc",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
  rightSpace: {
    borderBottom: "solid 2px #cccccc",
    width: "100%",
    maxWidth: "100%",
  },
}));

export default function ProjectTabs(props) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    props.onChange(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  let tab_styles = [];
  tab_styles[0] = tabStyles.defaultTab;
  tab_styles[1] = tabStyles.defaultTab;
  tab_styles[2] = tabStyles.defaultTab;
  tab_styles[props.tabNo] = tabStyles.activeTab;

  return (
    <>
      <Tabs
        value={props.tabNo}
        onChange={handleChange}
        aria-label="tab-detail"
        indicatorColor="primary"
        textColor="primary"
        className={classes.outline}
      >
        <Tab label="案件情報" {...a11yProps(0)} style={tab_styles[0]} />
        <Tab label="プラン" {...a11yProps(1)} style={tab_styles[1]} />
        <Tab disabled classes={{ root: classes.rightSpace }} />
      </Tabs>
    </>
  );
}

ProjectTabs.propTypes = {
  onChange: PropTypes.func,
  tabNo: PropTypes.number,
};
