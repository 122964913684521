import React from "react";
import PropTypes from "prop-types";

import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
import Datetime from "../molecules/Datetime";
import PostFileList from "../molecules/PostFileList";
import Message from "../templates/Message";
import { reviewStatusList } from "../commons/Constants";
import ListValue from "../molecules/ListValue";
import { dateToString } from "../commons/Functions";

export default function PostPlanInfo(props) {
  return (
    <>
      <Index value="プラン" />
      <LabelValue label="現在の設計担当">{props.designer}</LabelValue>

      <LabelValue label="投稿日時">
        <Datetime value={dateToString(props.plan.posted_at, true)} />
      </LabelValue>
      <LabelValue label="レビュー状態">
        <ListValue list={reviewStatusList} value={props.plan.review_status} />
      </LabelValue>
      {props.plan.review_complete_note !== null && (
        <LabelValue label="レビュー完了コメント" isWrap={true}>
          {props.plan.review_complete_note}
        </LabelValue>
      )}
      <LabelValue label="添付ファイル">
        <PostFileList
          data={props.postFiles}
          users={props.users}
          project_id={props.plan.project_id}
          plan_id={props.plan.id}
        />
      </LabelValue>
      {props.plan.review_note !== null &&
        [1, 3].includes(props.plan.review_status) && (
          <LabelValue label="差戻し理由" isWrap={true}>
            {props.plan.review_note}
          </LabelValue>
        )}

      <LabelValue label="" underline={false}>
        <Message label="設計者担当者からの伝達事項" showType={2} isIcon={true}>
          {props.plan.post_note}
        </Message>
      </LabelValue>
    </>
  );
}

PostPlanInfo.propTypes = {
  onChange: PropTypes.func,
  plan: PropTypes.object,
  designer: PropTypes.string,
  postFiles: PropTypes.array,
  users: PropTypes.array,
};
PostPlanInfo.defaultProps = {
  onChange: null,
  plan: {},
  postFiles: [],
  users: [],
};
