import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Input from "../atoms/Input";
import Button from "../atoms/Button";
import { Grid } from "@material-ui/core";
// import Button from "../atoms/Button";
// import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  list: {
    marginRight: theme.spacing(2),
  },
  item: {
    marginTop: theme.spacing(2),
  },
  add: {
    marginRight: theme.spacing(2),
  },
  header: {
    color: "rgba(0, 0, 0, 0.87)",
    borderBottom: "solid 2px #cccccc",
    fontWeight: "800",
  },
}));

export default function InputSiteNames(props) {
  const classes = useStyles();

  useEffect(() => {
    if (props.value.length == 0) {
      props.onChange([{ name: "" }]);
      return;
    }
  }, []);

  const handleSiteInfo = (id, e) => {
    let val = [...props.value];
    val[id].site_name = e.target.value;
    props.onChange(val);
  };

  const handleAdd = () => {
    let val = [...props.value, { name: "" }];
    if (val.length >= 100) {
      return;
    }
    props.onChange(val);
  };

  const handleDel = (id) => {
    let val = [...props.value];
    if (val.length <= 1) {
      return;
    }
    val.splice(id, 1);
    props.onChange(val);
  };

  // console.log(props.error);

  return (
    <div className={classes.list}>
      <Grid container>
        <Grid item xs={1} className={classes.header}>
          No
        </Grid>
        <Grid item xs={8} className={classes.header}>
          号棟名
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      {Object.keys(props.value).map((key) => {
        // console.log(key, props.value[key].site_name);
        return (
          <Grid container key={key} alignItems="center" justifyContent="center">
            <Grid item xs={1} className={classes.item}>
              {props.value[key].display_id}
            </Grid>
            <Grid item xs={8}>
              <Input
                id="site_name"
                value={props.value[key].site_name}
                onChange={(e) => handleSiteInfo(key, e)}
                required
                error={
                  props.error && props.error[key] ? props.error[key] : null
                }
              />
            </Grid>
            <Grid item xs={3} className={classes.item}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDel(key)}
                className={classes.del}
              >
                削除
              </Button>
            </Grid>
          </Grid>
        );
      })}
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleAdd()}
        className={classes.add}
      >
        追加
      </Button>
    </div>
  );
}

InputSiteNames.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  error: PropTypes.array,
};
InputSiteNames.defaultProps = {
  onChange: null,
  value: [],
  error: [],
};
