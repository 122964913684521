import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

// import Button from "../../atoms/Button";
import User from "../../commons/User";
import Layout from "../../templates/Layout";
// import Modal from "../../templates/Modal";
// import User from "../../commons/User";

const useStyles = makeStyles(() => ({
  body: {
    // marginRight: "auto",
    minHeight: "480px",
  },
  center: {
    // marginRight: "auto",
    textAlign: "center",
  },
}));

export default function AuthLogin() {
  const classes = useStyles();

  useEffect(() => {
    const authInfo = User.findAuthInfo();

    if (!authInfo) {
      const url = `${process.env.REACT_APP_API_URL}/accounts/google/login/?process=login`;
      window.open(url, "_self");
    }
  }, []);

  // const handleLogin = () => {
  //   const url = `${process.env.REACT_APP_API_URL}/accounts/google/login/?process=login`;
  //   window.open(url, "_self");
  // };

  return (
    <Layout isAuth={false}>
      <div className={classes.body}></div>
      {/* <Modal open={true} title="Dash案件管理" style="short">
        <div className={classes.center}>
          <Button variant="contained" color="primary" onClick={handleLogin}>
            ログイン
          </Button>
        </div>
      </Modal> */}
    </Layout>
  );
}
