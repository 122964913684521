import React from "react";
import PropTypes from "prop-types";

import "date-fns";
// import FormGroup from "@material-ui/core/FormGroup";
// import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import jaLocale from "date-fns/locale/ja";
import format from "date-fns/format";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "200px",
    marginTop: theme.spacing(1),
  },
}));

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "yyyy MMM", { locale: this.locale });
  }
  getDatePickerHeaderText(date) {
    return format(date, "MMMd日", { locale: this.locale });
  }
}
/**
 * DatePicker
 */
export default function DatePicker(props) {
  const classes = useStyles();

  // The first commit of Material-UI
  // const [selectedDate, setSelectedDate] = useState(null);
  // new Date("2014-08-18T21:11:54")

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  // console.log("DatePicker", props.error);
  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
      {/* <Grid container justify="space-around"> */}
      <KeyboardDatePicker
        disableToolbar
        inputVariant="outlined"
        variant="inline"
        format="yyyy/MM/dd"
        margin="normal"
        autoOk={true}
        className={classes.inputField}
        id={props.id}
        // label="Date picker inline"
        value={props.value}
        onChange={props.onChange}
        onClick={props.onClick}
        disabled={props.disabled}
        error={props.error !== null}
        helperText={props.error === null ? "" : props.error}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        // okLabel="OK"
        // cancelLabel="キャンセル"
        // keyboardIcon={<Icon type="calendar" />}
      />
      {/* </Grid> */}
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};
DatePicker.defaultProps = {
  id: "",
  value: null,
  disabled: false,
  error: null,
};
