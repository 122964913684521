import React from "react";
import PropTypes from "prop-types";

import Index from "../molecules/Index";
import LabelValue from "../templates/LabelValue";
// import InputSiteArea from "../molecules/InputSiteArea";
// import InputUseDistrict from "../molecules/InputUseDistrict";
// import InputConstructionVolumeRate from "../molecules/InputConstructionVolumeRate";
// import InputHeightDifference from "../molecules/InputHeightDifference";
// import InputRoadWidth from "../molecules/InputRoadWidth";
// import InputRoadObstacle from "../molecules/InputRoadObstacle";
// import InputFireProtection from "../molecules/InputFireProtection";
// import InputHeightDistrict from "../molecules/InputHeightDistrict";
import {
  attachedFileList,
  fireProtectionList,
  heightDifferenceList,
  heightDistrictList,
  roadObstacleList,
  useDistrictList,
} from "../commons/Constants";
import Address from "../molecules/Address";
import ListValues from "../molecules/ListValues";

export default function SiteInfo(props) {
  // console.log("InputSiteInfo", props.error);

  const is_address = attachedFileList.find(
    (v) => v.value == props.value.is_address && v.value == 1
  );

  const is_site_area = attachedFileList.find(
    (v) => v.value == props.value.is_site_area && v.value == 1
  );

  const is_use_district = attachedFileList.find(
    (v) => v.value == props.value.is_use_district && v.value == 1
  );
  const use_district = useDistrictList.find(
    (v) => v.value == props.value.use_district
  );

  const is_height_district = attachedFileList.find(
    (v) => v.value == props.value.is_height_district && v.value == 1
  );
  const height_district = heightDistrictList.find(
    (v) => v.value == props.value.height_district
  );

  const is_fire_protection = attachedFileList.find(
    (v) => v.value == props.value.is_fire_protection && v.value == 1
  );
  const fire_protection = fireProtectionList.find(
    (v) => v.value == props.value.fire_protection
  );

  const is_construction_volume_rate = attachedFileList.find(
    (v) => v.value == props.value.is_construction_volume_rate && v.value == 1
  );

  const height_difference = heightDifferenceList.find(
    (v) => v.value == props.value.height_difference
  );

  const is_road_width = attachedFileList.find(
    (v) => v.value == props.value.is_road_width && v.value == 1
  );

  const is_road_obstacle = attachedFileList.find(
    (v) => v.value == props.value.is_road_obstacle && v.value == 1
  );

  return (
    <>
      <Index value="敷地情報" required />
      <LabelValue label="現場住所\n(代表地番)">
        <div>{is_address ? is_address.name : ""}</div>
        {props.value.is_address == 0 && <Address value={props.value} />}
      </LabelValue>
      <LabelValue label="敷地面積">
        <div>{is_site_area ? is_site_area.name : ""}</div>
        {props.value.is_site_area == 0 && <>{props.value.site_area}㎡</>}
      </LabelValue>
      <LabelValue label="用途地域">
        <div>{is_use_district ? is_use_district.name : ""}</div>
        {props.value.is_use_district == 0 && (
          <>{use_district && use_district.name}</>
        )}
      </LabelValue>
      <LabelValue label="高度地区">
        <div>{is_height_district ? is_height_district.name : ""}</div>
        {props.value.is_height_district == 0 && (
          <>{height_district && height_district.name}</>
        )}
      </LabelValue>
      <LabelValue label="防火指定">
        <div>{is_fire_protection ? is_fire_protection.name : ""}</div>
        {props.value.is_fire_protection == 0 && (
          <>{fire_protection && fire_protection.name}</>
        )}
      </LabelValue>

      <LabelValue label="建蔽率/容積率">
        <div>
          {is_construction_volume_rate ? is_construction_volume_rate.name : ""}
        </div>
        {props.value.is_construction_volume_rate == 0 && (
          <>
            <div>建蔽率:{props.value.construction_rate}％</div>
            <div>容積率:{props.value.volume_rate}％</div>
          </>
        )}
      </LabelValue>
      <LabelValue label="高低差" isWrap={true}>
        <div>{height_difference ? height_difference.name : ""}</div>
        {props.value.height_difference == 6 && (
          <>{props.value.height_difference_note}</>
        )}
      </LabelValue>
      <LabelValue label="前面道路幅員">
        <div>{is_road_width ? is_road_width.name : ""}</div>
        {props.value.is_road_width == 0 && (
          <>
            <div>
              北側:
              {props.value.road_width_north
                ? props.value.road_width_north + "ｍ"
                : "-"}
            </div>
            <div>
              南側:
              {props.value.road_width_south
                ? props.value.road_width_south + "ｍ"
                : "-"}
            </div>
            <div>
              東側:
              {props.value.road_width_east
                ? props.value.road_width_east + "ｍ"
                : "-"}
            </div>
            <div>
              西側:
              {props.value.road_width_west
                ? props.value.road_width_west + "ｍ"
                : "-"}
            </div>
          </>
        )}
      </LabelValue>

      <LabelValue label="前面道路障害物">
        <div>{is_road_obstacle ? is_road_obstacle.name : ""}</div>
        {props.value.is_road_obstacle == 0 && (
          <ListValues
            list={roadObstacleList}
            value={props.value.road_obstacle}
          />
        )}
        {/* // <>{road_obstacle}</>} */}
      </LabelValue>
    </>
  );
}

SiteInfo.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
  isInput: PropTypes.bool,
};
SiteInfo.defaultProps = {
  onChange: null,
  value: {},
  error: {},
  isInput: false,
};
