import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { existingCustomerList } from "../commons/Constants";
import Radio from "../atoms/Radio";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import SearchCustomer from "./SearchCustomer";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    alignItems: "center",
  },
  item: {
    marginRight: theme.spacing(2),
  },
  dash: {
    width: "100px",
    backgroundColor: "#eeeeee",
    border: "1px solid #999999",
    textAlign: "center",
    // height: theme.spacing(4),
  },
}));

export default function InputCustomerName(props) {
  const classes = useStyles();

  const [openSearchCustomer, setOpenSearchCustomer] = useState(false);

  const handleOpenSearchCustomer = () => {
    setOpenSearchCustomer(true);
  };
  const handleCloseSearchCustomer = () => {
    setOpenSearchCustomer(false);
  };

  const handleExistingCustomer = (e) => {
    const existing_customer = parseInt(e.target.value);
    const ret = { existing_customer: existing_customer };
    // if (existing_customer == 2) {
    //   // 新規の場合は0
    //   ret["customer_no"] = 0;
    // }

    props.onChange(ret);
  };

  const handleCustomerName = (e) => {
    props.onChange({ input_customer_name: e.target.value });
  };

  const handleSelectedCustomer = (e) => {
    const ret = {
      customer_no: e.id,
      customer_name: e.customer_name,
      customer_dash: e.is_invitation_dash,
    };
    props.onChange(ret);
    // console.log(e);
  };

  return (
    <>
      <Radio
        id="existing_customer"
        onChange={handleExistingCustomer}
        options={existingCustomerList}
        value={String(props.existingCustomer)}
        // error={props.error}
      />
      {props.existingCustomer == 1 && (
        <>
          <div className={classes.flex}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenSearchCustomer()}
              className={classes.item}
            >
              顧客選択
            </Button>
            <div className={classes.item}>{props.customerName}</div>
            {props.customerDash && (
              <div className={classes.dash}>Dash加入済み</div>
            )}
          </div>
          <FormHelperText error={props.error !== null}>
            {props.error}
          </FormHelperText>
        </>
      )}
      {props.existingCustomer == 2 && (
        <Input
          id="customer_name"
          value={props.inputCustomerName}
          onChange={handleCustomerName}
          required
          error={props.error}
        />
      )}
      <SearchCustomer
        open={openSearchCustomer}
        onClose={handleCloseSearchCustomer}
        onChange={handleSelectedCustomer}
      />
    </>
  );
}

InputCustomerName.propTypes = {
  onChange: PropTypes.func,
  existingCustomer: PropTypes.number,
  customerName: PropTypes.string,
  customerDash: PropTypes.bool,
  inputCustomerName: PropTypes.string,
  error: PropTypes.string,
};
InputCustomerName.defaultProps = {
  onChange: null,
  existingCustomer: 1,
};
