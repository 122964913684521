import React from "react";
import PropTypes from "prop-types";
import { customerTypeList, contractTypeList } from "../commons/Constants";
import Radio from "../atoms/Radio";
import { InputLabel } from "@material-ui/core";

export default function InputContractType(props) {
  const handleCustomerType = (e) => {
    const val = parseInt(e.target.value);

    // console.log("handleCustomerType", val);
    let contract_type = props.contractType;
    if (contract_type != 3) {
      contract_type = val == 1 ? 0 : 4;
    }
    props.onChange({
      customer_type: val,
      contract_type: contract_type,
    });
  };

  const handleContractType = (e) => {
    const val = parseInt(e.target.value);

    props.onChange({
      contract_type: val,
      customer_type: props.customerType,
    });
  };

  const contractOptions = contractTypeList.filter((v) => {
    if (props.customerType == 1) {
      // 法人
      return ["0", "1", "2", "3"].includes(v.value);
    }
    // 個人
    return ["3", "4"].includes(v.value);
  });

  return (
    <>
      <InputLabel>法人・個人</InputLabel>
      <Radio
        id="customer_type"
        onChange={handleCustomerType}
        options={customerTypeList}
        value={String(props.customerType)}
        error={props.error.customer_type}
      />
      <InputLabel>種別</InputLabel>
      <Radio
        id="contract_type"
        onChange={handleContractType}
        options={contractOptions}
        value={String(props.contractType)}
        error={props.error.contract_type}
      />
    </>
  );
}

InputContractType.propTypes = {
  onChange: PropTypes.func,
  customerType: PropTypes.number,
  contractType: PropTypes.number,
  error: PropTypes.object,
};
InputContractType.defaultProps = {
  onChange: null,
  error: {},
};
