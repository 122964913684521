import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// import Button from "../../atoms/Button";
import Modal from "../templates/Modal";
import OkCancel from "../molecules/OkCancel";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import Table from "../atoms/Table";
import { rowsPerPage } from "../commons/Constants";
import LabelValue from "../templates/LabelValue";
import CallAPI from "./CallAPI";
import { addPageParams } from "../commons/Functions";
import Name from "./Name";
import User from "../commons/User";
// import SelectBranch from "./SelectBranch";

export default function SearchDesigner(props) {
  useEffect(() => {
    const branches = User.getDesignerBranches();
    setBranches(branches);
    // const branch = User.getBranch();
    // const branch_id = branch ? branch.id : "";
    // setBranchId(branch_id);
    loadData(page);
  }, []);

  const [branches, setBranches] = useState([]);
  const [apiParams, setApiParams] = useState({});
  // const [branchId, setBranchId] = useState("");
  const [keyword, setKeywoed] = useState("");
  const [users, setUsers] = useState({ list: [], count: 0 });
  // ページ管理
  const [page, setPage] = useState({
    offset: 0,
    limit: rowsPerPage,
  });

  // const handleBranchId = (e) => {
  //   setBranchId(e);
  // };

  const handleKeyword = (e) => {
    // props.onClose();
    setKeywoed(e.target.value);
  };

  const handleSearch = () => {
    const params = {
      ...page,
      offset: 0,
    };
    setPage(params);
    loadData(params);
  };

  const loadData = (page) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/user/search_designer/`,
      method: "GET",
      params: addPageParams({ keyword: keyword }, page, false),
      setData: setData,
      isSubmit: true,
    };
    setApiParams(params);
  };

  // 取得データセット
  const setData = (data) => {
    // console.log(data);
    setUsers(data);
  };

  // 改ページ
  const handleChangePageNo = (e, offset) => {
    const params = {
      ...page,
      offset: offset,
    };
    setPage(params);
    loadData(params);
  };

  const handleClickRow = (id) => {
    // console.log("handleClickRow", id);
    const user = users.list.find((v) => v.id == id);
    props.onChange(user);

    updateData(id);
  };

  const updateData = (id) => {
    const params = {
      url: `${process.env.REACT_APP_API_ENDPOINT}/plan/change_designer/`,
      method: "PUT",
      params: { plan_id: props.planId, designer: id },
      setData: setUpdate,
      isSubmit: true,
    };
    setApiParams(params);
  };

  const setUpdate = () => {
    setApiParams({});
    props.onClose();
  };

  const handleCancel = () => {
    props.onClose();
  };

  const getBranchName = (value) => {
    const branch = branches.find((v) => v.id == value);
    return <>{branch ? branch.name : ""}</>;
  };
  const getUserName = (value, id, row) => {
    return <Name user={row} />;
  };
  const getCountDetail = (value, id, row) => {
    return (
      <>
        {row.request_count} / {row.making_count}
      </>
    );
  };

  const columns = [
    {
      id: "user_name",
      label: "担当者名",
      minWidth: 150,
      format: (value, id, row) => {
        return getUserName(value, id, row);
      },
    },
    {
      id: "branch_id",
      label: "支店",
      minWidth: 150,
      format: (value, id, row) => {
        return getBranchName(value, id, row);
      },
    },
    {
      id: "total_count",
      label: "案件数",
      minWidth: 50,
      align: "center",
    },
    {
      id: "count_detail",
      label: "依頼中/受付済",
      minWidth: 50,
      align: "center",
      format: (value, id, row) => {
        return getCountDetail(value, id, row);
      },
    },
  ];

  return (
    <Modal
      title="設計担当検索"
      open={props.open}
      onClose={props.onClose}
      style="normal"
    >
      {/* <LabelValue label="支店名">
        <SelectBranch
          id="branch_id"
          branchId={branchId}
          onChange={handleBranchId}
          options={branches}
        />
      </LabelValue> */}

      <LabelValue label="設計担当">
        <Input
          id="keyword"
          value={keyword}
          onChange={handleKeyword}
          onKeyEnter={handleSearch}
        />
      </LabelValue>
      <LabelValue label="">
        <Button variant="contained" color="primary" onClick={handleSearch}>
          検索
        </Button>
      </LabelValue>

      <Table
        columns={columns}
        data={users}
        page={page}
        onChangePageNo={handleChangePageNo}
        onClickRow={handleClickRow}
        buttonLabel={<>変更</>}
      />

      <OkCancel onCancel={handleCancel} />
      <CallAPI params={apiParams} />
    </Modal>
  );
}

SearchDesigner.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  planId: PropTypes.number,
};
SearchDesigner.defaultProps = {
  open: false,
};
